import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import translations from 'decorators/Translations/translations';
import { isAdminOrCaverionUserRole } from 'utils/Data/profileData';
import withErrors from 'decorators/Errors/withErrors';
import { getPartnerNumbers } from 'utils/profile';
import { icons } from 'components/Icon/IconNames';
import {
  setProfileProperty,
  saveProfile,
  setEditProfile,
  loadCustomers,
  loadPermissionsHierarchy,
  loadTranslations,
  setLanguage,
  loadPermissions,
  addLoading,
  removeLoading,
  getPortfolioCounts,
  loadMyCustomViews,
} from 'redux/modules/index.js';
import { StandardPage } from 'components/index.js';
import UserForm from 'containers/Application/Admin/UserForm/UserForm.jsx';
import Header from 'containers/Application/Header/Header';
import FadeTransition from 'components/FadeTransition/FadeTransition';

const LOADING_PERMISSIONS = 'permissions';

class Profile extends React.Component {
  state = { saving: false, saved: false };

  componentDidMount() {
    if (!this.props.editProfiles || !this.props.editProfiles[this.props.profile.id]) {
      this.loadProfile();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.profile.id !== this.props.profile.id ||
      !_.isEqual(prevProps.profile.permissions, this.props.profile.permissions)
    ) {
      this.loadProfile();
    }
    if (this.props.errorMessage && !_.isEqual(prevProps.errorMessage, this.props.errorMessage)) {
      this.props.showError();
    }
  }

  loadProfile = () => {
    const {
      profile,
      loadPermissionsHierarchy,
      setEditProfile,
      addLoading,
      removeLoading,
      loadMyCustomViews,
    } = this.props;

    loadMyCustomViews();

    if (isAdminOrCaverionUserRole(profile.role)) {
      addLoading(LOADING_PERMISSIONS);
      loadPermissionsHierarchy(profile).then(() => removeLoading(LOADING_PERMISSIONS));
    } else {
      setEditProfile(profile.id);
    }
  };

  saveProfile = (id, profileData) => {
    const oldLanguage = this.props.profile.language;

    this.setState({ saving: true });

    this.props.saveProfile(id, profileData).then(data => {
      if (data.result) {
        const newLanguage = data.result.language;
        const addedPermissions = _.flatten(
          Object.keys(data.result.permissions).map(x =>
            data.result.permissions[x].filter(y => this.props.functionalLocations[y] === undefined)
          )
        );

        if (oldLanguage !== newLanguage) {
          this.props.loadTranslations(newLanguage);
          this.props.setLanguage(newLanguage);
        }

        // Load permissions to all added functional locations
        if (addedPermissions.length > 0) {
          this.props.loadPermissions();
        }

        this.setState({ saving: false, saved: true });
        setTimeout(() => {
          this.props.setProfileProperty(id, 'edited', false);
        }, 3000);

        setTimeout(() => {
          this.setState({ saved: false });
        }, 4000);
      } else {
        this.setState({ saving: false });
      }
    });
  };

  render() {
    const { t, editProfiles, profile, loading } = this.props;
    if (!editProfiles || !profile) {
      return null;
    }

    const editProfile = this.props.editProfiles && this.props.editProfiles[this.props.profile.id];
    const id = profile.id;

    const links = [
      { title: 'Back to Overview', icon: icons.ARROW_LEFT, to: { pathname: '/' }, smallIcon: true },
      { title: 'Your Profile', icon: icons.ADMIN_MANAGE_USERS },
    ];

    return (
      <FadeTransition>
        <StandardPage withTabs>
          <Helmet title={t('My Profile')} />
          <Header t={t} links={links} selected="your profile" />
          <UserForm
            id="editProfileForm"
            profileId={id}
            model={editProfile}
            setProperty={(property, value) => this.props.setProfileProperty(id, property, value)}
            submit={profile => this.saveProfile(id, profile)}
            t={t}
            saving={this.state.saving}
            saved={this.state.saved}
            loadingPermissions={_.includes(loading, LOADING_PERMISSIONS)}
          />
        </StandardPage>
      </FadeTransition>
    );
  }
}
const COMPONENT = 'Profile';

const mapStateToProps = state => ({
  editProfiles: state.profile.editProfiles,
  profile: state.profile.profile,
  functionalLocations: state.functionalLocations.functionalLocations,
  loading: state.common.loading[COMPONENT] || [],
});

function mapDispatchToProps(dispatch) {
  return {
    setProfileProperty: (id, property, value) => dispatch(setProfileProperty(id, property, value)),

    saveProfile: (id, data) => dispatch(saveProfile(id, data)),

    loadPermissionsHierarchy: profile =>
      Promise.all([
        dispatch(setEditProfile(profile.id)),
        dispatch(loadCustomers(getPartnerNumbers(profile))),
        dispatch(
          loadPermissionsHierarchy(_.flatten(Object.keys(profile.permissions || {}).map(x => profile.permissions[x])))
        ),
        dispatch(getPortfolioCounts(profile.partnerPermissions || [])),
      ]),

    setEditProfile: id => dispatch(setEditProfile(id)),

    loadTranslations: language => dispatch(loadTranslations(language)),

    setLanguage: language => dispatch(setLanguage(language)),

    loadPermissions: functionalLocation => dispatch(loadPermissions(functionalLocation)),

    addLoading: key => dispatch(addLoading(key, COMPONENT)),

    removeLoading: key => dispatch(removeLoading(key, COMPONENT)),

    loadMyCustomViews: () => dispatch(loadMyCustomViews()),
  };
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(withErrors(translations(withTheme(Profile))));
