import React, { Fragment, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { throttle } from 'lodash';

import DocumentRow, { CeruleanText } from './DocumentRow';
import EventsRow from 'components/BuildingEvents/EventsRow/EventsRow';
import EventsCell from 'components/BuildingEvents/EventsRow/EventsCell';
import Svg from 'components/Svg/Svg';
import ActionColumn from './ActionColumn';
import { indentationCSS } from 'utils/Data/documents';

const Cell = styled(EventsCell)`
  position: ${props => props.relative && 'relative'};
  padding: ${props => props.theme.spacing.sm};
`;

const Folder = styled.p`
  line-height: ${props => props.theme.font.size.xxl};
  cursor: pointer;
  display: inline-block;
  ${indentationCSS}
`;
Folder.displayName = 'Folder';

const FolderName = styled.span`
  color: ${props => props.theme.colors.black};
  font-weight: ${props => props.theme.fontWeight.bold};
  padding-left: ${props => props.theme.spacing.xxs};
`;
FolderName.displayName = 'FolderName';

const Content = styled.span`
  color: ${props => props.theme.colors.darkGray};
  font-weight: ${props => props.theme.fontWeight.normal};
  padding-left: ${props => props.theme.spacing.xxs};
`;

const FolderRow = props => {
  const [isClosed, setIsClosed] = useState(!props.rowData.isOpen);
  const [width, setWidth] = useState(window.innerWidth);

  const throttledSetWidth = useCallback(throttle(() => setWidth(window.innerWidth), 100), []);

  useEffect(() => {
    window.addEventListener('resize', throttledSetWidth);

    return () => {
      window.removeEventListener('resize', throttledSetWidth);
    };
  }, []);

  useEffect(
    () => {
      props.rowData.isOpen && setIsClosed(false); // allow only the opening from outside
    },
    [props.rowData.isOpen]
  );

  const download = e => {
    e.preventDefault();
    props.onClick(null, false, props.rowData);
  };

  const openFileUpload = e => {
    e.preventDefault();
    props.rowData.onFolderFileUpload(props.rowData.id);
  };

  return (
    <Fragment>
      <EventsRow>
        <Cell colSpan={width < 900 ? 1 : 3}>
          <Folder onClick={() => setIsClosed(!isClosed)} depth={props.depth - 1}>
            <Svg name={isClosed ? 'fa-caret-right' : 'fa-caret-down'} />
            <FolderName>{props.rowData.name}</FolderName>
            <Content>({props.rowData.children ? props.rowData.children.length : 0})</Content>
          </Folder>
          {typeof props.onClick === 'function' && width < 900 && (
            <CeruleanText href="" onClick={download}>
              {props.t('Download')}
            </CeruleanText>
          )}
        </Cell>
        <Cell hideOnMobile>
          {typeof props.onClick === 'function' && (
            <CeruleanText href="" onClick={download}>
              {props.t('Download')}
            </CeruleanText>
          )}
        </Cell>
        <Cell hideOnMobile>
          {typeof props.rowData.onFolderFileUpload === 'function' && (
            <CeruleanText href="" onClick={openFileUpload}>
              {props.t('Upload files')}
            </CeruleanText>
          )}
        </Cell>
        <Cell relative>
          <ActionColumn t={props.t} rowData={props.rowData} />
        </Cell>
      </EventsRow>
      {!isClosed &&
        props.rowData.children &&
        props.rowData.children.map(row => (
          <DocumentRow {...props} rowData={row} key={`${row.documentId || row.id}-${row.name}`} />
        ))}
    </Fragment>
  );
};

export default React.memo(FolderRow);
