import _ from 'lodash';
import { createReducerFromMapping } from 'redux/utils/index.js';
import { IoT, MasterData } from '@caverion/redux/api/actions';
import {
  isEnergySensor,
  isElectricitySensor,
  isDistrictHeatingSensor,
  isWaterConsumptionSensor,
  isEnergyRatingSensor,
} from 'utils/Data/values.js';

export const initialState = {
  energyChartValues: { byFl: {}, byPartner: {} },
  loadingEnergyChartValues: false,
};

export const LOAD_ENERGY_CHART_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_ENERGY_CHART_VALUES';
export const LOAD_ENERGY_CHART_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_ENERGY_CHART_VALUES_SUCCESS';
export const LOAD_ENERGY_CHART_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_ENERGY_CHART_VALUES_FAIL';

export function loadEnergyChartValues(partnerNumber, betweens, aggregation) {
  return async (dispatch, getState) => {
    dispatch({ type: LOAD_ENERGY_CHART_VALUES });

    if (!aggregation || !betweens || !Array.isArray(betweens)) {
      return dispatch({
        type: LOAD_ENERGY_CHART_VALUES_FAIL,
        error: 'Parameters aggregation or betweens are missing.',
      });
    }

    try {
      const state = getState();
      const energySensors = _.flatten(_.values(state.values.flEnergyValues.energySensors));
      const sensorTypes = await dispatch(MasterData.sensorTypes());

      const valuesFilter = {
        where: {},
        order: 'timestamp ASC',
        fields: ['sensorId', 'aggregation', 'timestamp', 'value'],
      };

      if (!energySensors || energySensors.length === 0 || !sensorTypes || sensorTypes.length === 0) {
        return dispatch({
          type: LOAD_ENERGY_CHART_VALUES_FAIL,
          error: 'No energySensors or sensorTypes.',
        });
      }

      const energyRatingSensorTypes = _.filter(sensorTypes, sensorType => isEnergyRatingSensor(sensorType.name));
      // sort sensors to energyRating and energyConsumption arrays
      const sensorsByType = energySensors.reduce(
        (accu, sensor) => {
          if (_.find(energyRatingSensorTypes, { id: sensor.sensorTypeId })) {
            accu.energyRating.push(sensor.id);
          } else {
            accu.energyConsumption.push(sensor.id);
          }
          return accu;
        },
        { energyRating: [], energyConsumption: [] }
      );

      valuesFilter.where.or = [];

      if (sensorsByType.energyRating.length !== 0) {
        // Add energyRating sensors for each between-condition
        _.forEach(betweens, between => {
          valuesFilter.where.or.push({
            sensorId: { inq: sensorsByType.energyRating },
            timestamp: { between },
            aggregation: 'energyRating',
            value: { neq: null },
          });
        });
      }
      if (sensorsByType.energyConsumption.length !== 0) {
        // Add other sensors for each between-condition
        _.forEach(betweens, between => {
          valuesFilter.where.or.push({
            sensorId: { inq: sensorsByType.energyConsumption },
            timestamp: { between },
            aggregation,
            value: { neq: null },
          });
        });
      }

      let sensorValues = [];
      if (valuesFilter.where.or && valuesFilter.where.or.length > 0) {
        sensorValues = await dispatch(IoT.findWithPost(valuesFilter));
      }

      return dispatch({
        type: LOAD_ENERGY_CHART_VALUES_SUCCESS,
        result: sensorValues,
        sensorTypes,
        energySensors,
        partnerNumber,
        aggregation,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_ENERGY_CHART_VALUES_FAIL,
        error,
      });
    }
  };
}

export default createReducerFromMapping(
  {
    [LOAD_ENERGY_CHART_VALUES]: (state, action) => ({
      ...state,
      loadingEnergyChartValues: true,
    }),
    [LOAD_ENERGY_CHART_VALUES_SUCCESS]: (state, action) => {
      if (!action.result || action.result.length === 0) {
        return {
          ...state,
          loadingEnergyChartValues: false,
        };
      }
      const initValues = { all: [], electricity: [], districtHeating: [], waterConsumption: [], energyRating: [] };

      // function to add a value to collection[aggregation][category of categories]
      const addValue = (valueObject, collection, categories) => {
        collection[action.aggregation] = collection[action.aggregation] || _.cloneDeep(initValues);
        _.forEach(categories, category => collection[action.aggregation][category].push(valueObject));
      };

      // overwrite old values
      const flValues = {};
      const partnerValues = {};

      // keep the dailySums if aggregation is hourlySum
      if (action.aggregation === 'hourlySum') {
        _.forEach(_.keys(state.energyChartValues.byFl), fl => {
          flValues[fl] = { dailySum: _.cloneDeep(state.energyChartValues.byFl[fl].dailySum) };
        });
        _.forEach(_.keys(state.energyChartValues.byPartner), partner => {
          partnerValues[partner] = { dailySum: _.cloneDeep(state.energyChartValues.byPartner[partner].dailySum) };
        });
      }

      // type needs to be resolved to sensors
      const sensors = action.energySensors.map(sensor => ({
        ...sensor,
        type: _.find(action.sensorTypes, { id: sensor.sensorTypeId }),
      }));

      action.result.forEach(data => {
        const sensor = _.find(sensors, { id: +data.sensorId });
        const functionalLocation = sensor.functionalLocation || data.functionalLocation;
        const partnerNumber = action.partnerNumber;
        const type = sensor.type ? sensor.type.name : data.type;
        const valueObject = { ...data, functionalLocation, type };
        const categories = [];
        // categories for valueObject
        if (isEnergySensor(type)) {
          categories.push('all');
        }
        if (isElectricitySensor(type)) {
          categories.push('electricity');
        }
        if (isDistrictHeatingSensor(type)) {
          categories.push('districtHeating');
        }
        if (isWaterConsumptionSensor(type)) {
          categories.push('waterConsumption');
        }
        if (isEnergyRatingSensor(type)) {
          categories.push('energyRating');
        }
        // add valueObject to flValues and/or partnerValues
        if (functionalLocation) {
          flValues[functionalLocation] = flValues[functionalLocation] || {};
          addValue(valueObject, flValues[functionalLocation], categories);
        }
        if (partnerNumber) {
          partnerValues[partnerNumber] = partnerValues[partnerNumber] || {};
          addValue(valueObject, partnerValues[partnerNumber], categories);
        }
      });

      return {
        ...state,
        energyChartValues: {
          byFl: flValues,
          byPartner: partnerValues,
        },
        loadingEnergyChartValues: false,
      };
    },
    [LOAD_ENERGY_CHART_VALUES_FAIL]: (state, action) => ({
      ...state,
      loadingEnergyChartValues: false,
    }),
  },
  initialState
);
