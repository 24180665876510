import memoizeOne from 'memoize-one';
import { forEach, map, flatten, orderBy, last, reject, compact, includes, isEmpty, startsWith } from 'lodash';
import { css } from 'styled-components';

const icons = {
  docx: 'docx',
  doc: 'doc',
  xlsx: 'xls',
  xls: 'xls',
  pptx: 'pptx',
  ppt: 'ppt',
  pdf: 'pdf',
  txt: 'txt',
  jpg: 'img',
  jpeg: 'img',
  png: 'img',
  svg: 'img',
  rtf: 'rtf',
};

export const getFileIconByExtension = file => {
  const extension = file && (file.extension || (file.name && last(file.name.split('.'))));
  const icon = icons[extension];
  return icon || 'fa-file-o';
};

export const shouldShowInlineLink = file => {
  const mimeType = file && file.mimeType;
  if (!mimeType) {
    return false;
  }
  return startsWith(mimeType, 'image') || mimeType === 'application/pdf';
};

export const getHumanreadableSize = size => {
  if (size === null) {
    return '-';
  }

  if (size === 0) {
    return '0 B';
  }
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][Math.min(i, 4)];
};

export const findFromFolderTree = (id, item) => {
  // Item can be either folder or a file, files have documentId and we want only folders
  if (item.id === id && !item.documentId) {
    return item;
  }

  let result;

  if (item.children) {
    forEach(item.children, childFolder => {
      result = findFromFolderTree(id, childFolder);
      if (result) {
        return false; // exit from forEach
      }
    });
  }

  return result;
};

const filterFolderTree = (tree, ids) => {
  const filteredChildren = [];

  if (tree.children) {
    forEach(tree.children, child => {
      const filteredChild = filterFolderTree(child, ids);
      if (filteredChild) {
        filteredChildren.push(filteredChild);
      }
    });
  }

  if (includes(ids, tree.id) || !isEmpty(filteredChildren)) {
    return {
      ...tree,
      children: !isEmpty(filteredChildren) && filteredChildren,
    };
  }

  return null;
};

export const filterFolders = (folders, ids) => {
  return compact(map(folders, folder => filterFolderTree(folder, ids)));
};

const toOption = (folder, path) => ({ label: path, value: folder.id });

export const toPath = (parentPath, folderName) => (parentPath ? `${parentPath} > ${folderName}` : folderName);

const createFolderPathOptions = (folder, parentPath, options) => {
  const path = toPath(parentPath, folder.name);

  options.push(toOption(folder, path));

  if (folder.children) {
    forEach(folder.children, childFolder => createFolderPathOptions(childFolder, path, options));
  }

  return options;
};

export const getFolderOptions = memoizeOne((folders, folderId) => {
  return orderBy(
    flatten(map(reject(folders, { id: folderId }), folder => createFolderPathOptions(folder, '', []))),
    'label'
  );
});

const insertToTree = (tree, getProperties) => ({
  ...tree,
  ...getProperties(tree),
  children: tree.children && map(tree.children, child => insertToTree(child, getProperties)),
});

export const insertFolderProperties = (folders, getProperties) => {
  return map(folders, folder => insertToTree(folder, getProperties));
};

export const folderIndentation = (breakpoint, depth = 0) => {
  let maxIndentation;

  switch (breakpoint) {
    case 'desktop':
      maxIndentation = 8;
      break;
    case 'portrait':
      maxIndentation = 5;
      break;
    default:
      maxIndentation = 3;
  }

  return `${Math.min(maxIndentation, depth) * 2.5}rem`;
};

export const indentationCSS = css`
    &:first-child {
        ${props => props.depth && `padding-left: ${folderIndentation('mobile', props.depth)};`}
    }
    ${props => props.depth && `padding-left: ${folderIndentation('mobile', props.depth)};`}

    ${props => props.theme.media.portrait`
        &:first-child {
            ${props => props.depth && `padding-left: ${folderIndentation('portrait', props.depth)};`}
        }
        ${props => props.depth && `padding-left: ${folderIndentation('portrait', props.depth)};`}
    `}

    ${props => props.theme.media.desktop`
        &:first-child {
            ${props => props.depth && `padding-left: ${folderIndentation('desktop', props.depth)};`}
        }
        ${props => props.depth && `padding-left: ${folderIndentation('desktop', props.depth)};`}
    `}
`;

const pathToNode = (tree, id) => {
  if (tree.id === id) {
    return [tree.id];
  }

  let result = null;

  if (tree.children) {
    forEach(tree.children, child => {
      const childResult = pathToNode(child, id);
      if (childResult) {
        result = [...childResult, tree.id];
        return false; // exit from forEach
      }
    });
  }

  return result;
};

export const findIdPath = (folderId, folders) => {
  return compact(map(folders, folder => pathToNode(folder, folderId)))[0];
};
