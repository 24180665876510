import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { map, keyBy, forEach, filter, reduce, cloneDeep } from 'lodash';

import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import StandardPage from 'components/StandardPage/StandardPage';
import Header from 'containers/Application/Header/Header';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import FadeTransition from 'components/FadeTransition/FadeTransition';
import CustomViewTable from 'components/CustomView/CustomViewTable';
import EditOrCreateCustomView from './EditOrCreateCustomView';
import SnackBar from 'components/SnackBar/SnackBar';
import ButtonDropdown from 'components/Button/ButtonDropdown';

import translations from 'decorators/Translations/translations';
import { noFilter } from 'components/FilterDropdown/FilterDropdown';
import { icons } from 'components/Icon/IconNames';
import { getPartnerNumbers } from 'utils/profile';
import { showModal } from 'redux/modules/modal/modal';
import { MODALTYPE } from 'components/Modal/ModalTypes';
import { NOTIFICATION_TIMEOUT } from 'constants/common';
import { searchPartnersByIds } from 'redux/modules/index.js';
import { loadCustomViews, deleteCustomView } from 'redux/modules/customView/customView';
import { VIEW_TYPES } from './utils';
import { CUSTOM_VIEW_FORM_MODE } from './EditOrCreateCustomView';

const PortfolioTitle = styled.h4`
  margin-bottom: ${props => props.theme.spacing.sm};
  color: ${props => props.theme.colors.black};
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.font.size.sm};
  padding: ${props => props.theme.spacing.xs};
  padding-left: 0;
  font-size: ${props => props.theme.font.size.md};
`;
PortfolioTitle.displayName = 'PortfolioTitle';

const StyledSection = styled(Section)`
  padding: ${props => props.theme.spacing.sm};

  ${props => props.theme.media.portrait`
        padding: ${props => props.theme.spacing.md};
    `}

  ${props => props.theme.media.landscape`
        padding: ${props.theme.grid.gutter};
    `}
`;
StyledSection.displayName = 'StyledSection';

const SectionTitle = styled.h2`
  display: inline-block;
`;

SectionTitle.displayname = 'SectionTitle';

const FilterDropDownWrapper = styled.div`
  ${props => props.theme.media.desktop`
        display: inline-block;
        width: 20%;
        position: relative;
        right -20%;
    `};
`;
FilterDropDownWrapper.displayName = 'FilterDropDownWrapper';

const sideNavLinks = [
  { title: 'Back to admin homepage', icon: icons.ARROW_LEFT, to: { pathname: '/Admin' }, smallIcon: true },
  { title: 'Manage custom views', icon: icons.CUSTOM_TAB },
];

export class AdminManageCustomViews extends Component {
  state = {
    showModal: false,
    formMode: null,
    customerFilter: noFilter,
    notificationType: null,
    notificationMessage: null,
    notificationVisible: false,
    loading: true,
  };

  componentDidMount() {
    this.props.loadCustomViews().then(() => this.setState({ loading: false }));
    const partnerNumbers = getPartnerNumbers(this.props.profile);
    this.props.searchPartnersByIds(partnerNumbers);
  }

  handleCustomViewActions = (customView, action) => {
    switch (action) {
      case 'DUPLICATE':
        return this.openForm(
          CUSTOM_VIEW_FORM_MODE.DUPLICATE,
          { ...customView, id: undefined, functionalLocations: [], users: [] },
          customView.viewType
        );
      case 'EDIT':
        return this.openForm(CUSTOM_VIEW_FORM_MODE.EDIT, customView);
      case 'DELETE':
        return this.openDeletionModal(customView.id, customView.viewName);
      default:
        break;
    }
  };

  deleteCustomView = id => {
    return this.props
      .deleteCustomView(id)
      .then(() => {
        const notification = {
          type: 'success',
          message: this.props.t('Custom view deleted successfully'),
        };
        this.showNotification(notification);
        setTimeout(() => this.hideNotification(), NOTIFICATION_TIMEOUT);
        return Promise.resolve();
      })
      .catch(error => Promise.reject(new Error('Delete Custom View Failed')));
  };

  openDeletionModal = (id, name) => {
    this.props.showModal(
      MODALTYPE.CONFIRMATION_DELETE_CUSTOM_VIEW,
      null,
      () => this.deleteCustomView(id),
      `${this.props.t('Custom view')} ${this.props.t('{0} will be removed', name)}`
    );
  };

  openForm = (formMode, customView, newViewType) =>
    this.setState({
      dialogOpen: true,
      formMode,
      newViewType,
      selectedCustomView: customView,
    });

  closeForm = () => this.setState({ dialogOpen: false });

  showNotification = notification => {
    this.setState({
      notificationType: notification.type,
      notificationMessage: notification.message,
      notificationVisible: true,
    });
  };

  hideNotification = () => {
    this.setState({
      notificationVisible: false,
    });
  };

  handleAfterFormSubmit = hasError => {
    const addNew = !!this.state.newViewType;
    const t = this.props.t;

    this.closeForm();

    let message = addNew ? t('Custom view created successfully') : t('Custom view updated successfully');

    if (hasError) {
      message = addNew ? t('Custom view creation failed') : t('Custom view update failed');
    }

    const notification = {
      type: hasError ? 'error' : 'success',
      message,
    };

    this.showNotification(notification);
    setTimeout(() => this.hideNotification(), NOTIFICATION_TIMEOUT);
  };

  handleFilterChange = value => this.setState({ customerFilter: value });

  render() {
    const { t, customViews, partnerSearch } = this.props;
    const { loading } = this.state;

    // create portfolios by keying partnerSearch options
    const basePortfolios = keyBy(partnerSearch.options, 'value');

    // add every customView under the right customers (basePortfolios are deepcloned to prevent side effects)
    const portfoliosWithCustomViews = reduce(
      customViews,
      (accu, view) => {
        forEach(view.customers, partnerNumber => {
          if (accu[partnerNumber]) {
            if (!accu[partnerNumber].customViews) {
              accu[partnerNumber].customViews = [];
            }
            accu[partnerNumber].customViews.push(view);
          }
        });
        return accu;
      },
      cloneDeep(basePortfolios)
    );

    // filter out portfolios without custom views
    const portfolios = filter(portfoliosWithCustomViews, 'customViews');

    // apply customer filter
    const filteredPortfolios =
      this.state.customerFilter === noFilter
        ? portfolios
        : filter(portfolios, portfolio => portfolio.value === this.state.customerFilter);

    // create options for customer filter
    const filterOptions = map(portfolios, portfolio => ({ text: portfolio.label, value: portfolio.value }));

    const newViewOptions = Object.keys(VIEW_TYPES).map(key => ({
      label: t(VIEW_TYPES[key]),
      onClick: () => this.openForm(CUSTOM_VIEW_FORM_MODE.CREATE, null, VIEW_TYPES[key]),
    }));

    return (
      <FadeTransition>
        <StandardPage>
          <Helmet title={t('Manage custom views')} />
          <Header t={t} links={sideNavLinks} selected="manage custom views" />

          <SectionHeader title={t('Manage custom views')}>
            <ButtonDropdown autoWidth buttonLabel={t('Add Custom View')} items={newViewOptions} alignment="center" />
            <FilterDropdown
              options={filterOptions}
              handleChange={this.handleFilterChange}
              selectedFilter={this.state.customerFilter}
              showAllText={t('Show all')}
              disabled={!filterOptions}
            />
          </SectionHeader>

          {loading && (
            <StyledSection>
              <CustomViewTable t={t} data={[]} loading={true} />
            </StyledSection>
          )}

          {!loading &&
            map(filteredPortfolios, portfolio => (
              <StyledSection key={`Portfolio-${portfolio.value}`}>
                <PortfolioTitle>{portfolio.label}</PortfolioTitle>
                <CustomViewTable
                  t={t}
                  data={map(portfolio.customViews, customView => ({
                    customView,
                    onAction: this.handleCustomViewActions,
                  }))}
                />
              </StyledSection>
            ))}

          {this.state.dialogOpen && (
            <EditOrCreateCustomView
              formMode={this.state.formMode}
              customView={this.state.selectedCustomView}
              newViewType={this.state.newViewType}
              onClose={this.closeForm}
              onSubmit={this.handleAfterFormSubmit}
            />
          )}

          <SnackBar variant={this.state.notificationType} visible={this.state.notificationVisible}>
            {this.state.notificationMessage}
          </SnackBar>
        </StandardPage>
      </FadeTransition>
    );
  }
}

AdminManageCustomViews.propTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
  customViews: state.customView.customViews,
  partnerSearch: state.customer.partnerSearch,
});

const mapDispatchToProps = dispatch => ({
  loadCustomViews: () => dispatch(loadCustomViews()),
  deleteCustomView: id => dispatch(deleteCustomView(id)),
  showModal: (type, preConditions, onSubmitAction, passedProps) =>
    dispatch(showModal(type, preConditions, onSubmitAction, passedProps)),
  searchPartnersByIds: ids => dispatch(searchPartnersByIds(ids)),
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(translations(AdminManageCustomViews));
