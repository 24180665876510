import { createActions, createReducer } from 'redux/utils/reducerUtils';
import isEmpty from 'lodash/isEmpty';
import { IoT } from '@caverion/redux/api/actions';

const loadIoTData = (
  { functionalLocationId, sensorIds, aggregation, startTime, endTime },
  { load, fail, success },
  dispatch
) => {
  dispatch(load({ payload: null, meta: { functionalLocationId, sensorIds } }));
  if (isEmpty(sensorIds)) {
    return dispatch(
      fail({ payload: new Error('No sensors to fetch data for'), meta: { functionalLocationId, sensorIds } })
    );
  }
  return dispatch(
    IoT.findWithPost({
      where: {
        aggregation: aggregation,
        timestamp: { between: [startTime, endTime] },
        sensorId: { inq: sensorIds },
      },
      order: 'timestamp ASC',
      fields: ['sensorId', 'aggregation', 'timestamp', 'value'],
    })
  ).then(result =>
    dispatch(success({ payload: result, meta: { functionalLocationId, sensorIds } }), error =>
      dispatch(fail({ payload: error, meta: { functionalLocationId, sensorIds } }))
    )
  );
};

const loadOPIDataActions = createActions('CUSTOMER_PLATFORM/IoT_Values/LOAD_RECYCLING_OPI_DATA');
const loadWasteAmountsDataActions = createActions('CUSTOMER_PLATFORM/IoT_Values/LOAD_RECYCLING_WASTE_AMOUNTS_DATA');

export const loadOPIData = ioTArgs => dispatch => loadIoTData(ioTArgs, loadOPIDataActions, dispatch);
export const loadWasteAmountsData = ioTArgs => dispatch => loadIoTData(ioTArgs, loadWasteAmountsDataActions, dispatch);

export const OPIDataReducer = createReducer(loadOPIDataActions);
export const wasteAmountsDataReducer = createReducer(loadWasteAmountsDataActions);
