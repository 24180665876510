import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import translations from 'decorators/Translations/translations';
import withErrors from 'decorators/Errors/withErrors';
import queryString from 'query-string';
import isEqual from 'lodash/isEqual';
import head from 'lodash/head';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import findLast from 'lodash/findLast';
import last from 'lodash/last';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { loadBuildingAdminContainer } from 'redux/modules/containers/buildingAdmin';
import { icons } from 'components/Icon/IconNames';
import { canEditBuilding } from 'utils/Data/profileData';
import { getEnabledTabs, getEnabledCustomViews } from 'utils/Data/features';
import { NOTIFICATION_TIMEOUT } from 'constants/common';

import Hero from 'components/Hero/Hero';
import StandardPage from 'components/StandardPage/StandardPage';
import BuildingImage from './BuildingImage/BuildingImage';
import SnackBar from 'components/SnackBar/SnackBar';
import BuildingSensors from './BuildingSensors/BuildingSensors';
import AdminAnnouncements from './BuildingAnnouncements/AdminAnnouncements';
import BuildingAdminTags from './BuildingTags/BuildingAdminTags';
import AdminBuildingConfiguration from './BuildingConfiguration/AdminBuildingConfiguration';
import Header from 'containers/Application/Header/Header.jsx';
import FadeTransition from 'components/FadeTransition/FadeTransition';
import LoadingBuildingSensors from './BuildingSensors/LoadingBuildingSensors';
import BuildingValueImporter from './BuildingValueImporter/BuildingValueImporter';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import TabContent from 'components/TabContent/TabContent';

export const TAB_TITLES = {
  Configuration: 'Configuration',
  FloorsAndSensors: 'Floors And Sensors',
  News: 'News',
  TaggingTool: 'Tagging tool',
  ImportValues: 'Import values',
};

class AdminEditBuilding extends Component {
  state = {
    /** Hero image for building */
    buildingImage: '',
    /** Notification type */
    notificationType: '',
    /** Notification message */
    notificationMessage: '',
    /** Notification visibility */
    notificationVisible: false,
  };

  // Load building data in component mount
  componentDidMount() {
    this.loadBuilding(this.props.match.params.functionalLocationId);
    this.timeout = null;
  }

  // Update building image and tree data in state when props change. Also handle generic error showing.
  componentDidUpdate(prevProps) {
    const { functionalLocationImages, errorMessage, showError } = prevProps;

    if (!isEqual(functionalLocationImages, this.props.functionalLocationImages)) {
      this.setState({ buildingImage: this.getBuildingImage(this.props) });
    }

    if (this.props.errorMessage && !isEqual(errorMessage, this.props.errorMessage) && !this.shouldRenderErrorPage()) {
      showError();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  // Building data loading logic and mapping to tree
  loadBuilding = functionalLocationId => {
    this.props
      .loadBuildingAdminContainer(functionalLocationId)
      .then(() => this.setState({ buildingImage: this.getBuildingImage(this.props) }));
  };

  // Show notification
  showNotification = notification => {
    this.setState({
      notificationType: notification.type,
      notificationMessage: notification.message,
      notificationVisible: true,
    });

    this.timeout = setTimeout(() => this.setState({ notificationVisible: false }), NOTIFICATION_TIMEOUT);
  };

  // Generate link back to building page
  generateBackLink = functionalLocation => {
    const {
      activePartner,
      profile: { features, customViews, partnerNumbers },
    } = this.props;
    if (!functionalLocation) {
      return { pathname: '' };
    }

    const functionalLocationId = functionalLocation.functionalLocation;
    const functionalLocationType = functionalLocation.type;
    const tab = head(
      getEnabledTabs(
        features,
        functionalLocationType,
        getEnabledCustomViews(customViews, { functionalLocation: functionalLocationId })
      )
    );

    let partnerNumber = activePartner;

    // Wrong active partner, probably due to direct link to admin
    if (!includes(functionalLocation.partnerNumberWithParents, activePartner)) {
      partnerNumber = head(intersection(functionalLocation.partnerNumberWithParents, partnerNumbers));
    }

    return {
      pathname: `/${partnerNumber || 'all'}/FunctionalLocation/${encodeURIComponent(functionalLocationId)}/${tab}`,
    };
  };

  // Gets building image path
  getBuildingImage = props => {
    const { functionalLocationImages } = props;
    let buildingImagePath = '';

    if (functionalLocationImages) {
      const buildingImage = findLast(functionalLocationImages, image => image.type === 'building');

      if (buildingImage && buildingImage.path) {
        buildingImagePath = buildingImage.path;
      }
    }

    return buildingImagePath;
  };

  renderImageSelector = () => {
    const {
      match: {
        params: { functionalLocationId },
      },
      fileupload: { uploading },
    } = this.props;
    return <BuildingImage functionalLocation={functionalLocationId} uploading={uploading} />;
  };

  getTitleBySelectedTab = pathname => TAB_TITLES[last(pathname.split('/'))] || 'Configuration';

  shouldRenderErrorPage = () => {
    const {
      profile,
      loading,
      functionalLocations,
      match: {
        params: { functionalLocationId },
      },
    } = this.props;
    return !canEditBuilding(profile.role) || (!loading && !functionalLocations[functionalLocationId]);
  };

  render() {
    const {
      t,
      profile,
      functionalLocations,
      match: {
        params: { functionalLocationId },
        path,
        url,
      },
      loading,
      activePartner,
      location,
    } = this.props;

    const { notificationMessage, notificationType, notificationVisible } = this.state;

    if (this.shouldRenderErrorPage()) {
      return <ErrorPage type="functionalLocation" />;
    }

    let buildingName;
    const functionalLocation = functionalLocations[functionalLocationId];

    if (functionalLocation) {
      buildingName = functionalLocation.description;
    }

    const links = [
      {
        title: buildingName || '...',
        icon: icons.TO_BUILDING,
        to: this.generateBackLink(functionalLocation),
        contextLink: true,
      },
      { title: 'Configuration', icon: icons.SETTINGS, pathname: `${url}/Configuration` },
      { title: 'Floors And Sensors', icon: icons.TO_FLOOR, pathname: `${url}/FloorsAndSensors` },
      { title: 'Import values', icon: icons.IMPORT, pathname: `${url}/ImportValues` },
      { title: 'News', icon: icons.ADMIN_MANAGE_NOTIFICATIONS, pathname: `${url}/News` },
      { title: 'Tagging tool', icon: icons.ADMIN_TAGS, pathname: `${url}/TaggingTool` },
    ];

    return (
      <FadeTransition>
        <StandardPage withTabs>
          <Header t={t} links={links} loading={loading} />
          <Hero
            title={t(this.getTitleBySelectedTab(location.pathname))}
            subTitle={buildingName}
            t={t}
            customImageElement={this.renderImageSelector()}
            loadingContext={loading}
          />
          <TabContent>
            <Switch>
              <Route path={`${path}/Configuration`}>
                <AdminBuildingConfiguration
                  functionalLocation={functionalLocationId}
                  showNotification={this.showNotification}
                  loading={loading}
                />
              </Route>
              <Route path={`${path}/FloorsAndSensors`}>
                {loading ? (
                  <LoadingBuildingSensors />
                ) : (
                  <BuildingSensors
                    functionalLocation={functionalLocationId}
                    showNotification={this.showNotification}
                    activePartner={activePartner}
                  />
                )}
              </Route>
              <Route path={`${path}/News`}>
                <AdminAnnouncements
                  functionalLocation={functionalLocationId}
                  showNotification={this.showNotification}
                  loading={loading}
                />
              </Route>
              <Route path={`${path}/TaggingTool`}>
                <BuildingAdminTags functionalLocation={functionalLocationId} loading={loading} />
              </Route>
              <Route path={`${path}/ImportValues`}>
                <BuildingValueImporter
                  showNotification={this.showNotification}
                  loading={loading}
                  functionalLocation={functionalLocationId}
                />
              </Route>
              <Redirect to={`${path}/Configuration`} />
            </Switch>
          </TabContent>
          <SnackBar variant={notificationType} visible={notificationVisible}>
            {notificationMessage}
          </SnackBar>
        </StandardPage>
      </FadeTransition>
    );
  }
}

AdminEditBuilding.defaultProps = {
  loading: false,
};

AdminEditBuilding.propTypes = {
  /** Translations function */
  t: PropTypes.func.isRequired,
  /** Currently active partner */
  activePartner: PropTypes.string.isRequired,
  /** User profile */
  profile: PropTypes.object.isRequired,
  /** Functional locations data */
  functionalLocations: PropTypes.object.isRequired,
  /** Loading state */
  loading: PropTypes.bool,
  /** Fileupload state */
  fileupload: PropTypes.object,
  /** Globally catched error message */
  errorMessage: PropTypes.object,
  /** Global error notification show function (from withErrors) */
  showError: PropTypes.func.isRequired,
  /** Loads container initial data */
  loadBuildingAdminContainer: PropTypes.func.isRequired,
  /** From router */
  match: PropTypes.object.isRequired,
  /** From router */
  location: PropTypes.object.isRequired,
  /** Hero images for functional locations */
  functionalLocationImages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state, props) => ({
  activePartner: state.profile.activePartner,
  profile: state.profile.profile,
  fileupload: state.fileupload,
  functionalLocationImages: state.partnerImage.byFL[props.match.params.functionalLocationId],
  errorMessage: state.errorMessage,
  functionalLocations: state.functionalLocations.functionalLocations,
  loading: state.buildingAdminContainer.loading,
});

const mapDispatchToProps = {
  loadBuildingAdminContainer,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withRouter(connector(withErrors(translations(AdminEditBuilding))));
