import React, { PureComponent } from 'react';
import _ from 'lodash';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTheme } from 'styled-components';
import { transparentize, lighten } from 'polished';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import Loader from 'components/Loader/Loader.jsx';
import { CELSIUS } from 'utils/Data/values';
import { getSymbolChar } from 'utils/String/symbols';
import styled from 'styled-components';
import Responsive from 'components/Responsive/Responsive';

const StyledBarChart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .bar-chart {
    z-index: 1 !important;
  }
`;

class BarChart extends PureComponent {
  getConfig = () => {
    // Use 1.) explicit width and height, or 2.) Dimensions from Responsive, or 3.) Highcharts default.

    let chartWidth = this.props.width ? this.props.width : this.props.dimensions ? this.props.dimensions.width : null;
    const chartHeight = this.props.height
      ? this.props.height
      : this.props.dimensions
      ? this.props.dimensions.height
      : null;

    if (chartWidth > window.innerWidth) {
      chartWidth = window.innerWidth;
    }

    const series = _.cloneDeep(this.props.series);
    const self = this;

    // enhance temperature data and push them to series
    const outdoorsTemperatureTitle = this.props.t('Outdoors temperature');
    const outdoorsTemperatureColors = this.props.theme.charts.colors.map(color => lighten(0.1, color));

    if (this.props.temperatureSeries && this.props.temperatureSeries.length > 0) {
      _.forEach(this.props.temperatureSeries, (temperatureSeries, index) => {
        series.push({
          name: temperatureSeries.name,
          type: 'line',
          color: temperatureSeries.color || outdoorsTemperatureColors[index % outdoorsTemperatureColors.length],
          lineWidth: 1,
          dashStyle: 'Dash',
          data: temperatureSeries.data,
          _unit: temperatureSeries._unit,
          zIndex: 0,
          showInLegend: true,
          _showTooltipForZeroValue: true,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        });
      });
    }

    const config = {
      chart: {
        type: this.props.type || 'column',
        zoomType: this.props.noZoom ? undefined : 'yx',
        panning: !this.props.noZoom,
        panKey: 'shift',
        width: chartWidth,
        height: chartHeight,
        className: 'bar-chart',
        plotBorderWidth: this.props.plotBorderWidth,
        plotBorderColor: this.props.plotBorderColor,
        plotBackgroundColor: this.props.plotBackgroundColor,
        style: {
          fontFamily: this.props.theme.font.family.arial,
          fontWeight: this.props.theme.font.weight.bold,
          color: this.props.theme.colors.rockBlue,
          cursor: this.props.pointer ? this.props.pointer : this.props.onClick && 'pointer',
        },
        events: {
          click: this.props.onClick,
        },
      },
      colors: this.props.theme.charts.colors,
      title: { text: this.props.title || '' },
      subtitle: { text: this.props.subtitle || '' },
      xAxis: {
        visible: true,
        minorTickLength: 0,
        tickLength: 0,
        tickInterval: this.props.tickInterval,
        categories: this.props.categories,
        crosshair: true,
        labels: {
          enabled: true,
          style: this.props.labelStyle,
          rotation: this.props.labelRotation,
          formatter: this.props.labelFormatter,
        },
        title: {
          text: this.props.xTitle,
        },
        type: this.props.xAxisType || 'datetime',
        minRange: this.props.minXRange,
        max: this.props.maxX,
      },
      legend: {
        align: this.props.legendAlign ? this.props.legendAlign : 'right',
        margin: this.props.legendAlign === 'left' ? 26 : 16,
        verticalAlign: 'top',
        borderWidth: 0,
      },
      tooltip: {
        enabled: !this.props.disableTooltip,
        backgroundColor: 'transparent',
        borderWidth: 0,
        shadow: false,
        padding: 0,
        valueDecimals: this.props.valueDecimals,
        positioner: function(labelWidth, labelHeight, point) {
          const chartWidth = this.chart.chartWidth;
          const plotWidth = this.chart.plotWidth;
          const xPosition = point.plotX + this.chart.plotLeft / 2;
          const distanceFromRight = chartWidth - xPosition;

          return {
            x: distanceFromRight > labelWidth ? xPosition : plotWidth - labelWidth / 2 - 10,
            y: 25,
          };
        },
        formatter: function() {
          const tooltipStyle = `
                        color: ${self.props.theme.colors.black};
                        background-color: ${self.props.theme.colors.white};
                        font-size: ${self.props.theme.font.size.xs};
                        border: 1px solid ${self.props.theme.colors.lightGray};
                        box-shadow: 0 2px 10px ${transparentize(0.9, self.props.theme.colors.black)};
                        border-radius: 0;
                        padding: 0.5em 1em;
                    `;
          const headerStyle = `
                        background-color: ${self.props.theme.colors.lightGray};
                        margin: -0.5em -1em 0.25em;
                        padding: 0.25em 1em;
                    `;
          const headerTextStyle = `
                        font-weight: ${self.props.theme.font.weight.bold};
                        font-size: ${self.props.theme.font.size.xxs};
                    `;

          let headerText = '';
          if (typeof self.props.getTooltipHeader === 'function') {
            headerText = self.props.getTooltipHeader(this.x);
          } else if (self.props.categories || self.props.xAxisType === 'category') {
            headerText = this.points[0].key;
          } else if (self.props.hideTime) {
            headerText = moment(this.x).format('Do MMM');
          } else {
            headerText = moment(this.x).format('Do MMM HH:mm');
          }

          let html = `
                        <div style="${tooltipStyle}">
                            <div style="${headerStyle}">
                                <span style="${headerTextStyle}">${headerText}</span>
                            </div>
                            <table>
                    `;

          const points = this.points;
          if (self.props.showTooltipTotal) {
            const total = _.sumBy(this.points, 'y');
            points.push({
              series: {
                ...points[0].series,
                name: self.props.t('Total'),
                color: self.props.theme.colors.black,
              },
              point: {},
              y: total,
            });
          }
          points.forEach(point => {
            if (point && (point.y !== 0 || point.series.userOptions._showTooltipForZeroValue)) {
              const color = !point.series.userOptions._noTooltipColors && point.series.color;
              const symbol = point.series.userOptions.type === 'line' ? getSymbolChar(point.series.symbol) : '';
              const symbolStyle = `
                                font-family: Arial;
                                color: ${color || self.props.theme.colors.black};
                                padding-right: ${symbol ? '0.3em' : 0};
                                font-size: ${self.props.theme.font.size.sm};
                                vertical-align: center;
                            `;
              const valueStyle = `
                                padding-left: ${self.props.hideLegend || !point.series.name ? 0 : '1em'};
                                padding-top: 0.3em;
                            `;
              const valueTextStyle = `
                                font-family: Arial;
                                color: ${self.props.theme.colors.black};
                                font-size: ${self.props.theme.font.size.xs};
                                font-weight: ${self.props.theme.font.weight.bold};
                            `;
              const labelStyle = `
                                padding: 0;
                                padding-top: 0.3em;
                            `;
              const labelTextStyle = `
                                color: ${color || self.props.theme.colors.rockBlue};
                                font-size: ${self.props.theme.font.size.xxs};
                                text-align: left;
                                font-family: Arial;
                            `;

              const isTemperature = point.series.userOptions._unit === CELSIUS;
              const value = !_.isNil(point.y)
                ? isTemperature
                  ? point.y.toFixed(1)
                  : self.props.noRounding
                  ? point.y
                  : point.y.toFixed(2)
                : '';

              html += `
                                <tr>
                                    <td style="${symbolStyle}">${symbol}</td>
                                    <td style="${labelStyle}">
                                        <span style="${labelTextStyle}">
                                            ${self.props.hideLegend ? '' : point.series.name}
                                        </span>
                                    </td>
                                    <td style="${valueStyle}">
                                        <span style="${valueTextStyle}">
                                            ${value} ${(!point.series.userOptions._hideTooltipUnit &&
                point.series.userOptions._unit) ||
                ''}
                                        </span>
                                    </td>
                                </tr>`;
              if (point.point.warning) {
                html += `
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>
                                            <em>${point.point.warning}</em>
                                        </td>
                                    </tr>`;
              }
            }
          });
          html += `
                            </table>
                        </div>`;
          return html;
        },
        shared: true,
        useHTML: true,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          pointWidth: this.props.pointWidth,
          enableMouseTracking: !this.props.disableTooltip,
          animation: !this.props.disableTooltip,
          colorByPoint: this.props.colorByPoint || false,
          stacking: this.props.stacked ? 'normal' : undefined,
          point: {
            events: {
              click: this.props.onClick,
            },
          },
        },
        line: {
          marker: {
            states: {
              hover: {
                lineWidthPlus: 0,
                radiusPlus: 0,
                shadow: false,
                animation: { duration: 0 },
              },
            },
          },
          states: {
            hover: {
              lineWidthPlus: 0,
              halo: { size: 0 },
            },
            inactive: {
              opacity: 1,
            },
          },
        },
        spline: {
          marker: {
            states: {
              hover: {
                lineWidthPlus: 0,
                radiusPlus: 0,
                shadow: false,
                animation: { duration: 0 },
              },
            },
          },
          states: {
            hover: {
              lineWidthPlus: 0,
              halo: { size: 0 },
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      series,
    };

    if (this.props.colors) {
      config.colors = this.props.colors;
    }
    if (this.props.hideLegend) {
      config.legend.enabled = false;
    }

    // y-axis configurations for different units
    const seriesByUnits = _.groupBy(series, '_unit');
    const units = _.reject(Object.keys(seriesByUnits), key => key === 'undefined');
    const isOnlyUnitTemperature =
      units.length === 0 && (!this.props.temperatureSeries || this.props.temperatureSeries.length === 0);

    if (units.length >= 1 || isOnlyUnitTemperature) {
      config.yAxis = [];
      units.forEach((unit, unitIndex) => {
        const yAxis = {
          title: { text: !this.props.hideYTitle && (this.props.yTitle || unit) },
          allowDecimals: !this.props.hideDecimals,
          opposite: this.props.yOpposite,
          tickInterval: this.props.yTickInterval || (unit === CELSIUS ? 10 : undefined),
          max: this.props.yMax,
          labels: {
            format: `{value}${this.props.hideYTitle ? unit : ''}`,
            style: this.props.labelStyle,
          },
          plotLines: _.clone(this.props.plotLines),
        };
        if (Boolean(unitIndex)) {
          yAxis.opposite = Boolean(unitIndex);
          yAxis.title.text = !this.props.hideYTitle && unit === CELSIUS && `${outdoorsTemperatureTitle}(${CELSIUS})`;
          yAxis.gridLineWidth = 0;
          yAxis.tickInterval = this.props.yTickInterval || unit === CELSIUS ? 1 : undefined;
          yAxis.minRange = unit === CELSIUS ? 5 : undefined;
        }
        // push to config and add config-index to all the series of this unit
        config.yAxis.push(yAxis);
        seriesByUnits[unit].forEach(series => {
          series.yAxis = unitIndex;
        });
      });
    } else {
      // Disable y-axis title if no units are given.
      config.yAxis = { title: { text: null } };
    }

    if (this.props.dataLabels) {
      const seriesValues = series[0].totals;
      config.plotOptions.series.dataLabels = {
        style: {
          color: this.props.theme.colors.black,
          fontSize: '1.5em',
          textOutline: 'none',
        },
        formatter: function() {
          return seriesValues && seriesValues.length > 0 ? `${seriesValues[this.point.x]}` : this.y > 0 ? this.y : '';
        },
        enabled: true,
        inside: true,
      };
    }

    return config;
  };

  hasData = () => {
    if (!this.props.series) {
      return false;
    }
    const dataLength = _.sum(
      _.flatten(
        this.props.series.map(series => {
          return _.map(series.data, d => {
            return d.y;
          });
        })
      )
    );
    return this.props.showEmpty || dataLength !== 0;
  };

  render() {
    const { width, height } = this.props;
    const noData = !this.hasData();
    const skeletonContent =
      (this.props.loading && <Loader color="BLUE" size="LARGE" />) ||
      (noData && this.props.error) ||
      (noData && this.props.t('No data available')) ||
      undefined;

    const showSkeleton = skeletonContent !== undefined;

    return (
      <StyledBarChart>
        {showSkeleton ? (
          <SkeletonChart
            width={width ? (width > window.innerWidth ? window.innerWidth : `${width}px`) : null}
            height={height ? `${height}px` : null}
          >
            {skeletonContent}
          </SkeletonChart>
        ) : (
          <HighchartsReact highcharts={Highcharts} options={this.getConfig()} />
        )}
      </StyledBarChart>
    );
  }
}

export default Responsive(withTheme(BarChart));
