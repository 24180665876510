import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import find from 'lodash/find';
import some from 'lodash/some';
import PerformanceItems from './PerformanceItems';
import PerformanceItem from './PerformanceItem';

export const Container = styled.div`
  padding: ${props => props.theme.spacing.sm} ${props => props.theme.sensorValues.padding.mobile} 0;
  background: ${props => props.theme.colors.white};

  ${props => props.theme.media.portrait`
        padding: ${props => props.theme.spacing.sm} ${props => props.theme.sensorValues.padding.tablet} 0;
    `}

  ${props => props.theme.media.desktop`
        padding: ${props => props.theme.spacing.sm} ${props => props.theme.sensorValues.padding.desktop} 0;
    `}
`;
Container.displayName = 'Container';

const PerformanceSelector = ({
  model,
  onClick,
  t,
  options,
  loading,
  property,
  theme,
  isAirQuality,
  disabled,
  areas,
  unit,
}) => {
  const getTitle = sensor => {
    if (sensor && sensor.isGroup) {
      return sensor.name;
    }
    if (!isAirQuality) {
      if (sensor.sensorType && sensor.sensorType.name === 'presence_area') {
        const parentArea = find(areas, area => some(area.sensors, { id: sensor.id }));
        if (parentArea) {
          return parentArea.name;
        }
      }
      return sensor.name;
    }
    if (sensor && sensor.sensorType) {
      return t(sensor.sensorType.name);
    }
    return '-';
  };

  const getColor = option => {
    if (option.backgroundColor) {
      return theme.colors.black;
    }
    if (!isAirQuality) {
      return theme.colors.midnight;
    }
    return option.performance >= 90 ? theme.colors.emerald : theme.colors.radicalRed;
  };

  return (
    <Container>
      <PerformanceItems model={model} property={property} onClick={onClick} disabled={disabled}>
        {options.map(option => (
          <PerformanceItem
            title={getTitle(option.sensor)}
            loading={loading}
            performance={option.performance}
            value={option.sensor}
            key={`PerformanceItem-${(option.sensor && option.sensor.id) || option.sensor.name}`}
            color={getColor(option)}
            backgroundColor={option.backgroundColor}
            unit={unit}
            noChart={!!unit}
          />
        ))}
      </PerformanceItems>
    </Container>
  );
};

PerformanceSelector.defaultProps = {
  loading: false,
};

PerformanceSelector.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  property: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  isAirQuality: PropTypes.bool,
  areas: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  unit: PropTypes.string,
};

export default PerformanceSelector;
