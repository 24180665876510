import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';
import Svg from 'components/Svg/Svg';

import translations from 'decorators/Translations/translations';

const Content = styled.div`
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 0.125rem 1rem ${props => rgba(props.theme.colors.black, 0.15)};
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  pointer-events: auto;

  ${props => props.theme.media.landscape`
        height: auto;
        width: auto;
    `}
`;
Content.displayName = 'Content';

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  padding: ${props => props.theme.spacing.xs};
  cursor: pointer;

  ${props => props.theme.media.landscape`
        display: block;
    `}
`;
CloseIconWrapper.displayName = 'CloseIconWrapper';

const CloseIcon = styled(Svg)`
  fill: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.md};
  pointer-events: none;
`;
CloseIcon.displayName = 'CloseIcon';

const Scrollable = styled.div`
  height: 100%;
  padding-top: 4rem;
  overflow-x: hidden; // prevent scroll for Firefox
  overflow-y: scroll;
  /* Non-standard feature to enhance iOS scrolling */
  -webkit-overflow-scrolling: touch;

  ${props => props.theme.media.landscape`
        padding-top: 0;
        max-height: ${props => (props.smallVerticalPadding ? 'calc(100vh - 50px)' : 'calc(100vh - 130px)')};
        overflow-y: auto;
    `}
`;
Scrollable.displayName = 'Scrollable';

const ClosePanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 4rem;
  padding: ${props => props.theme.spacing.xs};
  background-color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.font.size.xxs};
  font-family: ${props => props.theme.font.family.arial};
  font-weight: ${props => props.theme.font.weight.bold};
  color: ${props => props.theme.colors.black};
  letter-spacing: 0.3px;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  cursor: ${props => !props.form && 'pointer'};

  ${props => props.theme.media.landscape`
        display: none;
    `}
`;
ClosePanel.displayName = 'ClosePanel';

const PanelText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
PanelText.displayName = 'PanelText';

const Footer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.colors.alabaster};
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  padding: ${props => props.theme.spacing.xl};

  ${props => props.theme.media.landscape`
        padding: ${props => props.theme.spacing.xxl};
    `}
`;
Footer.displayName = 'Footer';

export const DialogFrame = ({ t, children, onClose, footer, smallVerticalPadding }) => {
  const handleEscape = event => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscape, false);
    return () => document.removeEventListener('keydown', handleEscape, false);
  }, []);

  return (
    <Content>
      <CloseIconWrapper onClick={onClose}>
        <CloseIcon data-test-id="CloseIcon" name="remove" />
      </CloseIconWrapper>
      <ClosePanel onClick={onClose}>
        <PanelText>{`← ${t('Close Modal')}`}</PanelText>
      </ClosePanel>
      <Scrollable smallVerticalPadding={smallVerticalPadding}>{children}</Scrollable>
      {footer}
    </Content>
  );
};
DialogFrame.displayName = 'DialogFrame';

DialogFrame.propTypes = {
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  children: PropTypes.node,
  footer: PropTypes.node,
};

DialogFrame.defaultProps = {
  children: null,
  footer: null,
};

export default translations(DialogFrame);
