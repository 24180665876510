import React from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import { transparentize } from 'polished';

const StyledCustomLegend = styled.div`
  display: flex;
  .custom-legend-entry {
    display: flex;
    margin-right: 1em;
    > svg {
      margin-right: 0.5em;
    }
  }
`;

const StyledTooltip = styled.div`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.font.size.xxs};
  border: 1px solid ${props => props.theme.colors.lightGray};
  box-shadow: 0 2px 10px ${props => transparentize(0.9, props.theme.colors.black)};
  border-radius: 0;

  div {
    font-weight: ${props => props.theme.font.weight.bold};
    padding: 0.5em;
  }
  .header {
    background-color: ${props => props.theme.colors.lightGray};
  }
  .content {
    background-color: ${props => props.theme.colors.white};
  }
`;

const textStyle = theme => ({
  fontSize: '12px',
  fontFamily: theme.font.family.arial,
  color: theme.colors.darkGray,
});

export const createBarChartConfig = ({ theme, categories, serie, serieName, customLegend, customProps }) => ({
  chart: {
    type: 'bar',
    style: {
      ...textStyle(theme),
      cursor: 'pointer',
    },
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      name: serieName,
      data: serie,
    },
  ],
  legend: {
    align: 'left',
    verticalAlign: 'top',
    useHTML: true,
    labelFormatter: () =>
      ReactDOMServer.renderToStaticMarkup(
        <StyledCustomLegend>
          {customLegend.map(entry => (
            <div key={entry.name} className="custom-legend-entry">
              <svg height="14" width="16">
                <circle cx="8" cy="7" r="4" stroke={entry.color} strokeWidth="3" fill={entry.color} />
              </svg>
              <div>{entry.name}</div>
            </div>
          ))}
        </StyledCustomLegend>
      ),
  },
  tooltip: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    shadow: false,
    padding: 0,
    useHTML: true,
    formatter: function() {
      return ReactDOMServer.renderToStaticMarkup(
        <StyledTooltip theme={theme}>
          <div className="header">{this.point.name} </div>
          <div className="content">{`${this.point.y} ${this.point._unit}`}</div>
        </StyledTooltip>
      );
    },
  },
  noData: {
    style: textStyle(theme),
  },
  xAxis: {
    categories,
    labels: {
      style: {
        ...textStyle(theme),
      },
    },
  },
  yAxis: {
    title: {
      text: null,
    },
    labels: {
      style: textStyle(theme),
    },
  },
  ...customProps,
});
