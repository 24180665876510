import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import memoizeOne from 'memoize-one';

import FunctionalLocationList from 'containers/Application/FunctionalLocationList/FunctionalLocationList';
import PermissionListItemPortfolio from '../PermissionListItem/PermissionListItemPortfolio';
import PermissionListItemFunctionalLocation from '../PermissionListItem/PermissionListItemFunctionalLocation';
import { InputSearch } from 'components/index';
import { NUMBER_OF_SEARCH_RESULTS } from 'redux/modules/customer/customer';
import ControlledPager from 'components/Pager/ControlledPager';
import ScrollToComponent from 'components/ScrollToComponent/ScrollToComponent';
import { sortFunctionalLocations } from '../utils';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.spacing.md} 0;
  flex-direction: column;
  align-items: flex-start;

  ${props => props.theme.media.portrait`
        flex-direction: row;
        align-items: center;
    `}
`;

const Heading = styled.h4`
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.md};
`;

Heading.displayName = 'Heading';

const Search = styled.div`
  width: 100%;
  max-width: 100%;
  margin-top: ${props => props.theme.spacing.sm};

  ${props => props.theme.media.portrait`
        max-width: 400px;
        margin-top: 0;
    `}
`;

const sortItems = memoizeOne(items => items.sort(sortFunctionalLocations));

const filterableFields = ['functionalLocation', 'description', 'name', 'address', 'city', 'country'];

const filterItems = memoizeOne((items, search) => {
  const lowercaseSearch = search.toLowerCase().trim();
  if (!lowercaseSearch) {
    return items;
  }

  return items.filter(item =>
    filterableFields.some(field => item[field] && _.includes(item[field].toLowerCase(), lowercaseSearch))
  );
});

class SearchResults extends React.Component {
  state = {
    filter: '',
    page: 1,
    pageChanged: false,
  };

  handleFilterChange = filter => {
    this.setState({
      filter,
      page: 1,
    });
  };

  handleFilterClear = () => this.handleFilterChange('');

  handlePageChange = page => this.setState({ page, pageChanged: true });

  render() {
    const {
      t,
      model,
      items,
      customer,
      portfolioCounts,
      addFunctionalLocation,
      deleteFunctionalLocation,
      addPartnerPermission,
    } = this.props;

    if (!model || !customer) {
      return null;
    }

    const {
      deletedFunctionalLocations = {},
      addedFunctionalLocations = {},
      deletedPartnerPermissions = [],
      addedPartnerPermissions = [],
    } = model;

    const partnerRemoved = _.includes(deletedPartnerPermissions, customer.partnerNumber);
    const partnerAddedPreviously = _.includes(model.partnerPermissions, customer.partnerNumber);
    const partnerAdded =
      !partnerRemoved && (partnerAddedPreviously || _.includes(addedPartnerPermissions, customer.partnerNumber));

    const sorted = sortItems(items);
    const filteredItems = filterItems(sorted, this.state.filter);

    const startIndex = (this.state.page - 1) * NUMBER_OF_SEARCH_RESULTS;
    const page = filteredItems.slice(startIndex, startIndex + NUMBER_OF_SEARCH_RESULTS);

    // Transform functional location items that match the parent number search box to
    // functional location list items.
    const searchResults = page.map(functionalLocation => {
      const partner = customer.partnerNumber;
      const added =
        model &&
        model.permissions[partner] &&
        _.includes(model.permissions[partner], functionalLocation.functionalLocation);
      const newlyAdded =
        addedFunctionalLocations[partner] &&
        _.includes(addedFunctionalLocations[partner], functionalLocation.functionalLocation);
      const deleted =
        deletedFunctionalLocations[partner] &&
        _.includes(deletedFunctionalLocations[partner], functionalLocation.functionalLocation);

      return (
        <PermissionListItemFunctionalLocation
          key={functionalLocation.functionalLocation}
          t={t}
          functionalLocation={functionalLocation}
          add={addFunctionalLocation(functionalLocation.functionalLocation)}
          remove={deleteFunctionalLocation(functionalLocation.functionalLocation, customer.partnerNumber)}
          disabled={(added && !newlyAdded) || partnerAdded}
          added={!partnerAdded && (added || newlyAdded)}
          included={partnerAdded}
          removed={deleted && !partnerAdded}
        />
      );
    });

    return (
      <React.Fragment>
        <Header>
          <Heading>{`${items.length} ${t('Results Found')}`}</Heading>
          <Search>
            <InputSearch
              onChange={this.handleFilterChange}
              onClear={this.handleFilterClear}
              placeholder={t('Filter name, id or address')}
            />
          </Search>
        </Header>
        {this.state.pageChanged && <ScrollToComponent key={this.state.page} />}
        <FunctionalLocationList>
          {customer && (
            <PermissionListItemPortfolio
              t={t}
              customer={customer}
              functionalLocationCount={portfolioCounts[customer.partnerNumber]}
              add={addPartnerPermission(customer.partnerNumber)}
              added={partnerAdded}
              removed={partnerRemoved}
              disabled={partnerAdded}
            />
          )}
          {searchResults}
        </FunctionalLocationList>
        {filteredItems.length > NUMBER_OF_SEARCH_RESULTS && (
          <div style={{ marginTop: '2em' }}>
            <ControlledPager
              page={this.state.page}
              onChange={this.handlePageChange}
              totalResults={filteredItems.length}
              limit={NUMBER_OF_SEARCH_RESULTS}
              center
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

SearchResults.propTypes = {
  t: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  model: PropTypes.object,
  customer: PropTypes.object,
  portfolioCounts: PropTypes.objectOf(PropTypes.number).isRequired,
  addFunctionalLocation: PropTypes.func.isRequired,
  deleteFunctionalLocation: PropTypes.func.isRequired,
  addPartnerPermission: PropTypes.func.isRequired,
};

export default SearchResults;
