import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import Section from 'components/Section/Section';
import RecyclingModuleSkeleton from './RecyclingModuleSkeleton';
import RecyclingOPICards from './RecyclingOPICards/RecyclingOPICards';
import WasteAmountsBreakdown from './WasteAmountsBreakdown/WasteAmountsBreakdown';
import { connect } from 'react-redux';
import { getPartnerNumbers } from 'utils/profile';
import { loadOPIData } from 'redux/modules/iot/values/recycling';
import {
  getWasteSensors,
  calculateRecyclingOPIValues,
} from 'containers/Application/Modules/RecyclingModule/RecyclingDataUtils';

export const RecyclingModule = ({
  match: {
    params: { partnerNumber, functionalLocationId },
  },
  profile,
  buildingHierarchy,
  loadOPIData,
  error,
  OPIData,
  meta,
}) => {
  // effect to fetch the data once, after buildingHierarchy has been fetched to store
  // or if the selected functional location has been changed
  useEffect(
    () => {
      if (!isEmpty(buildingHierarchy)) {
        const sensorIds = getWasteSensors(buildingHierarchy, functionalLocationId).map(sensor => sensor.id);
        if (OPIData === null || meta.functionalLocationId !== functionalLocationId) {
          loadOPIData({
            functionalLocationId,
            sensorIds,
            aggregation: 'dailySum',
            startTime: moment()
              .subtract(30, 'days')
              .utc()
              .toISOString(),
            endTime: moment.utc().toISOString(),
          });
        }
      }
    },
    [buildingHierarchy, functionalLocationId]
  );
  // validate URL path to have a valid partner number
  if (!partnerNumber || partnerNumber === 'all') {
    return <ErrorPage type="selectPartner" />;
  }
  // display skeleton while waiting for the initial store data
  if (isEmpty(profile) || isEmpty(buildingHierarchy) || isEmpty(functionalLocationId)) {
    return <RecyclingModuleSkeleton />;
  }
  // validate that the user profile has rights to this partner number
  if (!includes(getPartnerNumbers(profile), partnerNumber)) {
    return <ErrorPage type="partner" />;
  }
  // handle API error
  if (error.status) {
    return <Section>{error.message}</Section>;
  }
  // display skeleton while waiting for API response
  if (!OPIData) {
    return <RecyclingModuleSkeleton />;
  }
  // do calculations
  const wasteSensors = getWasteSensors(buildingHierarchy, functionalLocationId);
  const { recyclingRate, recoveryRate, carbonFootprint } = calculateRecyclingOPIValues(OPIData, wasteSensors);

  return (
    <Fragment>
      <RecyclingOPICards recyclingRate={recyclingRate} recoveryRate={recoveryRate} carbonFootprint={carbonFootprint} />
      <WasteAmountsBreakdown functionalLocationId={functionalLocationId} wasteSensors={wasteSensors} />
    </Fragment>
  );
};

RecyclingModule.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      partnerNumber: PropTypes.string.isRequired,
      functionalLocationId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  profile: PropTypes.object.isRequired,
  buildingHierarchy: PropTypes.object.isRequired,
  loadOPIData: PropTypes.func.isRequired,
  error: PropTypes.shape({
    status: PropTypes.bool.isRequired,
    message: PropTypes.string,
  }).isRequired,
  OPIData: PropTypes.array,
  meta: PropTypes.shape({
    functionalLocationId: PropTypes.string.isRequired,
    sensorIds: PropTypes.arrayOf(PropTypes.number.isRequired),
  }),
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
  customers: state.customer.customers,
  buildingHierarchy: state.sensorHierarchy.buildingHierarchy,
  error: state.values.recyclingOPI.error,
  OPIData: state.values.recyclingOPI.data,
  meta: state.values.recyclingOPI.meta,
});

const mapDispatchToProps = {
  loadOPIData,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RecyclingModule)
);
