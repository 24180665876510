import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { getPerformanceStatus, performanceColors } from 'utils/Data/performance';

const Card = styled.section`
  display: flex;
  flex: 1;
  align-items: center;
  cursor: ${props => props.hasPointer && 'pointer'};
  padding: ${props => props.theme.spacing.xs};
  background-color: ${props =>
    props.isLoading
      ? props.theme.colors.mystic
      : props.selected
      ? props.theme.colors.alabaster
      : props.theme.colors.white};
  border: 1px solid ${props => (props.selected ? props.theme.colors.darkGray : props.theme.colors.lightGray)};
  &:not(:last-child) {
    border-right: none;
  }
  & + section {
    border-left-color: ${props => props.selected && props.theme.colors.darkGray};
  }
  text-align: center;
`;
Card.displayName = 'Card';

const Value = styled.span`
  font-family: ${props => props.theme.font.family.arial};
  font-weight: ${props => props.theme.font.weight.normal};
  font-size: ${props => props.theme.font.size.xs};
  color: ${props => props.color || props.theme.colors.midnight};
`;
Value.displayName = 'Value';

const PlainTitle = styled.span`
  color: ${props => props.theme.colors.midnight};
  font-family: ${props => props.theme.font.family.arial};
  font-weight: ${props => (props.selected ? props.theme.font.weight.bold : props.theme.font.weight.normal)};
  font-size: ${props => props.theme.font.size.xs};
  word-wrap: break-word;
`;
PlainTitle.displayName = 'PlainTitle';

const PerformanceNarrowItem = ({ loading, onClick, selected, title, value, theme, color, noChart }) => {
  if (loading) {
    return (
      <Card isLoading>
        <PlainTitle>{title}:&nbsp;</PlainTitle>
        <Value>...</Value>
      </Card>
    );
  }

  const valueColor = color || performanceColors({ theme })[getPerformanceStatus(value, true)];

  return (
    <Card onClick={onClick} hasPointer={!!onClick} selected={selected}>
      <PlainTitle selected={selected}>{title}:&nbsp;</PlainTitle>
      <Value color={valueColor}>
        {value}
        {noChart ? '' : '%'}
      </Value>
    </Card>
  );
};

PerformanceNarrowItem.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  theme: PropTypes.object,
  color: PropTypes.string,
  noChart: PropTypes.bool,
};

export default withTheme(PerformanceNarrowItem);
