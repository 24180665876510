import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { loadEnergyChartValues } from 'redux/modules/iot/values/energy_chart_values';

const getZoomedChartValues = (state, props) =>
  props.functionalLocation && {
    ...state.values.energyChartValues.energyChartValues.byFl[props.functionalLocation.functionalLocation],
  };

const getMonthlyChartValues = (state, props) =>
  props.functionalLocation && state.values.flEnergyValues.energyValues[props.functionalLocation.functionalLocation];

const getChartValuesByPartnerNumber = (state, props) =>
  props.partnerNumber && state.values.energyChartValues.energyChartValues.byPartner[props.partnerNumber];

const energyChartValuesSelector = createSelector(
  [getMonthlyChartValues, getZoomedChartValues, getChartValuesByPartnerNumber],
  (monthlySum, zoomedValues, chartValuesByPartnerNumber) => {
    if (monthlySum || zoomedValues) {
      return { monthlySum, ...zoomedValues };
    }
    return chartValuesByPartnerNumber || {};
  }
);

const mapStateToProps = (state, props) => ({
  energyChartValues: energyChartValuesSelector(state, props),
  loadingEnergyChartValues: state.values.energyChartValues.loadingEnergyChartValues,
});

const mapDispatchToProps = {
  loadEnergyChartValues,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector;
