import { connect } from 'react-redux';

import { loadBuildingMeta } from 'redux/modules/buildingConfig/buildingMeta';
import { loadFunctionalLocationsEnergyValues } from 'redux/modules/iot/values/fl_energy_values';
import { loadSensorsValues } from 'redux/modules/iot/values/sensor_values';
import { loadEnergyRating } from 'redux/modules/iot/values/energy_rating';

const mapStateToProps = (state, props) => ({
  energySensors:
    props.functionalLocation && state.values.flEnergyValues.energySensors[props.functionalLocation.functionalLocation],
  energyValues:
    props.functionalLocation && state.values.flEnergyValues.energyValues[props.functionalLocation.functionalLocation],
  energyChartValues:
    props.functionalLocation &&
    state.values.energyChartValues.energyChartValues.byFl[props.functionalLocation.functionalLocation],
  loading: state.values.flEnergyValues.loadingEnergyValues,
  buildingMeta: state.buildingMeta,
  energyRatingValuesByFL: state.values.energyRating.energyRating.data,
  valuesBySensorId: state.values.sensors.valuesBySensorId,
  loadingSensorValues: state.values.sensors.loadingSensorValues,
});

const mapDispatchToProps = dispatch => ({
  loadFunctionalLocationsEnergyValues: functionalLocation => {
    dispatch(loadFunctionalLocationsEnergyValues(null, [functionalLocation]));
  },
  loadBuildingMeta: fl => dispatch(loadBuildingMeta([fl], false)),
  loadEnergyRatingValuesByFL: functionalLocations => dispatch(loadEnergyRating(functionalLocations)),
  loadSensorsValues: (sensors, startTime, endTime, aggregation) => {
    dispatch(loadSensorsValues(sensors, startTime, endTime, aggregation));
  },
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector;
