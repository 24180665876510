import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import addNoDataModule from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import translations from 'decorators/Translations/translations';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { calculateRecyclingBreakdownPerWasteSerie } from 'containers/Application/Modules/RecyclingModule/RecyclingDataUtils';
import { createBarChartConfig } from 'containers/Application/Modules/RecyclingModule/WasteAmountsBreakdown/BarChartConfig';

addNoDataModule(Highcharts);

const StyledWasteAmountsChart = styled.div`
  .highcharts-legend-item rect {
    visibility: hidden;
  }
  .highcharts-legend-item {
    margin-left: -25px;
  }
`;

export const WasteAmountsChart = ({ t, theme, wasteSensors, error, wasteAmountsData }) => {
  if (error.status || !wasteAmountsData) {
    return (
      <SkeletonChart height="400px" width="100%">
        {t('Loading data')}...
      </SkeletonChart>
    );
  }
  const serieName = t('Waste amount');
  const serie = calculateRecyclingBreakdownPerWasteSerie(wasteAmountsData, wasteSensors);

  // map serie to Highcharts compatible form, do translations and coloring
  const translatedSerie = serie.map(entry => ({
    name: t(entry.wasteType),
    y: entry.totalValue,
    _unit: t(entry.unit),
    color: theme.charts.colorsRecycling[entry.colorName],
  }));
  const categories = translatedSerie.map(entry => t(entry.name));
  const customLegend = [
    { name: t('Recyclable waste'), color: theme.charts.colorsRecycling.recyclableWaste },
    { name: t('Non-recyclable waste'), color: theme.charts.colorsRecycling.nonRecyclableWaste },
  ];
  const barChartConfig = createBarChartConfig({
    theme,
    categories,
    serie: translatedSerie,
    serieName,
    customLegend,
    customProps: { lang: { noData: t('No data to display') } },
  });
  return (
    <StyledWasteAmountsChart>
      <HighchartsReact highcharts={Highcharts} options={barChartConfig} />
    </StyledWasteAmountsChart>
  );
};

WasteAmountsChart.defaultProps = {
  wasteSensors: [],
};

WasteAmountsChart.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  wasteSensors: PropTypes.array.isRequired,
  error: PropTypes.shape({
    status: PropTypes.bool.isRequired,
    message: PropTypes.string,
  }).isRequired,
  wasteAmountsData: PropTypes.array,
};

const mapStateToProps = state => ({
  error: state.values.recyclingWasteAmounts.error,
  wasteAmountsData: state.values.recyclingWasteAmounts.data,
});

export default connect(mapStateToProps)(withTheme(translations(WasteAmountsChart)));
