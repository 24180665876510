import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import ContextualHelp, { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import WasteAmountsBreakdownTab from 'containers/Application/Modules/RecyclingModule/WasteAmountsBreakdown/WasteAmountsBreakdownTab';
import translations from 'decorators/Translations/translations';

export const WasteAmountsBreakdown = ({ t, wasteSensors, functionalLocationId }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const tabOptions = [{ value: 0, label: t('Waste amounts') }];
  return (
    <Section>
      <SectionHeader t={t}>
        <SectionTabSelector
          t={t}
          options={tabOptions}
          model={{ tabSelected: selectedTabIndex }}
          property="tabSelected"
          onTabChange={(property, value) => setSelectedTabIndex(value)}
          left
          large
        />
        <ContextualHelp
          t={t}
          title={'Waste breakdown'}
          text={`${CTXHELP_PREFIX} Waste breakdown`}
          standalone
          position="bottom"
        />
      </SectionHeader>
      <WasteAmountsBreakdownTab functionalLocationId={functionalLocationId} wasteSensors={wasteSensors} />
    </Section>
  );
};

WasteAmountsBreakdown.defaultProps = {
  wasteSensors: [],
};

WasteAmountsBreakdown.propTypes = {
  t: PropTypes.func.isRequired,
  functionalLocationId: PropTypes.string.isRequired,
  wasteSensors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default translations(WasteAmountsBreakdown);
