import React from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { rgba } from 'polished';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';

import Svg from 'components/Svg/Svg';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonCircle from 'components/Skeletons/SkeletonCircle';
import GaugeChart from './../Charts/GaugeChart';
import { getPerformanceStatus, performanceColors } from 'utils/Data/performance';
import ContextualHelp from 'components/ContextualHelp/ContextualHelp';
import Column from 'components/Columns/Column';

import { Card } from './Card';
import InfoCard from './InfoCard';

const StyledGaugeChart = styled(GaugeChart)`
  margin-bottom: ${props => props.theme.spacing.xs};
  ${props => props.theme.media.desktop`
        margin-bottom: 0;
    `}
`;

const IconContainer = styled.div`
  display: none;
  margin-left: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  ${props => props.theme.media.desktop`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    `}
`;

const IconWrapper = styled.div`
  padding: ${props => (props.dense ? '0.5em' : '0.75em')};
  ${props => (props.top ? 'margin-top: 0;' : props.bottom ? 'margin-bottom: 0;' : '')};
  ${props => props.onlyIcon && 'margin: auto 0'};
  margin-right: 0;
  display: flex;
  align-items: center;
  height: 50%;
`;

const Icon = styled(Svg)`
  font-size: ${props => props.theme.font.size.md};
  fill: ${props => props.theme.colors.darkGray};
  margin-right: 0;
`;

const IconDivider = styled.hr`
  border-top-color: ${props => props.theme.colors.mystic};
  border-top-width: 1px;
  width: 60%;
  margin: 0;
  padding: 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 100%;

  ${props => props.theme.media.desktop`
    padding: 0 ${props => props.theme.spacing.md};
    margin-right: ${props => props.theme.spacing.xl};
  `}
`;

const Title = styled.div`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSize.note};
  color: ${props => props.theme.colors.black};
  word-wrap: break-word;

  ${props => props.theme.media.landscape`
        font-size: ${props => props.theme.fontSize.text};
    `}
`;

const Value = styled.div`
  color: ${props => performanceColors(props)[props.status]};
  font-size: ${props => props.theme.fontSize.note};
  order: -1;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => props.theme.media.desktop`
        display: block;
        height: auto;
        order: 0;
        padding-top: 0.5em;
        margin-bottom: 0;
    `}
`;

const Subtitle = styled.div`
  color: ${props => (props.invalid ? props.theme.colors.radicalRed : props.theme.colors.darkGray)};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.normal};
  font-family: ${props => props.theme.font.arial};
  letter-spacing: 0.3px;
  padding: 0.5em 0 0;
  white-space: pre-line;
  line-height: ${props => props.theme.font.size.xs};
`;

const getValueColor = props => {
  if (props.invalid) {
    return props.theme.colors.darkGray;
  } else if (performanceColors(props)[props.status]) {
    return performanceColors(props)[props.status];
  } else if (props.isNegative) {
    return props.theme.status.alarmColor;
  } else if (props.isPositive) {
    return props.theme.status.okColor;
  }
  return props.theme.colors.midnight;
};

const InnerValue = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: ${props => getValueColor(props)};
  font-size: ${props => props.theme.font.size.lg};
  font-weight: ${props => props.theme.fontWeight.normal};
  line-height: ${props => props.theme.font.lineHeight.md};
  width: 70px;
  height: 70px;
  min-height: 70px;

  ${props => props.theme.media.desktop`
        font-size: ${props => props.theme.font.size.md};
    `}
`;

const InnerValueValue = styled.span``;

const InnerValueLabel = styled.span`
  font-size: ${props => props.theme.fontSize.xxs};
  text-align: center;

  ${props => props.theme.media.landscape`
        white-space: normal;
    `}
`;

const LoadingCircleContainer = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SkeletonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  padding: ${props => props.theme.spacing.xs};
  & > *:first-child {
    display: none;
  }
  ${props => props.theme.media.portrait`
        margin-bottom: 2px;
    `}

  ${props => props.theme.media.landscape`
        padding: ${props => props.theme.spacing.sm};
        margin-bottom: 0;
        & > *:first-child {
            display: block;
        }
    `}
`;

const StringGaugeValue = styled.span`
  color: ${props => props.theme.colors.darkGray};
`;

const TitleAndTrendWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${props => props.theme.media.desktop`
    flex-direction: row;
    flex-flow: wrap;
    ${Trend} {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    ${Title} {
      margin-right: ${props => props.theme.spacing.xs};
    }
  `}
`;

export const TREND_TYPE = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  NEUTRAL: 'NEUTRAL',
};

const Trend = styled.span`
  background-color: ${props => {
    switch (props.type) {
      case TREND_TYPE.POSITIVE:
        return rgba(props.theme.colors.emerald, 0.2);
      case TREND_TYPE.NEGATIVE:
        return rgba(props.theme.colors.radicalRed, 0.2);
      case TREND_TYPE.NEUTRAL:
        return rgba(props.theme.colors.midnight, 0.2);
      default:
        return null;
    }
  }};
  font-size: ${props => props.theme.font.size.xxxs};
  font-weight: ${props => props.theme.fontWeight.bold};
  font-family: ${props => props.theme.font.family.arial};
  line-height: ${props => props.theme.fontSize.note};
  color: ${props => props.theme.colors.black};
  padding: 0 ${props => props.theme.spacing.xxs};
  white-space: nowrap;
  align-self: center;
  border-radius: 2px;
  margin-bottom: ${props => props.theme.spacing.xs};
`;

const naviOpenColumnWidth = {
  bigDesktop: 3,
  desktop: 4,
  landscape: 3,
  portrait: 3,
  default: 6,
};

const naviClosedColumnWidth = {
  bigDesktop: 3,
  desktop: 3,
  landscape: 4,
  portrait: 3,
  default: 6,
};

const formatNumber = n => {
  if (n < 1e3) {
    return n.toFixed(0);
  }
  return `${+(n / 1e3).toFixed(1)}k`;
};

const OPICard = props => {
  const {
    t,
    theme,
    value,
    title,
    subtitle,
    icon = 'opi-arrow',
    neutral,
    invalidValue,
    valueLabel,
    valueInside,
    valueInsideLabel,
    valueInsideIsPositive,
    valueInsideIsNegative,
    noCircle,
    loading,
    ctxHelp,
    ctxHelpOverride,
    ctxHelpPosition = 'top',
    ctxHelpLink,
    naviExtended,
    dense,
    overrideColumnWidth,
    infoCard,
    isAirQuality = false,
    trend,
  } = props;

  const hasClick = () => {
    return typeof props.onClick === 'function';
  };

  const onClick = e => {
    if (hasClick()) {
      props.onClick();
    }
  };

  const status = props.status || (!isNil(value) && getPerformanceStatus(value, isAirQuality));
  const hasCtxHelp = ctxHelp && t(ctxHelp) !== ctxHelp;
  const roundedValue = Math.round(value);

  const columnWidth = overrideColumnWidth || (naviExtended ? naviOpenColumnWidth : naviClosedColumnWidth);
  if (loading) {
    return (
      <Column columnWidth={columnWidth}>
        <Card dense={dense} naviClosed={!naviExtended}>
          <LoadingCircleContainer>
            <SkeletonCircle width="60px" borderWidth="6px" margin="0px" />
          </LoadingCircleContainer>
          <SkeletonTextContainer>
            <SkeletonText />
            <SkeletonText />
          </SkeletonTextContainer>
        </Card>
      </Column>
    );
  }

  if (infoCard) {
    return <InfoCard {...props} columnWidth={columnWidth} />;
  }

  let stops = neutral && [[0, theme.colors.midnight]];
  stops = isAirQuality || props.status ? [[0, performanceColors({ theme })[status]]] : stops;

  return (
    <Column columnWidth={columnWidth}>
      <Card
        data-test-id={loading ? 'SkeletonOPICard' : 'OPICard'}
        onClick={onClick}
        noCursor={!hasClick()}
        dense={dense}
        naviClosed={!naviExtended}
      >
        {!noCircle && (
          <div>
            <StyledGaugeChart
              size={70}
              value={!isNaN(roundedValue) ? roundedValue : 0}
              showValue={!isNaN(roundedValue)}
              max={100}
              min={0}
              unit="%"
              valueColor={(!neutral && performanceColors(props)[status]) || theme.colors.midnight}
              background={theme.colors.mystic}
              backgroundPadding={0}
              lineWidth={22}
              hidePlus
              stops={stops}
            >
              {isString(value) ? <StringGaugeValue>{value}</StringGaugeValue> : undefined}
            </StyledGaugeChart>
          </div>
        )}
        {!isNil(valueInside) && (
          <InnerValue
            status={status}
            invalid={invalidValue}
            isPositive={valueInsideIsPositive}
            isNegative={valueInsideIsNegative}
          >
            <InnerValueValue>{Number.isFinite(valueInside) ? formatNumber(valueInside) : valueInside}</InnerValueValue>
            {valueInsideLabel && <InnerValueLabel>{valueInsideLabel}</InnerValueLabel>}
          </InnerValue>
        )}
        <TitleContainer>
          <TitleAndTrendWrapper>
            <Title>{title}</Title>
            {trend?.value && <Trend type={trend.type}>{trend.value}</Trend>}
          </TitleAndTrendWrapper>
          {valueLabel && <Value status={!neutral && status}>{valueLabel}</Value>}
          {subtitle && <Subtitle invalid={invalidValue}>{subtitle}</Subtitle>}
        </TitleContainer>
        <IconContainer>
          {hasCtxHelp && (
            <IconWrapper top noCursor onClick={e => e.stopPropagation()} dense={dense}>
              <ContextualHelp
                t={t}
                title={title}
                text={ctxHelp}
                standalone
                override={ctxHelpOverride}
                position={ctxHelpPosition}
                link={ctxHelpLink}
              />
            </IconWrapper>
          )}
          {hasCtxHelp && hasClick() && <IconDivider />}
          {hasClick() && (
            <IconWrapper bottom onlyIcon={!hasCtxHelp} dense={dense}>
              <Icon name={icon} />
            </IconWrapper>
          )}
        </IconContainer>
      </Card>
    </Column>
  );
};

OPICard.propTypes = {
  onClick: PropTypes.func,
  valueLabel: PropTypes.string,
  valueInside: PropTypes.number,
  valueInsideLabel: PropTypes.string,
  valueInsideIsPositive: PropTypes.bool,
  valueInsideIsNegative: PropTypes.bool,
  ctxHelpLink: PropTypes.string,
  ctxHelpPosition: PropTypes.string,
  ctxHelpOverride: PropTypes.bool,
  ctxHelp: PropTypes.string,
  naviExtended: PropTypes.bool,
  loading: PropTypes.bool,
  isAirQuality: PropTypes.bool,
  invalidValue: PropTypes.bool,
  icon: PropTypes.string,
  infoCard: PropTypes.bool,
  dense: PropTypes.bool,
  neutral: PropTypes.bool,
  noCircle: PropTypes.bool,
  overrideColumnWidth: PropTypes.bool,
  t: PropTypes.func,
  theme: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  value: PropTypes.string,
  trend: PropTypes.shape({
    value: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(TREND_TYPE)),
  }),
  status: PropTypes.string,
};

const mapStateToProps = state => ({
  naviExtended: state.navigation.sideNaviExtended,
});

const connector = connect(mapStateToProps);

export default connector(withTheme(OPICard));
