import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import _ from 'lodash';
import queryString from 'query-string';

import OPICards from 'components/OPICard/OPICards';
import OPICard from 'components/OPICard/OPICard';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import StatusViewChart from 'components/Charts/StatusViewChart';
import BuildingStatusView from 'components/BuildingStatusView/BuildingStatusView';
import ScrollToComponent from 'components/ScrollToComponent/ScrollToComponent';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import Dialog from 'components/Dialog/Dialog';
import DialogFrame from 'components/Dialog/DialogFrame';
import SensorValues from 'containers/Application/SensorValues/SensorValues';

import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { utilizationTypes } from 'utils/Data/values';
import { getOPICards, getBuildingConditionsValues, getUtilizationRateValues, getAirQualityValues } from './utils';
import { withRouter } from 'react-router';

const BuildingAutomationModule = ({
  buildingConditions,
  buildingSensors,
  floors,
  functionalLocation,
  loadingValues,
  loading,
  loadingBuilding,
  sensorValues,
  t,
  theme,
  latestValues,
  buildingMeta,
  location,
  history,
}) => {
  const [scrollTo, setScrollTo] = React.useState();

  if (loading || loadingBuilding) {
    return (
      <Fragment>
        <OPICards>
          <OPICard loading />
        </OPICards>
        <Section>
          <SkeletonText header />
          <SkeletonChart style={{ marginTop: '2em' }} />
        </Section>
      </Fragment>
    );
  }

  const openModal = modalId => {
    const queryParamsString = queryString.stringify({
      ...queryString.parse(location.search),
      openModalId: modalId,
    });
    history.push(`${location.pathname}?${queryParamsString}`);
  };

  const closeModal = () => {
    const queryParamsWithoutModal = _.omit(queryString.parse(location.search), ['openModalId']);
    const queryParamsString = queryString.stringify(queryParamsWithoutModal);
    history.push(`${location.pathname}?${queryParamsString}`);
  };

  const scrollToElement = scrollTo => {
    setScrollTo(scrollTo);
    setTimeout(() => setScrollTo(null), 500);
  };

  const airQualityValues = getAirQualityValues(floors, sensorValues, latestValues, t);
  const buildingConditionsValues = getBuildingConditionsValues(buildingConditions);
  const utilizationRateValues = getUtilizationRateValues(floors, sensorValues, buildingMeta, t);

  const buildingStatusData = [buildingConditionsValues.cooling, buildingConditionsValues.heating];

  const buildingChartColors = [theme.colors.blue, theme.colors.orange, theme.colors.rockBlue];

  const hasUtilizationSensors = _.some(
    buildingSensors,
    sensor => sensor.sensorType && utilizationTypes.indexOf(sensor.sensorType.name) !== -1
  );

  const opiCards = getOPICards(
    airQualityValues,
    _.last(buildingConditionsValues.outdoorTemperature),
    _.last(buildingConditionsValues.cooling),
    _.last(buildingConditionsValues.heating),
    utilizationRateValues.presenceArea,
    utilizationRateValues.presenceZone,
    utilizationRateValues.presenceSeat,
    openModal,
    t
  );

  const { openModalId } = queryString.parse(location.search);
  const modal = openModalId && opiCards.find(card => card.modal?.id === openModalId)?.modal;

  const areas = _.filter(_.flatMap(floors, 'children'), { type: 'area' });

  return (
    <Fragment>
      <OPICards>
        {opiCards.map((item, index) => {
          const {
            title,
            value,
            valueInside,
            valueInsideLabel,
            subtitle,
            scrollTo,
            neutral,
            noCircle,
            s2Link,
            isAirQuality,
            icon,
            modal,
          } = item;
          return (
            <OPICard
              t={t}
              title={t(title)}
              subtitle={subtitle && t(subtitle)}
              value={value}
              valueInside={valueInside}
              valueInsideLabel={valueInsideLabel && t(valueInsideLabel)}
              icon={icon}
              onClick={() => (modal ? openModal(modal.id) : scrollToElement(scrollTo))}
              key={title}
              neutral={neutral}
              noCircle={noCircle}
              ctxHelp={`${CTXHELP_PREFIX} ${title} OPI`}
              ctxHelpPosition={(index + 1) % 3 === 0 ? 'left' : 'top'}
              ctxHelpLink={s2Link ? t('s2_link') : null}
              isAirQuality={isAirQuality}
            />
          );
        })}
      </OPICards>
      {hasUtilizationSensors && (
        <Section>
          {scrollTo === 'BS' && <ScrollToComponent />}
          <SectionHeader t={t} title={t('Building Status')} ctxHelp={`${CTXHELP_PREFIX} Building Status`} />
          <BuildingStatusView>
            <StatusViewChart
              series={buildingStatusData}
              temperature={buildingConditionsValues.outdoorTemperature}
              colors={buildingChartColors}
              t={t}
              loading={loadingValues}
            />
          </BuildingStatusView>
        </Section>
      )}
      {modal && (
        <Dialog isActive={true} onOverlayClick={closeModal}>
          <DialogFrame onClose={closeModal} smallVerticalPadding>
            <SensorValues
              functionalLocation={functionalLocation}
              buildingMeta={buildingMeta}
              buildingSensors={buildingSensors}
              areas={areas}
              {...modal}
            />
          </DialogFrame>
        </Dialog>
      )}
    </Fragment>
  );
};

BuildingAutomationModule.propTypes = {
  buildingConditions: PropTypes.object,
  buildingSensors: PropTypes.array,
  floors: PropTypes.array,
  functionalLocation: PropTypes.object,
  loadingValues: PropTypes.bool,
  loading: PropTypes.bool,
  loadingBuilding: PropTypes.bool,
  sensorValues: PropTypes.array,
  t: PropTypes.func,
  theme: PropTypes.object,
  latestValues: PropTypes.object,
  buildingMeta: PropTypes.array,
  location: PropTypes.object,
  history: PropTypes.object,
};

const COMPONENT = 'BuildingStatusModule';

const mapStateToProps = (state, ownProps) => ({
  loadingValues:
    ownProps.functionalLocation &&
    _.includes(state.common.loading[COMPONENT], ownProps.functionalLocation.functionalLocation),
  loadingBuilding: state.buildingContainer.loading,
  latestValues: state.values.sensors.latestValuesBySensorId,
});

const connector = connect(mapStateToProps);

export default withRouter(connector(withTheme(BuildingAutomationModule)));
