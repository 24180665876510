import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import memoizeOne from 'memoize-one';
import { transparentize } from 'polished';

import Loader from 'components/Loader/Loader.jsx';
import GaugeChart from 'components/Charts/GaugeChart';

const getStopsForColor = memoizeOne(color => [[Number.MIN_VALUE, color]]);

const Card = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 130px;
  min-width: 130px;
  cursor: ${props => props.hasPointer && 'pointer'};
  padding: ${props => props.theme.spacing.xs};
  background-color: ${props => (props.selected ? props.theme.colors.alabaster : props.theme.colors.white)};
  border: 1px solid ${props => (props.selected ? props.theme.colors.darkGray : props.theme.colors.lightGray)};
  border-bottom: ${props => props.highlight && `2px solid ${props.theme.colors.cerulean}`};
  &:not(:last-child) {
    border-right: none;
  }
  & + section {
    border-left-color: ${props => props.selected && props.theme.colors.darkGray};
  }
  text-align: center;

  ${props => props.theme.media.portrait`
        padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.xs} ${props =>
    props.theme.spacing.sm};
        padding: ${props => props.noChart && !props.nextToChart && props.theme.spacing.sm};
    `};

  ${props => props.theme.media.landscape`
        max-width: 152px;
        min-width: 152px;
    `};
`;
Card.displayName = 'Card';

const Title = styled.div`
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.font.family.arial};
  font-weight: ${props => (props.selected ? props.theme.font.weight.bold : props.theme.font.weight.normal)};
  font-size: ${props => props.theme.font.size.xs};
  word-wrap: break-word;

  ${props => props.theme.media.portrait`
        font-size: ${props => props.theme.font.size.xs};
    `}
`;
Title.displayName = 'Title';

const Subtitle = styled.div`
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.normal};
  font-family: ${props => props.theme.font.arial};
  padding-top: ${props => props.theme.spacing.xxs};
  letter-spacing: 0.3px;
`;
Subtitle.displayName = 'Subtitle';

const ValueContainer = styled.div`
  height: ${props => (props.noChart && !props.nextToChart ? '40px' : '70px')};
  display: flex;
  align-items: center;
  justify-content: center;
`;
ValueContainer.displayName = 'ValueContainer';

const Value = styled.span`
  font-family: ${props => props.theme.font.family.arial};
  font-weight: ${props => props.theme.font.weight.normal};
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.color || props.theme.colors.black};
  background-color: ${props => props.backgroundColor && transparentize(0.9, props.backgroundColor)};
  padding: ${props => props.theme.spacing.xxs};

  ${props => props.theme.media.landscape`
        font-size: ${props => (props.backgroundColor ? props.theme.font.size.sm : props.theme.font.size.md)};
        padding: ${props => props.theme.spacing.xxs} ${props => props.theme.spacing.sm};
    `}
`;

const StatusMeter = props => {
  const {
    theme,
    value,
    title,
    subtitle,
    loading,
    onClick,
    selected,
    color,
    backgroundColor,
    noChart,
    nextToChart,
    highlight,
  } = props;

  if (loading) {
    return (
      <Card selected={selected} noChart={noChart} nextToChart={nextToChart}>
        <ValueContainer noChart={noChart} nextToChart={nextToChart}>
          <Loader color="GRAY" />
        </ValueContainer>
        <Title selected={selected}>{title}</Title>
        {subtitle && <Subtitle>...</Subtitle>}
      </Card>
    );
  }

  return (
    <Card
      onClick={onClick}
      hasPointer={!!onClick}
      selected={selected}
      noChart={noChart}
      nextToChart={nextToChart}
      color={color}
      highlight={highlight}
    >
      {noChart ? (
        <ValueContainer noChart={noChart} nextToChart={nextToChart}>
          <Value color={color} backgroundColor={backgroundColor}>
            {value}
          </Value>
        </ValueContainer>
      ) : (
        <GaugeChart
          size={70}
          value={value}
          showValue
          max={100}
          min={0}
          unit="%"
          valueColor={theme.colors.midnight}
          background={theme.colors.mystic}
          backgroundPadding={0}
          lineWidth={22}
          hidePlus
          stops={color ? getStopsForColor(color) : undefined}
        />
      )}
      <Title selected={selected}>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Card>
  );
};
StatusMeter.displayName = 'StatusMeter';

StatusMeter.defaultProps = {
  title: '',
  subtitle: undefined,
  loading: false,
  color: undefined,
  selected: false,
  onClick: undefined,
};

StatusMeter.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  loading: PropTypes.bool,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  theme: PropTypes.object,
  noChart: PropTypes.bool,
  nextToChart: PropTypes.bool,
  highlight: PropTypes.bool,
};

export default withTheme(StatusMeter);
