import React, { Fragment } from 'react';
import { isNil } from 'lodash';
import styled from 'styled-components';

import { getPerformanceStatus } from 'utils/Data/performance';
import { FLOOR_OPI } from 'components/Modules/FloorplanModule/FloorOPICards/FloorOPIUtils';
import { CELSIUS } from 'utils/Data/values';

const performanceColors = props => ({
  ok: props.theme.status.okColor,
  warning: props.theme.status.warningColor,
  alert: props.theme.status.alarmColor,
});

const TooltipRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${props => props.theme.fontSize.note};
  line-height: 1.5;
`;

const TooltipStatus = styled.span`
  color: ${props => performanceColors(props)[props.status]};
  margin-left: 2em;
`;

const FloorTooltip = ({ t, opiData }) => {
  const airQuality = opiData[FLOOR_OPI.airQuality];
  const temperature = opiData[FLOOR_OPI.temperature];

  const hasAirQuality = airQuality && airQuality.sensors && airQuality.sensors.length > 0;
  const hasTemperature = temperature && !isNil(temperature.value);

  if (!hasAirQuality && !hasTemperature) {
    return null;
  }

  return (
    <Fragment>
      {hasAirQuality && (
        <TooltipRow>
          <span>{t('Indoor Air Quality')}</span>
          <TooltipStatus status={getPerformanceStatus(airQuality.value, true)}>
            {airQuality.value ? `${airQuality.value}%` : '-'}
          </TooltipStatus>
        </TooltipRow>
      )}
      {hasTemperature && (
        <TooltipRow>
          <span>{t('Temperature')}</span>
          <TooltipStatus status={getPerformanceStatus(temperature.statusValue)}>
            {`${temperature.value}${CELSIUS}`}
          </TooltipStatus>
        </TooltipRow>
      )}
    </Fragment>
  );
};

export default FloorTooltip;
