import React, { Fragment, useState, useEffect, createRef } from 'react';
import styled from 'styled-components';
import FloorOPICards from 'components/Modules/FloorplanModule/FloorOPICards/FloorOPICards';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import Blueprint from 'components/Blueprint/Blueprint';
import Svg from 'components/Svg/Svg';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { controlsCss } from 'components/Blueprint/BlueprintMap';

const ControlsWrapper = styled.div`
  display: flex;
  ${controlsCss}
`;

const ExpandButton = styled.button`
  padding: 0;
  margin: 0 0 0 ${props => props.theme.spacing.md};
  border: 0;
  background: ${props => props.theme.colors.white};
  cursor: pointer;

  &:hover svg {
    fill: ${props => props.theme.colors.blue};
  }
`;

const Icon = styled(Svg)`
  fill: ${props => props.theme.colors.darkGray};
  font-size: 2em;
`;
Icon.displayName = 'Icon';

const FloorTab = props => {
  const {
    t,
    isSelectedStickyTab,
    floor,
    loading,
    toggleDialog,
    opiData,
    renderTabLabel,
    isExpanded,
    toggleExpand,
    content,
    selectedTab,
  } = props;

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (mounted === false) {
      setMounted(true);
    }
  });
  const controlsRef = createRef();

  if (!isSelectedStickyTab) {
    return null;
  }

  const imageSize = { width: floor.imageWidth, height: floor.imageHeight };

  // Get togglable feature group names from area titles
  const featureGroupNames = floor.areas.features.map(feature => feature.properties.title);
  // Map areas to their own feature groups so they can have their own colors and filtering
  const areaFeatureGroups = floor.areas.features.map(feature => {
    return [
      {
        type: 'FeatureCollection',
        features: [feature],
      },
    ];
  });

  const getControlsRef = () => (controlsRef ? controlsRef.current : null);

  return (
    <Fragment>
      <FloorOPICards t={t} loading={loading} toggleDialog={toggleDialog} data={opiData} />
      <Section>
        <SectionHeader t={t} title={`${t('Floor')} ${floor.title || floor.number}`} ctxHelp={`${CTXHELP_PREFIX} Floor`}>
          <ControlsWrapper>
            <div ref={controlsRef} className="ol-controls" />
            <ExpandButton onClick={toggleExpand}>
              <Icon name="fullscreen" />
            </ExpandButton>
          </ControlsWrapper>
        </SectionHeader>
        {/* We'll wait for the parent component to mount so we can use controlsRef in Blueprint */}
        {mounted && floor.image && imageSize && (
          <Blueprint
            t={t}
            onSensorClick={toggleDialog}
            image={floor.image}
            imageSize={imageSize}
            number={floor.number}
            title={floor.title}
            featureGroupNames={featureGroupNames}
            featureGroups={[...areaFeatureGroups, [floor.sensors]]}
            loading={loading}
            getControlsRef={getControlsRef}
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
            renderTabLabel={renderTabLabel}
            content={content}
            selectedTab={selectedTab}
            isZoomable
          />
        )}
      </Section>
    </Fragment>
  );
};

export default React.memo(FloorTab);
