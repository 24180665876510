import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import cookies from 'utils/Cookies/Cookies.js';
import NaviItem from './NaviItem';
import MobileNaviItem from './MobileNaviItem';
import Svg from 'components/Svg/Svg.jsx';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonSquare from 'components/Skeletons/SkeletonSquare';

import { getPortfolioLinks } from 'utils/Data/partners';
import { setSideNavigationStatus } from 'redux/modules';

const StyledMenu = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: ${props => props.theme.zIndex('sideNavi')};
  flex-flow: column nowrap;
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.opiCard};
  transition: width ${props => props.theme.navigation.transition};
  width: ${props =>
    props.isExtended ? props.theme.navigation.sideTabletWidth : props.theme.navigation.sideSmallWidth};
  padding-top: calc(${props => props.theme.spacing.md} + ${props => props.theme.navigation.height});

  ${props => props.theme.media.landscape`
        display: flex;
    `}

  ${props => props.theme.media.desktop`
        width: ${props =>
          props.isExtended ? props.theme.navigation.sideWidth : props.theme.navigation.sideSmallWidth};
    `}
`;

const Scrollable = styled.div`
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const ToggleContainer = styled.div`
  border-top: 1px solid ${props => props.theme.colors.alabaster};
  margin-top: auto;
`;

const Toggle = styled.div`
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.md};
  display: flex;
  justify-content: ${props => (props.isExtended ? 'flex-end' : 'center')};
  align-items: center;
  cursor: pointer;
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.fontSize.xxs};
  letter-spacing: 0.3px;
  overflow: hidden;
`;

const ToggleIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  min-width: 25px;
  height: 25px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.mystic};
`;

const ToggleIcon = styled(Svg)`
  font-size: ${props => props.theme.fontSize.xxxs};
  fill: ${props => props.theme.colors.midnight};
`;

const MobileNavi = styled.div`
  height: 80px;

  ${props => props.theme.media.portrait`
        margin-bottom: ${props => props.theme.navigation.tabletSpacing};
    `}

  ${props => props.theme.media.landscape`
        display: none;
    `}
`;

const MobileNaviContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  background-color: ${props => props.theme.colors.white};
  position: ${props => props.fixed && 'fixed'};
  z-index: ${props => props.theme.zIndex('sideNavi')};
  height: 80px;
  top: 0;
  left: 0;
  right: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  ${props => props.theme.media.portrait`
        position: ${props => (props.fixedTablet ? 'fixed' : 'initial')};
        margin: ${props => !props.fixedTablet && `0 -${props.theme.navigation.tabletSpacing}`};
    `}

  ${props => props.theme.media.landscape`
        display: none;
    `}
`;

export const SideNavigation = props => {
  const {
    links,
    linksMobile,
    location,
    t,
    selected,
    match: {
      params: { partnerNumber },
    },
    features,
    toggleExtend,
    isExtended,
    scroll: { scrollTop },
    loading,
    customViews,
    loadingCustomViews,
    disabledCustomViews,
    featureTeasers,
  } = props;

  const toggle = e => {
    e.preventDefault();

    // Force resize to update floorplan/chart widths
    setTimeout(() => {
      requestAnimationFrame(() => {
        window.dispatchEvent(new Event('resize'));
      });
    }, 500);
    cookies.setItem('naviExtended', !isExtended, Infinity, '/');
    toggleExtend(!isExtended);
  };

  const renderLinks = (linksToShow, NaviElement) => {
    return linksToShow.map(link => {
      const {
        title,
        icon,
        disabled,
        count,
        loading,
        queryParams,
        to,
        smallIcon,
        contextLink,
        pathname,
        teaser,
        external,
      } = link;

      const linkTo = to || {
        pathname: pathname || (location && location.pathname),
        query: { page: undefined, year: undefined, ...queryParams },
      };

      return (
        <NaviElement
          key={pathname || title}
          title={title}
          disabled={disabled || teaser}
          icon={icon}
          count={count}
          to={linkTo}
          location={location}
          t={t}
          selected={location?.pathname === pathname}
          loading={loading}
          extended={isExtended}
          smallIcon={smallIcon}
          contextLink={contextLink}
          teaser={teaser}
          external={external}
        />
      );
    });
  };

  const getLoadingSkeleton = toggleIcon => {
    return (
      <Fragment>
        <StyledMenu isExtended={isExtended}>
          <SkeletonText large style={{ width: '90%', marginBottom: '1em', marginTop: '2em', height: '1em' }} />
          <SkeletonText large style={{ width: '90%', marginBottom: '1em', height: '1em' }} />
          <SkeletonText large style={{ width: '90%', marginBottom: '1em', height: '1em' }} />
          <SkeletonText large style={{ width: '90%', marginBottom: '1em', height: '1em' }} />
          <ToggleContainer>
            <Toggle onClick={toggle} isExtended={isExtended}>
              <ToggleIconContainer>
                <ToggleIcon name={toggleIcon} />
              </ToggleIconContainer>
            </Toggle>
          </ToggleContainer>
        </StyledMenu>
        <MobileNavi>
          <MobileNaviContent>
            <SkeletonSquare size="3em" style={{ margin: 'auto 0 auto 2em' }} />
            <SkeletonSquare size="3em" style={{ margin: 'auto 0 auto 2em' }} />
            <SkeletonSquare size="3em" style={{ margin: 'auto 0 auto 2em' }} />
            <SkeletonSquare size="3em" style={{ margin: 'auto 0 auto 2em' }} />
          </MobileNaviContent>
        </MobileNavi>
      </Fragment>
    );
  };

  const toggleIcon = isExtended ? 'ion-arrow-left' : 'ion-arrow-right';
  const linksToShow =
    links ||
    getPortfolioLinks(partnerNumber, features, location.search, customViews, disabledCustomViews, featureTeasers);
  const linksToShowInMobile = linksMobile || linksToShow;

  const scrollOffsetTablet = 64; // Navi
  const fixedTablet = scrollOffsetTablet < scrollTop;

  if (loading) {
    return getLoadingSkeleton(toggleIcon);
  }

  return (
    <Fragment>
      <StyledMenu isExtended={isExtended}>
        <Scrollable>
          {renderLinks(linksToShow, NaviItem)}
          {loadingCustomViews && partnerNumber && (
            <SkeletonText large style={{ width: '90%', marginBottom: '1em', height: '1em' }} />
          )}
        </Scrollable>
        <ToggleContainer>
          <Toggle onClick={toggle} isExtended={isExtended}>
            <ToggleIconContainer>
              <ToggleIcon name={toggleIcon} />
            </ToggleIconContainer>
          </Toggle>
        </ToggleContainer>
      </StyledMenu>
      <MobileNavi>
        <MobileNaviContent fixed={false} fixedTablet={fixedTablet}>
          {renderLinks(linksToShowInMobile, MobileNaviItem)}
          {loadingCustomViews && partnerNumber && <SkeletonSquare size="3em" style={{ margin: 'auto 0 auto 2em' }} />}
        </MobileNaviContent>
      </MobileNavi>
    </Fragment>
  );
};

SideNavigation.propTypes = {
  t: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      count: PropTypes.number,
      loading: PropTypes.bool,
      queryParams: PropTypes.object,
      to: PropTypes.object,
      smallIcon: PropTypes.bool,
      contextLink: PropTypes.bool,
      teaser: PropTypes.bool,
      external: PropTypes.bool,
    })
  ),
  linksMobile: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      count: PropTypes.number,
      loading: PropTypes.bool,
      queryParams: PropTypes.object,
      to: PropTypes.object,
      smallIcon: PropTypes.bool,
      contextLink: PropTypes.bool,
      teaser: PropTypes.bool,
      external: PropTypes.bool,
    })
  ),
  selected: PropTypes.string,
  location: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  isExtended: PropTypes.bool.isRequired,
  toggleExtend: PropTypes.func.isRequired,
  scroll: PropTypes.shape({
    scrollTop: PropTypes.number.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  disabledCustomViews: PropTypes.array.isRequired,
};

const EMPTY_ARRAY = [];

const mapStateToProps = state => ({
  features: state.profile.profile.syntheticFeatures,
  featureTeasers: state.profile.profile.featureTeasers,
  isExtended: state.navigation.sideNaviExtended,
  scroll: state.common.scroll,
  loadingCustomViews: state.customView.loading,
  disabledCustomViews: state.profile.profile.disabledCustomViews || EMPTY_ARRAY,
});

const mapDispatchToProps = dispatch => ({
  toggleExtend: open => dispatch(setSideNavigationStatus(open)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SideNavigation)
);
