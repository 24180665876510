import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import queryString from 'query-string';
import _ from 'lodash';
import Helmet from 'react-helmet';

import { loadFunctionalLocationContainer } from 'redux/modules/containers/functional-location.js';
import { loadEquipmentsModule } from 'redux/modules/containers/equipments-module.js';
import { isValidPartner, findPartnerNumberForFL } from 'utils/Data/partners';
import { getSensorAlarmsBySensorId } from 'redux/modules/iot/sensorAlarms';

import {
  getParentFunctionLocations,
  getHeroContext,
  isBusinessUnit,
  isTenant,
  isSystem,
  getBuildingImage,
} from 'utils/Data/functionalLocations.js';
import { getEnabledTabs, FEATURE_TO_TAB } from 'utils/Data/features';
import translations from 'decorators/Translations/translations';
import StandardPage from 'components/StandardPage/StandardPage.jsx';

import BusinessUnit from 'components/FunctionalLocations/BusinessUnit';
import TechnicalSystem from 'components/FunctionalLocations/TechnicalSystem';
import GenericFunctionalLocation from 'components/FunctionalLocations/GenericFunctionalLocation';
import LoadingFunctionalLocation from 'components/FunctionalLocations/LoadingFunctionalLocation';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage.jsx';
import { getBuildingSensorsFromHierarchy, getBuildingFloorsFromHierarchy } from 'utils/Data/sensorHierarchy';
import FadeTransition from 'components/FadeTransition/FadeTransition';

const startDate = moment.utc().startOf('month');
const endDate = moment.utc().endOf('month');
const emptyArray = [];

class FunctionalLocation extends Component {
  state = {
    floors: [],
    sensors: [],
    disableScrollToTop: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      match: {
        params: { functionalLocationId },
      },
      location: { search },
      functionalLocations,
    } = this.props;
    if (prevProps.match.params.functionalLocationId !== functionalLocationId) {
      this.loadData();
      return;
    }

    const oldTab = queryString.parse(prevProps.location.search).tab;
    const tab = queryString.parse(search).tab;
    const disableScrollToTop = tab === 'floors' && oldTab === 'floors';
    prevState.disableScrollToTop !== disableScrollToTop && this.setState({ disableScrollToTop });

    const oldPage = parseInt(queryString.parse(prevProps.location.search).page, 10) || 1;
    const page = parseInt(queryString.parse(search).page, 10) || 1;
    const functionalLocation = functionalLocations[functionalLocationId];
    const functionalLocationsLoaded =
      Object.keys(functionalLocations).length > 0 && Object.keys(prevProps.functionalLocations).length === 0;

    if (oldPage !== page || functionalLocationsLoaded) {
      if (tab === 'equipment') {
        this.props.loadEquipmentsModule(functionalLocation);
      }
    }
    if (prevProps.sensorHierarchy !== this.props.sensorHierarchy) {
      const hierarchy = this.props.sensorHierarchy.buildingHierarchy[functionalLocationId];
      let floors = [];
      let sensors = [];
      if (hierarchy && hierarchy.length > 0) {
        floors = getBuildingFloorsFromHierarchy(_.head(hierarchy));
        sensors = getBuildingSensorsFromHierarchy(_.head(hierarchy));
      }
      this.setState({
        floors,
        sensors,
      });
    }
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillMount() {
    this.loadData();
  }

  loadData = () => {
    const {
      match: {
        params: { partnerNumber, functionalLocationId },
      },
      location: { search },
      features,
      loadFunctionalLocationContainer,
    } = this.props;
    const realData = queryString.parse(search).realData;

    loadFunctionalLocationContainer(functionalLocationId, startDate, endDate, features, partnerNumber, realData);
  };

  render() {
    const {
      t,
      loading,
      loadingParentFL,
      loadingChildren,
      loadingEquipment,
      functionalLocations,
      permissions,
      equipments,
      subEquipments,
      features,
      match: {
        params: { partnerNumber, functionalLocationId },
      },
      match,
      location: { search },
      functionalLocationDocuments,
      sensorCategories,
      sensorHierarchy,
      functionalLocationImages,
      notice,
      alarm,
      serviceOrders,
      meta,
      slaByFL,
      customViewsByFl,
      disabledCustomViews,
      sensorAlarmsById,
      featureTeasers,
      partnerMeta,
      profile,
    } = this.props;

    const functionalLocation = functionalLocations[functionalLocationId];

    if (!functionalLocation && !loading) {
      return <ErrorPage type="functionalLocation" />;
    }

    // Show error page if partnerNumber does not match with FL partner numbers
    if (
      functionalLocation &&
      partnerNumber !== 'all' &&
      !_.includes(functionalLocation.partnerNumberWithParents, partnerNumber)
    ) {
      return <ErrorPage type="functionalLocation" />;
    }

    const query = queryString.parse(search);
    const page = parseInt(query.page, 10) || 1;
    const scrollToTopOnMount = query.tab === 'service orders';

    const path = functionalLocation && functionalLocation.path;
    const childFunctionalLocations =
      (path &&
        Object.keys(_.get(permissions, path) || {})
          .map(child => functionalLocations[child])
          .filter(
            child => !isValidPartner(partnerNumber) || _.includes(child.partnerNumberWithParents, partnerNumber)
          )) ||
      [];

    // We MUST strictly compare to undefined, since it might still have null value after loading has completed
    // on technical system (discipline) functional location. This is probably a bug in the permissions reducer
    // and should be checked out.
    const loadingFunctionalLocations = !functionalLocation || _.get(permissions, path) === undefined;

    const totalFunctionalLocations = childFunctionalLocations.length;
    const parents = getParentFunctionLocations(functionalLocations, path, functionalLocationId).reverse();
    const parentImages = _.omitBy(
      _.map(parents, parent => getBuildingImage(functionalLocationImages, parent)),
      _.isEmpty
    );

    // Show documents loading indicator if the  functionalLocationDocuments is null.
    let loadingDocuments = false;
    let documentCount = 0;
    if (functionalLocationDocuments && functionalLocation) {
      if (_.isArray(functionalLocationDocuments[functionalLocation.functionalLocation])) {
        const files = _.flatten(
          functionalLocationDocuments[functionalLocation.functionalLocation]
            .filter(d => d.files.length > 0)
            .map(d => d.files)
        );
        documentCount = files.length;
      } else {
        loadingDocuments = true;
      }
    }

    const equipmentCount =
      (functionalLocation &&
        equipments &&
        equipments[functionalLocation.functionalLocation] &&
        equipments[functionalLocation.functionalLocation].length) ||
      0;
    const categories = functionalLocation && _.get(sensorCategories, [functionalLocation.functionalLocation]);

    const customViews = customViewsByFl[functionalLocationId];
    const enabledCustomViews = _.filter(
      customViews || [],
      customView => !_.includes(disabledCustomViews, customView.id)
    );

    const defaultTab = profile.defaultFeature
      ? profile.defaultFeature === 'technicalTab'
        ? functionalLocation?.type === 'TO'
          ? 'Systems'
          : 'Technical'
        : FEATURE_TO_TAB[profile.defaultFeature]
      : _.head(getEnabledTabs(features, functionalLocation?.type, customViews));

    const sharedProperties = {
      t,
      loading,
      features,
      functionalLocation,
      childFunctionalLocations,
      equipments,
      subEquipments,
      parents,
      match,
      loadingParent: loadingParentFL,
      loadingChildren,
      loadingEquipment,
      partnerNumber:
        partnerNumber && partnerNumber !== 'all' ? partnerNumber : findPartnerNumberForFL(functionalLocation, profile),
      getHeroContext: getHeroContext,
      totalFunctionalLocations,
      loadingFunctionalLocations,
      documentCount,
      loadingDocuments,
      equipmentCount,
      buildingImage: parentImages && parentImages[0],
      page,
      customViews: enabledCustomViews,
      featureTeasers,
      partnerMeta,
      profile,
      defaultTab,
    };

    const { sensors, floors, disableScrollToTop } = this.state;

    return (
      <FadeTransition>
        <StandardPage withTabs disableScrollToTop={disableScrollToTop} scrollToTopOnMount={scrollToTopOnMount}>
          <Helmet title={functionalLocation && functionalLocation.description} />
          {(() => {
            if (loading) {
              return <LoadingFunctionalLocation {...sharedProperties} />;
            } else if (isSystem(functionalLocation)) {
              return <TechnicalSystem {...sharedProperties} />;
            } else if (isBusinessUnit(functionalLocation) || isTenant(functionalLocation)) {
              const flAnnouncements =
                this.props.announcements.byFL && this.props.announcements.byFL[functionalLocationId]
                  ? this.props.announcements.byFL[functionalLocationId]
                  : emptyArray;
              return (
                <BusinessUnit
                  {...sharedProperties}
                  floors={floors}
                  buildingSensors={sensors}
                  buildingHierarchy={sensorHierarchy.buildingHierarchy[functionalLocationId] || emptyArray}
                  sensorValues={this.props.sensorValues}
                  valuesBySensorId={this.props.valuesBySensorId}
                  latestValuesBySensorId={this.props.latestValuesBySensorId}
                  cleaningValues={this.props.cleaningValues}
                  buildingConditions={this.props.buildingConditions[functionalLocationId]}
                  observations={
                    notice.byFL && notice.byFL[functionalLocationId]
                      ? notice.byFL[functionalLocationId].observations
                      : emptyArray
                  }
                  inspections={
                    notice.byFL && notice.byFL[functionalLocationId]
                      ? notice.byFL[functionalLocationId].inspections
                      : emptyArray
                  }
                  alarms={
                    alarm.byFL && alarm.byFL[functionalLocationId] ? alarm.byFL[functionalLocationId] : emptyArray
                  }
                  serviceOrders={
                    serviceOrders.byFL && serviceOrders.byFL[functionalLocationId]
                      ? serviceOrders.byFL[functionalLocationId]
                      : emptyArray
                  }
                  buildingImage={getBuildingImage(functionalLocationImages, functionalLocation)}
                  functionalLocationImages={functionalLocationImages}
                  announcements={flAnnouncements}
                  sensorHierarchy={sensorHierarchy}
                  meta={meta[functionalLocation.functionalLocation]}
                  sla={slaByFL[functionalLocation.functionalLocation]}
                  sensorAlarmsById={sensorAlarmsById}
                />
              );
            }

            return (
              <GenericFunctionalLocation {...sharedProperties} noIoTData={!categories || categories.length === 0} />
            );
          })()}
        </StandardPage>
      </FadeTransition>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.functionalLocationContainer.loading,
  loadingParentFL: state.functionalLocationContainer.loadingParents,
  loadingChildren: state.functionalLocationContainer.loadingChildren,
  loadingEquipment: state.functionalLocationContainer.loadingEquipment || state.equipmentsModule.loadingMoreData,
  features: state.profile.profile.syntheticFeatures,
  featureTeasers: state.profile.profile.featureTeasers,
  permissions: state.profile.permissionTree,
  functionalLocations: state.functionalLocations.functionalLocations,
  equipments: state.equipments.equipments,
  subEquipments: state.equipments.subEquipments,
  sensorHierarchy: state.sensorHierarchy,
  sensorValues: state.values.sensors.sensorValues,
  valuesBySensorId: state.values.sensors.valuesBySensorId,
  latestValuesBySensorId: state.values.sensors.latestValuesBySensorId,
  cleaningValues: state.values.cleaning.cleaningValues,
  buildingConditions: state.values.conditions.conditions,
  notice: state.notice,
  alarm: state.alarm,
  serviceOrders: state.serviceOrders,
  functionalLocationDocuments: state.document.filteredFunctionalLocations,
  sensorCategories: state.sensors.categories,
  profile: state.profile.profile,
  functionalLocationImages: state.partnerImage.byFL,
  announcements: state.announcement,
  meta: state.buildingMeta.meta,
  slaByFL: state.notice.slaByFL,
  customViewsByFl: state.customView.customViewsByFl,
  disabledCustomViews: state.profile.profile.disabledCustomViews || emptyArray,
  sensorAlarmsById: getSensorAlarmsBySensorId(state),
  partnerMeta: state.partnerMeta.meta,
});

const mapDispatchToProps = {
  loadFunctionalLocationContainer,
  loadEquipmentsModule,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(translations(withRouter(FunctionalLocation)));
