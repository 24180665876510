import React from 'react';
import styled from 'styled-components';
import underscored from 'underscore.string/underscored';
import { forEach, some } from 'lodash';
import PropTypes from 'prop-types';

import DateColumn from 'components/Calendar/ColumnTypes/DateColumn';
import StatusTextColumn from 'components/Calendar/ColumnTypes/StatusTextColumn';
import LocationColumn from 'components/Calendar/ColumnTypes/LocationColumn';
import LinkColumn from 'components/Calendar/ColumnTypes/LinkColumn';
import EventsRow from 'components/BuildingEvents/EventsRow/EventsRow';
import EventsCell from 'components/BuildingEvents/EventsRow/EventsCell';
import PriorityColumn from 'components/Calendar/ColumnTypes/PriorityColumn';
import ResponseColumn from 'components/Calendar/ColumnTypes/ResponseColumn';
import SimpleColumn from 'components/Calendar/ColumnTypes/SimpleColumn';
import { orderStatusLabels } from 'containers/Application/ServiceCalendar/utils';

const StyledServiceOrderRow = styled(EventsRow)`
  position: relative;
  transform: scale(1);
`;

const ClickableEventsCell = styled(EventsCell)`
  cursor: pointer;
  position: relative;
`;

const ServiceOrderRow = ({ rowData, t, onClick, columns }) => {
  const showLinkColumn = some(columns, col => col.field === 'discipline' || col.field === 'location');

  return (
    <StyledServiceOrderRow noAlternatingRows data-test-id="ServiceOrderRow">
      {(() => {
        const cells = [];
        forEach(columns, column => {
          switch (column.field) {
            case 'status':
              cells.push(
                <ClickableEventsCell hideOnMobile onClick={() => onClick(rowData)} key="statusCell">
                  <StatusTextColumn
                    showStatusColor
                    t={t}
                    data={{ status: underscored(rowData.status), humanReadableStatus: rowData.status }}
                  />
                </ClickableEventsCell>
              );
              break;
            case 'title':
              cells.push(
                <ClickableEventsCell onClick={() => onClick(rowData)} key="titleCell">
                  <StatusTextColumn
                    t={t}
                    data={{
                      text: rowData.title,
                      status: underscored(rowData.status),
                      humanReadableStatus: rowData.status,
                    }}
                    showStatusColor={window.innerWidth < 900 || !some(columns, { field: 'status' })}
                  />
                </ClickableEventsCell>
              );
              break;
            case 'reactionTime':
              cells.push(
                <EventsCell hideOnMobile key="reactionTimeCell">
                  <ResponseColumn
                    t={t}
                    data={{
                      response: rowData.reactionTime,
                      overSLA: rowData.meta.overSLA.reactionTime,
                    }}
                    nilValue=" "
                  />
                </EventsCell>
              );
              break;
            case 'downtime':
              cells.push(
                <EventsCell hideOnMobile key="downtimeCell">
                  <ResponseColumn
                    t={t}
                    data={{ response: rowData.downtime, overSLA: rowData.meta.overSLA.downtime }}
                    nilValue=" "
                  />
                </EventsCell>
              );
              break;
            case 'leadTime':
              cells.push(
                <EventsCell hideOnMobile key="leadTimeCell">
                  <ResponseColumn
                    t={t}
                    data={{ response: rowData.leadTime, overSLA: rowData.meta.overSLA.leadTime }}
                    nilValue=" "
                  />
                </EventsCell>
              );
              break;
            case 'priority':
              cells.push(
                <EventsCell hideOnMobile key="priotityCell">
                  <PriorityColumn data={{ priority: rowData.priority }} />
                </EventsCell>
              );
              break;
            case 'createdDate':
              cells.push(
                <EventsCell key="createdDateCell">
                  <DateColumn data={{ date: rowData.createdDate }} />
                </EventsCell>
              );
              break;
            case 'modifiedDate':
              cells.push(
                <EventsCell hideOnMobile key="modifiedDateCell">
                  <DateColumn data={{ date: rowData.modifiedDate }} />
                </EventsCell>
              );
              break;
            case 'plannedDate':
              cells.push(
                <EventsCell hideOnMobile key="plannedDateCell">
                  <DateColumn data={{ date: rowData.plannedDate }} isPlannedDate />
                </EventsCell>
              );
              break;
            case 'location':
              cells.push(
                <EventsCell hideOnMobile key="locationCell">
                  <LocationColumn data={rowData} t={t} loading={rowData.meta.loadingFL} />
                </EventsCell>
              );
              break;
            case 'discipline':
              cells.push(
                <EventsCell hideOnMobile key="disciplineCell">
                  <LocationColumn data={rowData} t={t} loading={rowData.meta.loadingFL} isDisciplineColumn />
                </EventsCell>
              );
              break;
            case 'link':
              showLinkColumn &&
                cells.push(
                  <EventsCell hideOnMobile noPadding key="linkCell">
                    <LinkColumn data={rowData} />
                  </EventsCell>
                );
              break;
            case 'type':
              cells.push(
                <EventsCell hideOnMobile key="typeCell">
                  <SimpleColumn
                    data={t(orderStatusLabels[rowData.maintenanceActivityType] || rowData.maintenanceActivityType)}
                  />
                </EventsCell>
              );
              break;
            default:
              break;
          }
        });
        return cells;
      })()}
    </StyledServiceOrderRow>
  );
};

ServiceOrderRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
};

export default React.memo(ServiceOrderRow);
