import { useLocation, useHistory } from 'react-router-dom';

import queryString from 'query-string';

const useQuery = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const history = useHistory();

  return {
    query,
    setQuery: (newQuery, options = { replace: false }) => {
      let newSearch = {};
      if (!options.replace) {
        newSearch = { ...query };
      }
      newSearch = { ...newSearch, ...newQuery };
      const newSearchString = queryString.stringify({ ...newSearch });
      history.push({
        search: newSearchString,
      });
    },
  };
};

export default useQuery;
