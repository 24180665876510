import capitalize from 'lodash/capitalize';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import round from 'lodash/round';
import startsWith from 'lodash/startsWith';

const recyclableSensorTypes = [
  'waste/biowaste',
  'waste/cardboard',
  'waste/magazine_paper',
  'waste/glass',
  'waste/metal',
  'waste/heavy_metal_batteries',
  'waste/electronics',
  'waste/office_paper',
];
const recoverableSensorTypes = [
  ...recyclableSensorTypes,
  'waste/burned_mixed_waste',
  'waste/mixed_waste',
  'waste/energy_waste',
];
const carbonFootprintSensorTypes = ['waste/burned_mixed_waste', 'waste/energy_waste'];

const getSensorIds = (wasteSensors, sensorTypes) =>
  (wasteSensors ?? [])
    .filter(wasteSensor => includes(sensorTypes, wasteSensor.sensorType.name))
    .map(wasteSensor => String(wasteSensor.id));

/**
 * Calculates total tonnes from given IoT data for all the given sensor ids.
 * @param  {Object[]} ioTData - fetched IoT data
 * @param  {number[]} sensorIds - sensor ids
 */
const calculateTotalTonnes = (ioTData, sensorIds) =>
  (ioTData ?? [])
    .filter(ioTEntry => includes(sensorIds, ioTEntry.sensorId))
    .map(ioTEntry => ioTEntry.value)
    .reduce((prev, curr) => prev + curr, 0);

export const getWasteSensors = (buildingHierarchy, functionalLocationId) => {
  const allSensors = buildingHierarchy[functionalLocationId]?.[0]?.sensors;
  return (allSensors ?? []).filter(sensor => sensor.sensorType && startsWith(sensor.sensorType.name, 'waste/'));
};

/**
 * Calculates recycling and recovery rates, and carbon footprint from given IoT data by the given sensors.
 * @param  {Object[]} ioTData - fetched IoT data
 * @param  {Object[]} wasteSensors - waste type sensors
 */
export const calculateRecyclingOPIValues = (ioTData, wasteSensors) => {
  // recycling
  const recyclableSensorIds = getSensorIds(wasteSensors, recyclableSensorTypes);
  const recyclingTotalTonnes = calculateTotalTonnes(ioTData, recyclableSensorIds);
  // recovery
  const recoverableSensorIds = getSensorIds(wasteSensors, recoverableSensorTypes);
  const recoveryTotalTonnes = calculateTotalTonnes(ioTData, recoverableSensorIds);
  // carbon footprint
  const carbonFootprintSensorIds = getSensorIds(wasteSensors, carbonFootprintSensorTypes);
  const carbonFootprint = calculateTotalTonnes(ioTData, carbonFootprintSensorIds) * 0.41;
  // total
  const totalTonnes = ioTData.map(entry => entry.value).reduce((prev, curr) => prev + curr, 0);
  return {
    recyclingRate: round(totalTonnes ? (recyclingTotalTonnes / totalTonnes) * 100 : 0, 0),
    recoveryRate: round(totalTonnes ? (recoveryTotalTonnes / totalTonnes) * 100 : 0, 0),
    carbonFootprint,
  };
};

/**
 * Calculates a total sum of IoT values for the given sensor type.
 * @param  {string} sensorType - sensor type to calculate the sum for
 * @param  {Object[]} ioTData - fetched IoT data
 * @param  {Object[]} wasteSensors - waste type sensors
 */
const calculateTotalValueForSensorType = (sensorType, ioTData, wasteSensors) => {
  const sensorIdsByType = wasteSensors
    .filter(sensor => sensor.sensorType?.name === sensorType)
    .map(sensor => String(sensor.id));
  return ioTData
    .filter(ioTEntry => includes(sensorIdsByType, ioTEntry.sensorId))
    .map(ioTEntry => ioTEntry.value)
    .reduce((prev, curr) => prev + curr, 0);
};

const resolveColorName = sensorType => {
  if (includes(recyclableSensorTypes, sensorType)) {
    return 'recyclableWaste';
  }
  return 'nonRecyclableWaste';
};

/**
 * Calculates a total sum per waste type from the given IoT data.
 * @param  {Object[]} ioTData - fetched IoT data
 * @param  {Object[]} wasteSensors - waste type sensors to calculate the data for
 */
export const calculateRecyclingBreakdownPerWasteSerie = (ioTData, wasteSensors) => {
  if (isEmpty(ioTData)) {
    return [];
  }
  const rawSerie = wasteSensors.map(sensor => {
    const sensorType = sensor.sensorType?.name;
    const wasteType = capitalize(sensorType?.replace('waste/', '').replace(/_/g, ' '));
    return {
      wasteType,
      colorName: resolveColorName(sensorType),
      totalValue: calculateTotalValueForSensorType(sensorType, ioTData, wasteSensors),
      unit: sensor?.sensorType?.unit,
    };
  });
  return orderBy(rawSerie, 'wasteType', 'asc');
};
