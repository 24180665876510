import React, { Component, Fragment } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import last from 'lodash/last';
import clone from 'lodash/clone';

import MaintenanceModule from 'containers/Application/Modules/MaintenanceModule/MaintenanceModule.jsx';
import FunctionalLocationsModule from 'components/Modules/FunctionalLocationsModule/FunctionalLocationsModule.jsx';
import EquipmentModule from 'containers/Application/Modules/EquipmentModule/EquipmentModule.jsx';
import ExternalDocumentModule from 'containers/Application/Modules/ExternalDocumentModule/ExternalDocumentModule.jsx';
import Hero from 'components/Hero/Hero.jsx';
import ServiceRequest from 'containers/Application/ServiceRequest/ServiceRequest';
import Header from 'containers/Application/Header/Header.jsx';
import TabContent from 'components/TabContent/TabContent';

import {
  disciplines,
  addServiceOrderLink,
  addTechnicalLink,
  addEquipmentLink,
  addExternalDocumentsLink,
  addNewServiceRequestLink,
} from 'utils/Data/functionalLocations';
import { isServiceOrdersEnabled, isTechnicalTabEnabled, isEquipmentEnabled } from 'utils/Data/profileData';

class TechnicalSystem extends Component {
  render() {
    const {
      t,
      loadingParent,
      loadingEquipment,
      features,
      featureTeasers,
      functionalLocation,
      equipments,
      subEquipments,
      partnerNumber,
      parents,
      documentCount,
      loadingDocuments,
      equipmentCount,
      totalFunctionalLocations,
      loadingFunctionalLocations,
      buildingImage,
      loadingChildren,
      childFunctionalLocations,
      page,
      match: { url },
      match,
      profile,
      defaultTab,
    } = this.props;
    const discipline = last(functionalLocation.functionalLocation.split('-'));
    const type = disciplines.indexOf(discipline) !== -1 ? discipline : 'EXT';

    let links = [];
    links = addServiceOrderLink(links, url, features);
    links = addTechnicalLink(
      links,
      url,
      features,
      functionalLocation,
      totalFunctionalLocations,
      loadingFunctionalLocations,
      featureTeasers
    );
    links = addEquipmentLink(links, url, features, equipmentCount, loadingEquipment, featureTeasers);
    links = addExternalDocumentsLink(links, url, features, documentCount, loadingDocuments, featureTeasers);

    let linksMobile = clone(links);
    linksMobile = addNewServiceRequestLink(linksMobile, url, features);

    const heroContext = this.props.getHeroContext(parents, partnerNumber, features, profile.customViews);
    const context = Object.assign({}, heroContext, { type, loadingContext: loadingParent, functionalLocation });

    return (
      <Fragment>
        <Header context={context} t={t} links={links} linksMobile={linksMobile} />
        <Hero
          title={functionalLocation.description}
          functionalLocation={functionalLocation}
          type={type}
          loadingContext={loadingParent}
          t={t}
          showAddress
          partnerNumber={partnerNumber}
          heroImage={buildingImage}
        />
        <TabContent>
          <Switch>
            {features?.documents && (
              <Route path={`${match?.path}/ExternalDocuments`}>
                <ExternalDocumentModule functionalLocation={functionalLocation} />
              </Route>
            )}
            {isEquipmentEnabled(features) && equipments && (
              <Route path={`${match?.path}/Equipment`}>
                <EquipmentModule
                  loadingEquipment={loadingEquipment}
                  equipments={equipments[functionalLocation.functionalLocation]}
                  subEquipments={subEquipments}
                />
              </Route>
            )}
            {features?.sr && (
              <Route path={`${match?.path}/ServiceRequest`}>
                <ServiceRequest />
              </Route>
            )}
            {isServiceOrdersEnabled(features) && (
              <Route path={`${match?.path}/ServiceCalendar`}>
                <MaintenanceModule functionalLocation={functionalLocation} partnerNumber={partnerNumber} />
              </Route>
            )}
            {isTechnicalTabEnabled(features) && (
              <Route path={`${match?.path}/Technical`}>
                <FunctionalLocationsModule
                  t={t}
                  loadingChildren={loadingChildren}
                  childFunctionalLocations={childFunctionalLocations}
                  partnerNumber={partnerNumber}
                  page={page}
                />
              </Route>
            )}
            <Redirect exact from={`${match?.path}/`} to={`${match?.path}/${defaultTab}`} />
          </Switch>
        </TabContent>
      </Fragment>
    );
  }
}

export default withRouter(TechnicalSystem);
