import React, { useState, useEffect } from 'react';
import translations from 'decorators/Translations/translations';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as JsSearch from 'js-search';
import styled from 'styled-components';
import { loadPartnerOverview } from 'redux/modules/containers/partner-overview.js';
import { getFileCounts } from 'redux/modules/document/file.js';
import SectionHeader from 'components/Section/SectionHeader';
import SortableFLTable from 'components/SortableFunctionalLocationTable/SortableFLTable';
import Section from 'components/Section/Section';
import { InputSearch } from 'components/index';
import queryString from 'query-string';

const InputSearchContainer = styled.div`
  width: 100%;
  ${props => props.theme.media.landscape`
        width: auto;
        min-width: 18rem;
    `}

  & > div {
    width: 100%;
  }
`;

const getFilteredFunctionalLocations = (functionalLocations, topLevelPermissions, profile, partnerNumber) => {
  const partnerPermissions = profile.partnerPermissions || [];
  const topLevelFunctionalLocations = _.values(functionalLocations).filter(functionalLocation => {
    // If user has partner permissions, show building level FLs and tenants with direct partner.
    if (functionalLocation.partnerNumberWithParents.some(partner => _.includes(partnerPermissions, partner))) {
      return (
        functionalLocation.type === 'BU' ||
        (_.includes(functionalLocation.partnerNumber, partnerNumber) && functionalLocation.type === 'UN')
      );
    }

    // Otherwise show UNs and BUs the user has direct permissions to
    return (
      _.includes(topLevelPermissions, functionalLocation.functionalLocation) &&
      (functionalLocation.type === 'UN' || functionalLocation.type === 'BU')
    );
  });

  return (
    topLevelFunctionalLocations
      // Filter out functional locations that are not related to selected partner
      .filter(
        functionalLocation =>
          partnerNumber === 'all' || _.includes(functionalLocation.partnerNumberWithParents, partnerNumber)
      )
  );
};

const initSearch = () => {
  const flSearch = new JsSearch.Search('functionalLocation');
  flSearch.addIndex('functionalLocation');
  flSearch.addIndex('name');
  flSearch.addIndex('description');
  flSearch.addIndex('addressInfo');
  return flSearch;
};

const BuildingTable = props => {
  const {
    profile: { topLevelPermissions, profile },
    match: {
      params: { partnerNumber },
    },
    location,
    history,
    t,
    loadPartnerOverview,
    getFileCounts,
    loading,
    functionalLocations,
    fileCountsByFL,
  } = props;

  const [searchResults, setSearchResults] = useState(null);
  const [filteredFunctionalLocations, setFilteredFunctionalLocations] = useState([]);
  const flSearch = initSearch();

  useEffect(
    () => {
      loadPartnerOverview(partnerNumber);
    },
    [partnerNumber]
  );

  useEffect(
    () => {
      const flList = getFilteredFunctionalLocations(functionalLocations, topLevelPermissions, profile, partnerNumber);
      if (flList.length > 0) {
        getFileCounts(flList.map(fl => fl.functionalLocation));
        const mappedFLs = flList.map(functionalLocation => ({
          functionalLocation: functionalLocation.functionalLocation,
          description: functionalLocation.description,
          name: functionalLocation.name,
          key: functionalLocation.key,
          type: functionalLocation.type,
          addressInfo: `${functionalLocation.address || ''} ${functionalLocation.city || ''}`,
          tab: 'Documents',
        }));
        setFilteredFunctionalLocations(mappedFLs);
      }
    },
    [functionalLocations]
  );

  useEffect(
    () => {
      const mappedFLs = filteredFunctionalLocations.map(functionalLocation => ({
        ...functionalLocation,
        count: fileCountsByFL[functionalLocation.functionalLocation] || 0,
      }));
      setFilteredFunctionalLocations(mappedFLs);
    },
    [fileCountsByFL]
  );

  const page = parseInt(queryString.parse(location.search).page, 10) || 1;
  flSearch.addDocuments(filteredFunctionalLocations);

  return (
    <Section>
      <SectionHeader noBorder>
        <InputSearchContainer>
          <InputSearch
            id="fl-search-input"
            onChange={value => {
              const result = flSearch.search(value);
              setSearchResults(result);

              // Reset possible paging after search
              history.push(`/${partnerNumber}/Documents/Buildings`);
            }}
            placeholder={t('Filter by Name or Address')}
            onClear={() => setSearchResults(null)}
          />
        </InputSearchContainer>
      </SectionHeader>
      <SortableFLTable
        loading={loading}
        t={t}
        sortedFunctionalLocations={searchResults || filteredFunctionalLocations}
        page={page}
        orderBy="count"
        order="desc"
        hideAddress
        removeHead
      />
    </Section>
  );
};

const mapStateToProps = state => ({
  profile: state.profile,
  loading: state.partnerOverview.loading || state.file.loadingCounts,
  functionalLocations: state.functionalLocations.functionalLocations,
  fileCountsByFL: state.file.fileCountsByFL,
});

const mapDispatchToProps = dispatch => ({
  loadPartnerOverview: partnerNumber => dispatch(loadPartnerOverview(partnerNumber, true)),
  getFileCounts: fls => dispatch(getFileCounts(fls)),
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);
export default connector(translations(BuildingTable));
