import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import translations from 'decorators/Translations/translations';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { loadWasteAmountsData } from 'redux/modules/iot/values/recycling';

const StyledWasteAmountsDateRangeSelector = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xxs};
  padding: 0.5em;

  .link {
    font-size: ${props => props.theme.font.size.xxxs};
    font-weight: ${props => props.theme.font.weight.bold};
    cursor: pointer;
  }
`;

export const WasteAmountsDateRangeSelector = ({
  t,
  theme,
  functionalLocationId,
  wasteSensors,
  loadWasteAmountsData,
  wasteAmountsData,
  meta,
}) => {
  const [selectedRange, setSelectedRange] = useState({
    startTime: moment()
      .utc()
      .startOf('month'),
    endTime: moment()
      .utc()
      .endOf('month'),
  });
  const loadData = range => {
    loadWasteAmountsData({
      functionalLocationId,
      sensorIds: wasteSensors.map(wasteSensor => wasteSensor.id),
      aggregation: 'monthlySum',
      startTime: range.startTime.toISOString(),
      endTime: range.endTime.toISOString(),
    });
  };

  // effect to fetch the data once
  useEffect(() => {
    if (wasteAmountsData === null || meta.functionalLocationId !== functionalLocationId) {
      loadData(selectedRange);
    }
  }, []);

  const onPreviousClick = () => {
    const newRange = {
      startTime: selectedRange.startTime.subtract(1, 'month'),
      endTime: selectedRange.endTime.subtract(1, 'month'),
    };
    setSelectedRange(newRange);
    loadData(newRange);
  };

  const onNextClick = () => {
    const newRange = {
      startTime: selectedRange.startTime.add(1, 'month'),
      endTime: selectedRange.endTime.add(1, 'month'),
    };
    setSelectedRange(newRange);
    loadData(newRange);
  };

  return (
    <StyledWasteAmountsDateRangeSelector theme={theme}>
      <div className="link" onClick={onPreviousClick}>
        &larr; {t('Previous month').toUpperCase()}
      </div>
      <div>{t(capitalize(selectedRange.startTime.local().format('MMMM YYYY')))}</div>
      <div className="link" onClick={onNextClick}>
        {t('Next month').toUpperCase()} &rarr;
      </div>
    </StyledWasteAmountsDateRangeSelector>
  );
};

WasteAmountsDateRangeSelector.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  functionalLocationId: PropTypes.string.isRequired,
  wasteSensors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  loadWasteAmountsData: PropTypes.func.isRequired,
  wasteAmountsData: PropTypes.array,
  meta: PropTypes.shape({
    functionalLocationId: PropTypes.string.isRequired,
    sensorIds: PropTypes.arrayOf(PropTypes.number.isRequired),
  }),
};

const mapStateToProps = state => ({
  wasteAmountsData: state.values.recyclingWasteAmounts.data,
  meta: state.values.recyclingWasteAmounts.meta,
});

const mapDispatchToProps = {
  loadWasteAmountsData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(translations(WasteAmountsDateRangeSelector)));
