import React from 'react';
import styled, { css } from 'styled-components';
import { stripUnit } from 'polished';

const StyledColumns = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => props.center && 'center'};
  margin-left: -${props => stripUnit(props.theme.grid.gutter) / 2 + 'rem'};
  margin-right: -${props => stripUnit(props.theme.grid.gutter) / 2 + 'rem'};

  ${props =>
    props.scrollMobile &&
    css`
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }

      ${props => props.theme.media.portrait`
            flex-wrap: wrap;
            overflow-x: initial;
        `}
    `}
`;
StyledColumns.displayName = 'StyledColumns';

const Columns = props => <StyledColumns {...props} />;

export default Columns;
