import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import memoizeOne from 'memoize-one';
import queryString from 'query-string';
import SkeletonText from 'components/Skeletons/SkeletonText';
import { OrderType } from 'constants/serviceCalendar';

const getQuery = memoizeOne(search => queryString.parse(search));

const StyledLocationColumn = styled.div`
  font-size: ${props => props.theme.fontSize.xxs};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

const LocationColumn = props => {
  const { t, data, loading, theme, isDisciplineColumn } = props;
  const {
    location,
    meta: {
      noPermissions,
      authorizedPartnerNumber,
      filterable: { discipline },
    },
    functionalLocation,
    orderType,
  } = data;
  const { search } = window.location;

  if (loading) {
    return <SkeletonText style={{ height: theme.spacing.sm }} />;
  }

  const linkText = isDisciplineColumn ? (discipline ? t(discipline) : '-') : location;

  const query = getQuery(search);
  const section = orderType === OrderType.PLANNED ? 'PlannedMaintenance' : 'ServiceOrders';
  const locationLink = noPermissions ? (
    <div>
      {linkText} ({t('Missing permissions')})
    </div>
  ) : (
    <Link
      to={`/${authorizedPartnerNumber}/FunctionalLocation/${functionalLocation}/ServiceCalendar/${section}?year=${
        query.year
      }`}
    >
      {linkText}
    </Link>
  );

  return <StyledLocationColumn>{locationLink}</StyledLocationColumn>;
};

LocationColumn.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  theme: PropTypes.object,
  isDisciplineColumn: PropTypes.bool,
};

export default withTheme(LocationColumn);
