import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { map, orderBy, last, isEmpty, entries, sortBy, flatMap, omit } from 'lodash';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import ConditionModal from 'components/Conditions/ConditionModal/ConditionModal';
import ConditionTable from 'components/Conditions/ConditionTable/ConditionTable';
import ExportSensorData from 'containers/Application/ExportData/ExportSensorData';
import SensorAlarm from 'containers/Application/SensorAlarm/SensorAlarm';
import IconButton from 'components/Button/IconButton';
import SnackBar from 'components/SnackBar/SnackBar';
import Button from 'components/Button/Button';
import { disciplines } from 'utils/Data/functionalLocations.js';
import { isEquipmentEnabled } from 'utils/Data/profileData';
import { conditionData } from 'components/Conditions/ConditionUtils';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { isValidPartner } from 'utils/Data/partners';
import SkeletonText from 'components/Skeletons/SkeletonText';
import { MODALTYPE } from 'components/Modal/ModalTypes';
import memoizeOne from 'memoize-one';
import { deleteSensorAlarm } from 'redux/modules/iot/sensorAlarms';
import { showModal } from 'redux/modules/modal/modal';
import Checkbox from 'components/Form/Checkbox';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import { CATEGORY_SORT } from 'components/Conditions/ConditionUtils';

const Container = styled.div`
  margin: 0 auto;
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
`;
Container.displayName = 'Container';

const CategoryTitle = styled.h4`
  margin-bottom: ${props => props.theme.spacing.sm};
  color: ${props => props.theme.colors.black};
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.font.size.sm};
  padding: ${props => props.theme.spacing.sm};
  font-size: ${props => props.theme.font.size.md};
`;
CategoryTitle.displayName = 'CategoryTitle';

const StyledSection = styled(Section)`
  padding: ${props => props.theme.spacing.sm};

  ${props => props.theme.media.portrait`
        padding: ${props => props.theme.spacing.md};
    `}

  ${props => props.theme.media.landscape`
        padding: ${props.theme.grid.gutter};
    `}
`;
StyledSection.displayName = 'StyledSection';

const NoDataAvailable = styled.p`
  text-align: center;
  margin-top: ${props => props.theme.spacing.md};
`;
NoDataAvailable.displayName = 'NoDataAvailable';

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.md};
  flex-direction: column;

  ${props => props.theme.media.portrait`
        flex-direction: row;
    `}
`;
FilterContainer.displayName = 'FilterContainer';

const Filters = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 800px;
  width: 100%;

  ${props => props.theme.media.portrait`
        flex-direction: row;
    `}
`;
Filters.displayName = 'Filters';

const DropdownContainer = styled.div`
  width: 100%;
  min-width: 12em;
  padding: 0 ${props => props.theme.spacing.sm} ${props => props.theme.spacing.sm};

  ${props => props.theme.media.portrait`
        padding: 0 ${props => props.theme.spacing.sm} 0 0;
        flex: 1;
    `}
`;

const CheckboxContainer = styled.div`
  flex: 1;
`;

const TotalCount = styled.div`
  font-family: ${props => props.theme.font.family.arial};
  font-size: ${props => props.theme.font.size.xs};
  margin-top: ${props => props.theme.spacing.sm};

  ${props => props.theme.media.portrait`
        margin: 0 0 0 ${props => props.theme.spacing.sm};
    `}
`;

function mapSensorTypeName(name) {
  if (name?.startsWith('presence')) {
    return 'presence';
  }

  return name;
}

const EMPTY_ARRAY = [];

export class ConditionListModule extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    latestValuesBySensorId: PropTypes.object.isRequired,
    functionalLocation: PropTypes.object.isRequired,
    sensorCategories: PropTypes.array.isRequired,
    features: PropTypes.object.isRequired,
    loadingValues: PropTypes.bool.isRequired,
    loadingHierarchy: PropTypes.bool.isRequired,
    loadingBuilding: PropTypes.bool.isRequired,
    sensorAlarmsById: PropTypes.object,
    buildingMeta: PropTypes.arrayOf(PropTypes.object),
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    noTimeout: PropTypes.bool,
  };

  state = {
    sensor: null,
    showExportModal: false,
    showAlarmModal: false,
    showNotificationClose: true,
    title: undefined,
    firstRender: true,
  };

  timeout = null;

  componentDidMount() {
    if (!this.props.noTimeout) {
      this.timeout = setTimeout(() => this.setState({ firstRender: false }), 400);
    } else {
      this.setState({ firstRender: false });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  getSensorCategoryOptions = memoizeOne((sensorCategories, t) =>
    sortBy(
      map(sensorCategories, category => ({
        label: this.getCategoryName(category, t),
        value: category.id,
        type: category.type,
        order: category.order,
      })),
      CATEGORY_SORT
    )
  );

  getSensorTypeOptions = memoizeOne((sensorCategories, categoriesSelected, t) => {
    const types = {};
    function collectTypes(category, sensor) {
      if (sensor.sensorType) {
        const typeName = mapSensorTypeName(sensor.sensorType.name);
        if (types.hasOwnProperty(typeName)) {
          types[typeName].categoryIds.add(category.id);
        } else {
          types[mapSensorTypeName(sensor.sensorType.name)] = {
            ...sensor.sensorType,
            categoryIds: new Set([category.id]),
          };
        }
      }

      if (sensor.children) {
        sensor.children.forEach(child => collectTypes(category, child));
      }
    }

    sensorCategories.forEach(category => category.sensors.forEach(sensor => collectTypes(category, sensor)));
    const sensorTypeOptions = sortBy(
      entries(types).map(([name, type]) => {
        const translated =
          t(type.name)
            .charAt(0)
            .toUpperCase() + t(type.name).slice(1);
        let label = translated;
        if (type.name.startsWith('air_quality')) {
          label = `${t('Air Quality')} (${translated})`;
        } else if (type.name.startsWith('technical_performance/')) {
          label = `${t('Technical performance')} (${translated})`;
        }

        return {
          id: type.id,
          label,
          value: name,
          categoryIds: type.categoryIds,
        };
      }),
      this.TYPE_SORT
    );
    return !isEmpty(categoriesSelected)
      ? sensorTypeOptions.filter(t => Array.from(t.categoryIds).some(id => categoriesSelected.includes(id)))
      : sensorTypeOptions;
  });

  getFilters = () => {
    const { location } = this.props;
    const { category, type, alarm } = queryString.parse(location.search);
    return {
      category: category && parseInt(category, 10),
      type,
      alarm: alarm === 'true',
    };
  };

  handleFilterChange = (key, value) => {
    const { history, location } = this.props;
    const query = queryString.stringify({
      ...queryString.parse(location.search),
      [key]: value,
    });
    history.push(`${location.pathname}?${query}`);
  };

  handleAlarmFilterChange = event => this.handleFilterChange('alarm', event.target.checked);

  openSensorModal = sensor => {
    const { location, history } = this.props;
    if (sensor?.id) {
      const queryParamsString = queryString.stringify({
        ...queryString.parse(location.search),
        openModalSensorId: sensor.id,
      });
      history.push(`${location.pathname}?${queryParamsString}`);
    }
  };

  closeSensorModal = () => {
    const { history, location } = this.props;
    const queryParamsWithoutModal = omit(queryString.parse(location.search), ['openModalSensorId']);
    const queryParamsString = queryString.stringify(queryParamsWithoutModal);
    history.push(`${location.pathname}?${queryParamsString}`);
  };

  toggleAlarmModal = () => this.setState(oldState => ({ showAlarmModal: !oldState.showAlarmModal }));

  openAlarmModal = sensor => this.setState({ sensor, showAlarmModal: true });

  handleAlarmSuccess = message => {
    this.openNotification(message, 'success', true);
    this.toggleAlarmModal();
  };

  handleAlarmError = message => this.openNotification(message, 'error', true);

  deleteSensorAlarm = (sensorId, sensorName) => {
    const { t, deleteSensorAlarm, showModal } = this.props;

    showModal(
      MODALTYPE.CONFIRMATION_DELETE_SENSOR_ALARM,
      null,
      () =>
        deleteSensorAlarm(sensorId)
          .then(() => this.openNotification(t('Alarm removed successfully'), 'success', true))
          .catch(() => this.openNotification(t('Removing alarm failed!'), 'error', true)),
      t('Alarm for measuring point {0} will be removed', sensorName)
    );
  };

  toggleExportModal = () => this.setState(oldState => ({ showExportModal: !oldState.showExportModal }));

  handleCloseExportModal = () => {
    this.toggleExportModal();
    this.closeNotification();
  };

  handleExportSuccess = message => {
    this.openNotification(message, 'success');
    this.toggleExportModal();
  };

  handleExportError = message => {
    this.openNotification(message, 'error');
  };

  openNotification = (message, type, closeAutomatically = false) => {
    this.setState({
      notificationVisible: true,
      notificationMessage: message,
      notificationType: type,
      showNotificationClose: !closeAutomatically,
    });
    closeAutomatically && setTimeout(() => this.closeNotification(), 2000);
  };

  closeNotification = () => {
    this.setState({
      notificationVisible: false,
    });
  };

  getCategoryName = (category, t) => {
    let name = t(category.shortName || category.name || 'Unnamed');
    if (category.type === 'floor') {
      name = `${t('Floor')} ${name}`;
    }
    return name;
  };

  getDisciplineType = category => {
    if (!category.functionalLocation) {
      return undefined;
    }

    const discipline = last(category.functionalLocation.split('-'));
    return disciplines.indexOf(discipline) !== -1 ? discipline : 'EXT';
  };

  getEquipmentLink = sensor => {
    const {
      match: {
        params: { partnerNumber },
      },
      functionalLocation,
    } = this.props;

    const partnerPart = isValidPartner(partnerNumber) ? `/${partnerNumber}` : '';

    return `${partnerPart}/Equipment/${sensor.functionalLocation}/${sensor.equipmentNumber}/Conditions?from=${
      functionalLocation.functionalLocation
    }`;
  };

  getLoadingSkeleton() {
    const { t } = this.props;

    return (
      <Container>
        <SectionHeader title={t('Conditions')} t={t} ctxHelp={`${CTXHELP_PREFIX} Conditions`} />
        <StyledSection>
          <CategoryTitle>
            <SkeletonText header />
          </CategoryTitle>
        </StyledSection>
      </Container>
    );
  }

  filterSensor = (sensor, filters, alarms) => {
    return (
      sensor.sensorType &&
      (mapSensorTypeName(sensor.sensorType?.name) === filters.type || !filters.type) &&
      (alarms[sensor.id] || !filters.alarm)
    );
  };

  filterCategorySensors = (category, filters, sensorAlarmsById) => ({
    ...category,
    sensors: category.sensors
      .filter(sensor => !sensor.disabled)
      .filter(
        sensor =>
          this.filterSensor(sensor, filters, sensorAlarmsById) ||
          sensor.children.some(child => this.filterSensor(child, filters, sensorAlarmsById))
      )
      .map(sensor => ({
        ...sensor,
        children: (sensor.children || []).filter(child => {
          if (
            child.disabled ||
            (filters.type &&
              mapSensorTypeName(sensor.sensorType?.name) !== filters.type &&
              mapSensorTypeName(child.sensorType?.name) !== filters.type)
          ) {
            return false;
          }

          if (filters.alarm) {
            return sensorAlarmsById[child.id];
          }

          return true;
        }),
      })),
  });

  getCategories = memoizeOne((sensorCategories, latestValuesBySensorId, sensorAlarmsById, filters) => {
    const filtered = sensorCategories
      .filter(category => !filters.category || category.id === filters.category)
      .map(category => this.filterCategorySensors(category, filters, sensorAlarmsById))
      .filter(category => category.sensors.length);

    return this.getOrderedCategories(filtered, latestValuesBySensorId, sensorAlarmsById);
  });

  getOrderedCategories = (sensorCategories, latestValuesBySensorId, sensorAlarmsById) =>
    map(orderBy(sensorCategories, CATEGORY_SORT), category => {
      return {
        ...category,
        data: this.getCategoryData(category, latestValuesBySensorId, sensorAlarmsById),
      };
    });

  getCategoryData = (category, latestValuesBySensorId, sensorAlarmsById) => {
    const { features, theme, t, buildingMeta } = this.props;
    return map(orderBy(category.sensors, this.SENSOR_SORT), sensor => {
      const link = isEquipmentEnabled(features) && this.getEquipmentLink(sensor);
      const alarmConfig = {
        alarms: sensorAlarmsById,
        openAlarmModal: this.openAlarmModal,
        deleteSensorAlarm: this.deleteSensorAlarm,
      };

      return conditionData(
        sensor,
        sensor,
        latestValuesBySensorId,
        buildingMeta,
        theme,
        t,
        this.openSensorModal,
        this.getDisciplineType(category),
        link,
        alarmConfig,
        category
      );
    });
  };

  getTotal = memoizeOne(categories =>
    categories.reduce((total, category) => {
      return (
        total +
        category.sensors.reduce((acc, sensor) => {
          const includeTopLevelSensor =
            !sensor.children ||
            !sensor.children.length ||
            (sensor.sensorType &&
              (sensor.sensorType.name === 'air_quality' || sensor.sensorType.name === 'technical_performance'))
              ? 1
              : 0;
          return acc + includeTopLevelSensor + sensor.children.length;
        }, 0)
      );
    }, 0)
  );

  findSensorById = memoizeOne((categories, sensorId) => {
    const sensorMatchPredicate = sensor => sensor && sensor.id === sensorId;
    const sensors = flatMap(categories, 'data');
    let sensor = sensors.find(sensorMatchPredicate);
    if (!sensor) {
      sensor = flatMap(sensors, 'children').find(sensorMatchPredicate);
    }
    return sensor;
  });

  render() {
    const {
      t,
      functionalLocation,
      sensorCategories,
      loadingHierarchy,
      loadingValues,
      theme,
      loadingBuilding,
      latestValuesBySensorId,
      sensorAlarmsById,
      buildingMeta,
      location,
    } = this.props;

    const {
      notificationType,
      notificationMessage,
      notificationVisible,
      showNotificationClose,
      sensor,
      firstRender,
    } = this.state;

    const { openModalSensorId } = queryString.parse(location.search);
    const loading = loadingHierarchy || loadingBuilding;
    const loadingSensorValues = loadingValues && !openModalSensorId;

    if (firstRender || loading) {
      return this.getLoadingSkeleton();
    }

    if (isEmpty(sensorCategories)) {
      return <NoDataAvailable>{t('No data available')}</NoDataAvailable>;
    }

    const filters = this.getFilters();

    const categoryOptions = loadingSensorValues ? EMPTY_ARRAY : this.getSensorCategoryOptions(sensorCategories, t);

    const categoriesSelected = filters && filters.category ? [filters.category] : [];

    const typeOptions = loadingSensorValues
      ? EMPTY_ARRAY
      : this.getSensorTypeOptions(sensorCategories, categoriesSelected, t);

    const allTypeOptions = loadingSensorValues ? EMPTY_ARRAY : this.getSensorTypeOptions(sensorCategories, [], t);

    const selectedValidSensorType = filters?.type && typeOptions.find(option => option.value === filters.type)?.id;
    if (!selectedValidSensorType && filters?.type) {
      filters.type = undefined;
    }

    const categories = loadingSensorValues
      ? sensorCategories
      : this.getCategories(sensorCategories, latestValuesBySensorId, sensorAlarmsById, filters);

    const total = loadingSensorValues ? '...' : this.getTotal(categories);

    let modalSensorData;
    if (openModalSensorId) {
      modalSensorData = this.findSensorById(categories, parseInt(openModalSensorId, 10));
    }
    return (
      <Fragment>
        {modalSensorData && (
          <ConditionModal
            t={t}
            sensor={modalSensorData.parent}
            subsensor={modalSensorData.measuringPoint}
            onClick={this.closeSensorModal}
            functionalLocation={functionalLocation}
            category={modalSensorData.category}
            buildingMeta={buildingMeta}
            sensorAlarmsById={sensorAlarmsById}
            title={modalSensorData.title}
            openingHours={modalSensorData.openingHours}
          />
        )}
        {this.state.showExportModal && (
          <ExportSensorData
            onClose={this.handleCloseExportModal}
            onSuccess={this.handleExportSuccess}
            onError={this.handleExportError}
            categories={sensorCategories}
            sensorTypeOptions={allTypeOptions}
            categoriesSelected={categoriesSelected}
            sensorTypesSelected={selectedValidSensorType ? [selectedValidSensorType] : []}
          />
        )}
        {this.state.showAlarmModal && (
          <SensorAlarm
            t={t}
            buildingMeta={buildingMeta}
            sensor={sensor}
            onClose={this.toggleAlarmModal}
            onSuccess={this.handleAlarmSuccess}
            onError={this.handleAlarmError}
          />
        )}
        <Container>
          <SectionHeader title={t('Conditions')} t={t} ctxHelp={`${CTXHELP_PREFIX} Conditions`} noBorder>
            <IconButton onClick={this.toggleExportModal} iconName="export" text={t('Export CSV')} />
          </SectionHeader>
          <FilterContainer>
            <Filters>
              <DropdownContainer>
                <InputSelectDropdown
                  t={t}
                  options={categoryOptions}
                  onChange={this.handleFilterChange}
                  model={filters}
                  property="category"
                  disabled={loadingSensorValues}
                  placeholder={t('Show all groups & floors')}
                />
              </DropdownContainer>
              <DropdownContainer>
                <InputSelectDropdown
                  t={t}
                  options={typeOptions}
                  onChange={this.handleFilterChange}
                  model={filters}
                  property="type"
                  disabled={loadingSensorValues}
                  placeholder={t('Show all sensor types')}
                />
              </DropdownContainer>
              <CheckboxContainer>
                <Checkbox
                  id="alarms-only"
                  checked={filters.alarm}
                  onChange={this.handleAlarmFilterChange}
                  label={t('Only sensors with alarms')}
                  disabled={loadingSensorValues}
                />
              </CheckboxContainer>
            </Filters>
            <TotalCount>{`${t('Total')}: ${total}`}</TotalCount>
          </FilterContainer>
          {map(categories, category => (
            <StyledSection key={'building-category-' + category.id}>
              <CategoryTitle>{this.getCategoryName(category, t)}</CategoryTitle>
              {category.data ? (
                <ConditionTable
                  t={t}
                  loading={loadingSensorValues}
                  data={category.data}
                  onClick={this.openSensorModal}
                />
              ) : null}
            </StyledSection>
          ))}
          <SnackBar
            variant={notificationType}
            visible={notificationVisible}
            secondaryContent={
              showNotificationClose && (
                <Button
                  submit
                  onClick={this.closeNotification}
                  color={theme.button.colors.clear}
                  textColor={theme.colors.midnight}
                >
                  {t('Close')}
                </Button>
              )
            }
          >
            {notificationMessage}
          </SnackBar>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  features: state.profile.profile.syntheticFeatures,
  loadingHierarchy: state.sensorHierarchy.loading,
  loadingBuilding: state.buildingContainer.loading,
  loadingValues: state.values.sensors.loadingLatestSensorValues,
});

const mapDispatchToProps = {
  deleteSensorAlarm,
  showModal,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withRouter(connector(withTheme(ConditionListModule)));
