import React from 'react';
import PropTypes from 'prop-types';
import WasteAmountsChart from 'containers/Application/Modules/RecyclingModule/WasteAmountsBreakdown/WasteAmountsChart';
import WasteAmountsDateRangeSelector from 'containers/Application/Modules/RecyclingModule/WasteAmountsBreakdown/WasteAmountsDateRangeSelector';
import translations from 'decorators/Translations/translations';

export const RecyclingBreakdownTab = ({ t, functionalLocationId, wasteSensors }) => {
  return (
    <div>
      <WasteAmountsChart wasteSensors={wasteSensors} />
      <WasteAmountsDateRangeSelector functionalLocationId={functionalLocationId} wasteSensors={wasteSensors} />
    </div>
  );
};

RecyclingBreakdownTab.defaultProps = {
  wasteSensors: [],
};

RecyclingBreakdownTab.propTypes = {
  t: PropTypes.func.isRequired,
  functionalLocationId: PropTypes.string.isRequired,
  wasteSensors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default translations(RecyclingBreakdownTab);
