import React, { Fragment, PureComponent } from 'react';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import map from 'lodash/map';
import find from 'lodash/find';
import some from 'lodash/some';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import BuildingAutomationModule from 'components/Modules/BuildingAutomationModule/BuildingAutomationModule';
import CleaningModule from 'components/Modules/CleaningModule/CleaningModule';
import AnnouncementsModule from 'components/Modules/AnnouncementsModule/AnnouncementsModule';
import ControlRoomModule from 'components/Modules/ControlRoomModule/ControlRoomModule';
import FloorplanModule from '../Modules/FloorplanModule/FloorplanModule.jsx';
import MaintenanceModule from 'containers/Application/Modules/MaintenanceModule/MaintenanceModule.jsx';
import FunctionalLocationsModule from 'components/Modules/FunctionalLocationsModule/FunctionalLocationsModule.jsx';
import ConditionListModule from 'components/Modules/ConditionListModule/ConditionListModule.jsx';
import ExternalDocumentModule from 'containers/Application/Modules/ExternalDocumentModule/ExternalDocumentModule.jsx';
import DocumentModule from 'containers/Application/Modules/DocumentModule/DocumentModule.jsx';
import EquipmentModule from 'containers/Application/Modules/EquipmentModule/EquipmentModule.jsx';
import CustomViewModule from 'containers/Application/CustomViews/CustomViewModule';
import Hero from 'components/Hero/Hero.jsx';
import EnergyModule from 'containers/Application/Modules/EnergyModule/EnergyModule';
import RecyclingModule from 'containers/Application/Modules/RecyclingModule/RecyclingModule';
import Header from 'containers/Application/Header/Header.jsx';
import ServiceRequest from 'containers/Application/ServiceRequest/ServiceRequest';
import ContactModule from 'containers/Application/Modules/ContactModule/ContactModule';
import TabContent from 'components/TabContent/TabContent';

import {
  addServiceOrderLink,
  addConditionsLink,
  addTechnicalLink,
  addEquipmentLink,
  addDocumentsLink,
  addExternalDocumentsLink,
  addBuildingAutomationLink,
  addFloorsLink,
  addEnergyLink,
  addRecyclingLink,
  addControlRoomLink,
  addCleaningLink,
  addAnnouncementsLink,
  addCustomViewLinks,
  addNewServiceRequestLink,
  addBuildingAdminLink,
  addContactCaverionLink,
} from 'utils/Data/functionalLocations';
import {
  isServiceOrdersEnabled,
  isTechnicalTabEnabled,
  isEquipmentEnabled,
  isConditionsEnabled,
} from 'utils/Data/profileData';
import { activeAnnouncements } from 'components/Announcement/utils';
import { utilizationTypes, isEnergySensor, isRecyclingSensor } from 'utils/Data/values';
import { showContactCaverion } from 'containers/Application/Modules/ContactModule/utils';

const heroMetaKeys = ['BuildingType', 'YearOfConstruction'];

const getSensorCategories = memoizeOne(buildingHierarchy =>
  buildingHierarchy && buildingHierarchy.length ? buildingHierarchy[0].children.filter(child => !!child.sensors) : []
);

const isEnergySensorsFound = (buildingSensors, { sensorDataTypes }) => {
  const energySensorTypes = filter(sensorDataTypes, sensorType => isEnergySensor(sensorType.name));
  return !!find(buildingSensors, sensor => includes(map(energySensorTypes, 'id'), sensor.sensorTypeId));
};

const isRecyclingSensorsFound = (buildingSensors, { sensorDataTypes }) => {
  const recyclingRensorTypeIds = sensorDataTypes
    .filter(sensorType => isRecyclingSensor(sensorType.name))
    .map(sensorType => sensorType.id);
  return buildingSensors.some(buildingSensor => recyclingRensorTypeIds.includes(buildingSensor.sensorTypeId));
};

class BusinessUnit extends PureComponent {
  memoizedLinks = memoizeOne(links => links, isEqual);
  memoizedLinksMobile = memoizeOne(links => links, isEqual);
  memoizedContext = memoizeOne(
    (heroContext, loadingParent, functionalLocation) =>
      Object.assign({}, heroContext, { loadingContext: loadingParent, functionalLocation }),
    isEqual
  );

  getHeroMeta = memoizeOne((meta, t) => {
    if (meta) {
      const heroMeta = filter(meta, item => includes(heroMetaKeys, item.key)).map(item => ({
        key: t(item.key),
        value: t(item.value),
      }));
      const areaMeta = find(meta, { key: 'grossArea' });
      areaMeta && heroMeta.push({ key: t(areaMeta.key), value: `${areaMeta.value} m²` });
      return heroMeta;
    }
  });

  render() {
    const {
      t,
      loadingParent,
      loadingEquipment,
      features,
      featureTeasers,
      functionalLocation,
      equipments,
      subEquipments,
      partnerNumber,
      parents,
      floors,
      sensorValues,
      valuesBySensorId,
      latestValuesBySensorId,
      cleaningValues,
      buildingConditions,
      buildingSensors,
      buildingHierarchy,
      observations,
      inspections,
      alarms,
      documentCount,
      loadingDocuments,
      equipmentCount,
      totalFunctionalLocations,
      loadingFunctionalLocations,
      profile,
      buildingImage,
      announcements,
      sensorHierarchy,
      page,
      loadingChildren,
      childFunctionalLocations,
      meta,
      sla,
      match: { url },
      customViews,
      sensorAlarmsById,
      partnerMeta,
      match,
      defaultTab,
    } = this.props;

    const showBuildingManagement = floors && floors.length > 0;

    const sensorCategories = getSensorCategories(buildingHierarchy);
    const disableEnergyTab = !isEnergySensorsFound(buildingSensors, sensorHierarchy);

    // TODO: Enable when recycling sensor names are added
    // const disableRecyclingTab = !isRecyclingSensorsFound(buildingSensors, sensorHierarchy);
    const disableRecyclingTab = false;

    const hasCleaningSensors = some(
      buildingSensors,
      sensor => sensor.sensorType && sensor.sensorType.name === 'cleaning'
    );
    const activeAnnouncementsList = activeAnnouncements(announcements);
    const hasUtilizationSensors = some(
      buildingSensors,
      sensor => sensor.sensorType && utilizationTypes.indexOf(sensor.sensorType.name) !== -1
    );
    const hasS2Sensors = some(buildingSensors, sensor => sensor.sensorType && sensor.sensorType.name === 's2');
    const buildingAutomationEnabled = hasUtilizationSensors || hasS2Sensors;

    const hasAlarms = alarms && alarms.totals && alarms.totals.all > 0;
    const controlRoomDisabled = !observations.length && !sla && !hasAlarms && isEmpty(inspections);

    const showContactCaverionLink = showContactCaverion(
      partnerMeta[partnerNumber] && partnerMeta[partnerNumber].meta,
      profile
    );

    let links = [];
    links = addServiceOrderLink(links, url, features);
    links = addConditionsLink(links, url, features, isEmpty(sensorCategories), featureTeasers);
    links = addEnergyLink(links, url, features, disableEnergyTab, featureTeasers);
    links = addRecyclingLink(links, url, features, disableRecyclingTab);
    links = addControlRoomLink(links, url, features, controlRoomDisabled, featureTeasers);
    links = addBuildingAutomationLink(links, url, features, !buildingAutomationEnabled, featureTeasers);
    links = addFloorsLink(links, url, features, !showBuildingManagement, featureTeasers);
    links = addCleaningLink(links, url, features, !hasCleaningSensors, featureTeasers);
    links = addTechnicalLink(
      links,
      url,
      features,
      functionalLocation,
      totalFunctionalLocations,
      loadingFunctionalLocations,
      featureTeasers
    );
    links = addEquipmentLink(links, url, features, equipmentCount, loadingEquipment, featureTeasers);
    links = addDocumentsLink(links, url, features, featureTeasers);
    links = addExternalDocumentsLink(links, url, features, documentCount, loadingDocuments, featureTeasers);
    links = addAnnouncementsLink(
      links,
      url,
      features,
      (activeAnnouncementsList && activeAnnouncementsList.length) || 0,
      featureTeasers
    );
    links = addCustomViewLinks(links, url, customViews);

    let linksMobile = clone(links);
    linksMobile = addNewServiceRequestLink(linksMobile, url, features);
    linksMobile = addBuildingAdminLink(linksMobile, functionalLocation, profile);
    linksMobile = addContactCaverionLink(linksMobile, url, showContactCaverionLink);

    const heroContext = this.props.getHeroContext(parents, partnerNumber, features, profile.customViews);
    const context = Object.assign({}, heroContext, { loadingContext: loadingParent, functionalLocation });

    return (
      <Fragment>
        <Header
          t={t}
          context={this.memoizedContext(context, loadingParent, functionalLocation)}
          links={this.memoizedLinks(links)}
          linksMobile={this.memoizedLinksMobile(linksMobile)}
        />
        <Hero
          functionalLocation={functionalLocation}
          title={functionalLocation.description}
          type={functionalLocation.type}
          loadingContext={loadingParent}
          t={t}
          showAddress
          showContactCaverion={showContactCaverionLink}
          partnerNumber={partnerNumber}
          heroImage={buildingImage}
          profile={profile}
          isBuilding
          meta={this.getHeroMeta(meta, t)}
        />
        <TabContent>
          <Switch>
            {features?.files && (
              <Route path={`${match?.path}/Documents`}>
                <DocumentModule functionalLocation={functionalLocation} />
              </Route>
            )}
            {features?.documents && (
              <Route path={`${match?.path}/ExternalDocuments`}>
                <ExternalDocumentModule functionalLocation={functionalLocation} />
              </Route>
            )}
            {features?.sr && (
              <Route path={`${match?.path}/ServiceRequest`}>
                <ServiceRequest />
              </Route>
            )}
            {showContactCaverionLink && (
              <Route path={`${match?.path}/ContactCaverion`}>
                <ContactModule />
              </Route>
            )}
            {isServiceOrdersEnabled(features) && (
              <Route path={`${match?.path}/ServiceCalendar`}>
                <MaintenanceModule functionalLocation={functionalLocation} partnerNumber={partnerNumber} />
              </Route>
            )}
            {isConditionsEnabled(features) && (
              <Route path={`${match?.path}/Conditions`}>
                <ConditionListModule
                  t={t}
                  sensorCategories={sensorCategories}
                  valuesBySensorId={valuesBySensorId}
                  latestValuesBySensorId={latestValuesBySensorId}
                  functionalLocation={functionalLocation}
                  buildingMeta={meta}
                  sensorAlarmsById={sensorAlarmsById}
                />
              </Route>
            )}
            {features?.controlRoomTab && (
              <Route path={`${match?.path}/RemoteCenter`}>
                <ControlRoomModule
                  t={t}
                  observations={observations}
                  inspections={inspections}
                  alarms={alarms}
                  sla={sla}
                />
              </Route>
            )}
            {features?.buildingAutomationTab && (
              <Route path={`${match?.path}/Building`}>
                <BuildingAutomationModule
                  t={t}
                  functionalLocation={functionalLocation}
                  sensorValues={sensorValues}
                  buildingConditions={buildingConditions}
                  buildingSensors={buildingSensors}
                  loading={loadingParent}
                  floors={floors}
                  buildingMeta={meta}
                />
              </Route>
            )}
            {features?.floorsTab && showBuildingManagement && (
              <Route path={`${match?.path}/Floors`}>
                <FloorplanModule
                  t={t}
                  functionalLocation={functionalLocation}
                  floors={floors}
                  sensors={buildingSensors}
                  sensorValues={sensorValues}
                  latestValuesBySensorId={latestValuesBySensorId}
                  loading={loadingParent}
                  partnerNumber={partnerNumber}
                  buildingMeta={meta}
                  sensorAlarmsById={sensorAlarmsById}
                  features={features}
                />
              </Route>
            )}
            {features?.cleaningTab && (
              <Route path={`${match?.path}/Cleaning`}>
                <CleaningModule t={t} cleaningValues={cleaningValues} floors={floors} />
              </Route>
            )}
            {features?.energyTab && (
              <Route path={`${match?.path}/Energy`}>
                <EnergyModule
                  functionalLocation={functionalLocation}
                  buildingSensors={buildingSensors}
                  buildingConditions={buildingConditions}
                  sensorTypes={sensorHierarchy && sensorHierarchy.sensorDataTypes}
                />
              </Route>
            )}
            {features?.recycling && (
              <Route path={`${match?.path}/Recycling`}>
                <RecyclingModule />
              </Route>
            )}
            {isTechnicalTabEnabled(features) && (
              <Route path={`${match?.path}/Technical`}>
                <FunctionalLocationsModule
                  t={t}
                  loadingChildren={loadingChildren}
                  childFunctionalLocations={childFunctionalLocations}
                  partnerNumber={partnerNumber}
                  page={page}
                />
              </Route>
            )}
            {equipments && isEquipmentEnabled(features) && (
              <Route path={`${match?.path}/Equipment`}>
                <EquipmentModule
                  equipments={equipments[functionalLocation.functionalLocation]}
                  subEquipments={subEquipments}
                  loadingEquipment={loadingEquipment}
                />
              </Route>
            )}
            {features?.announcementsTab && (
              <Route path={`${match?.path}/News`}>
                <AnnouncementsModule
                  t={t}
                  announcements={activeAnnouncementsList}
                  profile={profile}
                  functionalLocationId={functionalLocation.functionalLocation}
                />
              </Route>
            )}
            {customViews?.map(customView => (
              <Route path={`${match?.path}/${customView.id}`} key={customView.id}>
                <CustomViewModule customView={customView} />
              </Route>
            ))}
            <Redirect exact from={`${match?.path}/`} to={`${match?.path}/${defaultTab}`} />
          </Switch>
        </TabContent>
      </Fragment>
    );
  }
}

export default withRouter(BusinessUnit);
