import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import ControlledPager from './ControlledPager';

class Pager extends Component {
  static propTypes = {
    limit: PropTypes.number.isRequired,
    totalResults: PropTypes.number.isRequired,
    center: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    onChange: PropTypes.func,
  };

  handlePageChange = page => {
    const {
      onChange,
      location: { search },
    } = this.props;
    onChange && onChange(page);
    const params = queryString.stringify(Object.assign({}, queryString.parse(search), { page }));
    this.props.history.push(`${this.props.location.pathname}?${params}`);
  };

  render() {
    const {
      limit,
      totalResults,
      center,
      location: { search },
    } = this.props;
    const currentPage = parseInt(queryString.parse(search).page, 10) || 1;
    return (
      <ControlledPager
        page={currentPage}
        limit={limit}
        totalResults={totalResults}
        center={center}
        onChange={this.handlePageChange}
      />
    );
  }
}

export default withRouter(Pager);
