import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';
import Helmet from 'react-helmet';
import translations from 'decorators/Translations/translations';
import { connect } from 'react-redux';
import includes from 'lodash/includes';
import find from 'lodash/find';
import map from 'lodash/map';
import styled from 'styled-components';

import Hero from 'components/Hero/Hero';
import StandardPage from 'components/StandardPage/StandardPage';
import Header from 'containers/Application/Header/Header';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import { getPartnerNumbers } from 'utils/profile';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import Alarms from './Alarms';
import Observations from './Observations';
import Inspections from './Inspections';

const Container = styled.div`
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
  width: 100%;
  margin: 0 auto;
`;

const RemoteCenter = props => {
  const {
    customers,
    match: {
      params: { partnerNumber },
      path,
      url,
    },
    location,
    history,
    profile,
    t,
    features: { controlRoomTab },
  } = props;

  if (!controlRoomTab) {
    return null;
  }

  if (!partnerNumber || partnerNumber === 'all') {
    return <ErrorPage type="selectPartner" />;
  }

  if (!includes(getPartnerNumbers(profile), partnerNumber)) {
    return <ErrorPage type="partner" />;
  }

  const tabOptions = [
    { value: 'Alarms', label: t('Alarms') },
    { value: 'Inspections', label: t('Inspections') },
    { value: 'Observations', label: t('Observations') },
  ];

  const customerName = customers[partnerNumber] && customers[partnerNumber].name;

  const selectedTab = find(map(tabOptions, 'value'), tabValue => location.pathname.endsWith(tabValue));

  return (
    <StandardPage withTabs>
      <Helmet title={t('Control Room')} />
      <Header t={t} selected="remote center" showPartnerSelect />
      <Hero title={t('Control Room')} subTitle={customerName} t={t} type="CONTROL_ROOM" />
      <Container>
        <SectionHeader t={t}>
          <SectionTabSelector
            left
            large
            t={t}
            options={tabOptions}
            model={{ selectedTab }}
            property="selectedTab"
            onTabChange={(property, value) => history.push(`${url}/${value}`)}
          />
        </SectionHeader>
        <Switch>
          <Route exact path={`${path}/Alarms`} render={() => <Alarms t={t} partnerNumber={partnerNumber} />} />
          <Route
            exact
            path={`${path}/Inspections`}
            render={() => <Inspections t={t} partnerNumber={partnerNumber} />}
          />
          <Route
            exact
            path={`${path}/Observations`}
            render={() => <Observations t={t} partnerNumber={partnerNumber} />}
          />
        </Switch>
      </Container>
    </StandardPage>
  );
};

RemoteCenter.propTypes = {
  customers: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  features: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
  customers: state.customer.customers,
  features: state.profile.profile.features,
});

const connector = connect(mapStateToProps);
export default connector(translations(RemoteCenter));
