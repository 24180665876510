import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';

import Button from 'components/Button/Button';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import StandardPage from 'components/StandardPage/StandardPage';

import { updateTranslations } from 'redux/modules/profile/profile';
import { NOTIFICATION_TIMEOUT } from 'constants/common';
import SnackBar from 'components/SnackBar/SnackBar';
import { isCaverionAdminRole } from 'utils/Data/profileData';
import Header from 'containers/Application/Header/Header';
import { icons } from 'components/Icon/IconNames';
import FadeTransition from 'components/FadeTransition/FadeTransition';

const Title = styled.h2`
  font-size: ${props => props.theme.font.size.md};
  font-weight: ${props => props.theme.font.weight.bold};
`;
Title.displayName = 'Title';

const List = styled.ol`
  list-style-position: inside;
  color: ${props => props.theme.colors.black};
  padding-bottom: ${props => props.theme.spacing.md};

  li {
    margin: ${props => props.theme.spacing.xs} 0;
  }
`;
List.displayName = 'List';

const ERROR_MSG = 'Translations update failed';
const SUCCESS_MSG = 'Successfully updated translations';

export const AdminTranslations = props => {
  const { t, profile, updateTranslations } = props;

  const [notificationType, setNotificationType] = useState(null);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [updating, setUpdating] = useState(false);

  if (!isCaverionAdminRole(profile.role)) {
    return null;
  }

  const handleClick = () => {
    setUpdating(true);
    updateTranslations().then(result => {
      const error = result && result.error;
      setNotificationType(error ? 'error' : 'success');
      setNotificationMessage(error ? t(ERROR_MSG) : t(SUCCESS_MSG));
      setNotificationVisible(true);
      setUpdating(false);

      setTimeout(() => {
        setNotificationVisible(false);
      }, NOTIFICATION_TIMEOUT);
    });
  };

  const links = [
    {
      title: 'Back to admin homepage',
      icon: icons.ARROW_LEFT,
      to: { pathname: '/Admin' },
      smallIcon: true,
    },
    {
      title: 'Translations',
      icon: icons.LANGUAGE,
    },
  ];

  return (
    <FadeTransition>
      <StandardPage>
        <Helmet title={t('Update translations')} />
        <Header t={t} links={links} selected="translations" />
        <Section>
          <SectionHeader t={t} title={t('Update translations')} noBorder />
          <Title>{t('Instructions')}</Title>
          <List>
            <li>{t('Update translations to Google Docs sheet.')}</li>
            <li>{t("Curly brackets mean variables, don't translate anything inside them (e.g. {variable}).")}</li>
            <li>{t('Take translations into use by pressing the button bellow.')}</li>
            <li>{t('Note that there is a max 15min delay before the updated translations are available.')}</li>
          </List>
          <Button onClick={handleClick} loading={updating}>
            {t('Take translations into use')}
          </Button>
        </Section>
        <SnackBar variant={notificationType} visible={notificationVisible}>
          {notificationMessage}
        </SnackBar>
      </StandardPage>
    </FadeTransition>
  );
};

AdminTranslations.propTypes = {
  t: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  updateTranslations: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
});

const mapDispatchToProps = dispatch => ({
  updateTranslations: () => dispatch(updateTranslations()),
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(translations(withTheme(AdminTranslations)));
