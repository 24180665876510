import moment from 'moment-timezone';
import memoizeOne from 'memoize-one';
import { isNil, includes, filter, findLast, intersection, head } from 'lodash';
import queryString from 'query-string';
import { icons } from 'components/Icon/IconNames';
import { flIcons } from 'utils/Data/functionalLocations';
import {
  isServiceOrdersEnabled,
  isPartnerEnergyEnabled,
  isPartnerRecyclingEnabled,
  isPortfolioDocumentsEnabled,
} from 'utils/Data/profileData';
import { getHeaderImageUrl, variants } from './images';
import { VIEW_TYPES, getIcon } from 'containers/Application/Admin/CustomViews/utils';
import { getPartnerNumbers } from 'utils/profile';

const paths = [
  'FunctionalLocation',
  'ServiceCalendar',
  'ServiceRequest',
  'Equipment',
  'ServiceOrder',
  'KPI',
  'Admin',
  'Profile',
  'Logout',
  'UserManual',
  'AdminUserManual',
  'DataMapping',
  'ContactCaverion',
];

export const isValidPartner = partner => !isNil(partner) && partner !== 'all' && !includes(paths, partner);

export const getValidPartnerFromRouter = router => {
  const partnerNumber = router.location.pathname.split('/')[1];

  if (isNil(partnerNumber) || partnerNumber === 'all') {
    return false;
  }

  if (includes(paths, partnerNumber)) {
    return false;
  }
  return partnerNumber;
};

export const getPortfolioLinks = memoizeOne(
  (partnerNumber, features, search, customViews, disabledCustomViews, featureTeasers) => {
    const partnerPart = !partnerNumber ? '' : `/${partnerNumber}`;
    const selectedYear = queryString.parse(search).year;
    const emptyQuery = { year: undefined, type: undefined };
    const serviceCalendarTo = {
      pathname: `${partnerPart}/ServiceCalendar`,
      query: { year: selectedYear || moment().format('YYYY'), type: undefined },
    };
    const overviewTo = { pathname: '/', query: emptyQuery };
    const energyTo = { pathname: `${partnerPart}/EnergyOptimization`, query: emptyQuery };
    const remoteCenterTo = { pathname: `${partnerPart}/RemoteCenter/Alarms`, query: emptyQuery };
    const documentsTo = { pathname: `${partnerPart}/Documents/Portfolio`, query: emptyQuery };

    const serviceCalendarLink = { title: 'Service Calendar', icon: icons.SERVICE_ORDERS, to: serviceCalendarTo };

    const energyTeaser = includes(featureTeasers, 'energyKpi');
    const energyLink = {
      title: 'Energy Optimization',
      icon: icons.ENERGY,
      to: energyTo,
      teaser: energyTeaser,
    };

    const remoteCenterLink = { title: 'Remote Center', icon: icons.CONTROL_ROOM, to: remoteCenterTo };
    const documentsLink = { title: 'Documents', icon: icons.DOCUMENTS, to: documentsTo };

    const linksToShow = [{ title: 'Overview', icon: flIcons.OVERVIEW, to: overviewTo }];
    isServiceOrdersEnabled(features) && linksToShow.push(serviceCalendarLink);
    (isPartnerEnergyEnabled(features) || energyTeaser) && linksToShow.push(energyLink);
    if (isPartnerRecyclingEnabled(features)) {
      linksToShow.push({
        title: 'Recycling',
        icon: icons.RECYCLING,
        to: { pathname: `${partnerPart}/Recycling`, query: emptyQuery },
      });
    }
    features && features.controlRoomTab && linksToShow.push(remoteCenterLink);
    isPortfolioDocumentsEnabled(features) && linksToShow.push(documentsLink);

    const enabledCustomViews = filter(customViews || [], customView => !includes(disabledCustomViews, customView.id));

    return linksToShow.concat(
      enabledCustomViews.map(customView => {
        const isExternalLink = customView.viewType === VIEW_TYPES.externalLink;
        const icon = getIcon(customView.viewType);
        const pathname = isExternalLink ? customView.content : `${partnerPart}/Report/${customView.id}`;

        return {
          id: customView.id,
          title: customView.viewName,
          icon,
          to: { pathname, query: emptyQuery },
          external: isExternalLink,
        };
      })
    );
  }
);

export const getPortfolioImage = (portfolioImages, partnerNumber) => {
  if (portfolioImages && partnerNumber) {
    const images = portfolioImages[partnerNumber];
    const portfolioImage = findLast(images, image => image.type === 'partner');
    if (portfolioImage && portfolioImage.path) {
      if (portfolioImage.variants && includes(portfolioImage.variants, variants.HEADER)) {
        return getHeaderImageUrl(portfolioImage.path);
      }
      return portfolioImage.path;
    }
  }
  return '';
};

export const findPartnerNumberForFL = (functionalLocation, profile) => {
  if (!functionalLocation || !profile) {
    return null;
  }

  const profilePartnerNumbers = getPartnerNumbers(profile);
  return head(intersection(profilePartnerNumbers, functionalLocation.partnerNumberWithParents));
};
