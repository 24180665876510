import React from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import OPICards from 'components/OPICard/OPICards';
import OPICard from 'components/OPICard/OPICard';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { overrideColumnWidth } from 'components/BuildingEvents/BuildingEvents';

export const RecyclingOPICards = ({ t, recyclingRate, recoveryRate, carbonFootprint }) => (
  <OPICards>
    <OPICard
      t={t}
      title={t('Recycling rate')}
      subtitle={t('Last 30 days')}
      noCircle
      valueInside={`${recyclingRate} %`}
      valueInsideIsPositive={true}
      valueInsideIsNegative={false}
      ctxHelp={`${CTXHELP_PREFIX} Recycling rate OPI`}
    />
    <OPICard
      t={t}
      title={t('Recovery rate')}
      subtitle={t('Last 30 days')}
      noCircle
      valueInside={`${recoveryRate} %`}
      valueInsideIsPositive={true}
      valueInsideIsNegative={false}
      ctxHelp={`${CTXHELP_PREFIX} Recovery rate OPI`}
    />
    <OPICard
      t={t}
      title={t('Carbon footprint')}
      subtitle={t('Last 30 days')}
      noCircle
      valueInside={carbonFootprint}
      valueInsideLabel={t('tonnes')}
      valueInsideIsPositive={true}
      valueInsideIsNegative={false}
      ctxHelp={`${CTXHELP_PREFIX} Carbon footprint OPI`}
    />
    <OPICard
      t={t}
      title={t('Recycling observations')}
      subtitle={`${performance.completed ? performance.completed : '-'} ${t('Completed')} / ${
        performance.total ? performance.total : '-'
      } ${t('Total')}\n(${t('Last 365 Days')})`}
      value={0}
      ctxHelp={`${CTXHELP_PREFIX} Recycling observations OPI`}
      icon={'opi-expand'}
      neutral
      overrideColumnWidth={overrideColumnWidth}
    />
  </OPICards>
);

RecyclingOPICards.propTypes = {
  t: PropTypes.func.isRequired,
  recyclingRate: PropTypes.number.isRequired,
  recoveryRate: PropTypes.number.isRequired,
  carbonFootprint: PropTypes.number.isRequired,
};

export default translations(RecyclingOPICards);
