import { combineReducers } from 'redux';
import airQuality from './values/air_quality';
import cleaning from './values/cleaning';
import conditions from './values/conditions';
import consumption from './values/consumption';
import energyChartValues from './values/energy_chart_values';
import energyRating from './values/energy_rating';
import flEnergyValues from './values/fl_energy_values';
import importSensorValues from './values/import_sensor_values';
import sensors from './values/sensor_values';
import { OPIDataReducer, wasteAmountsDataReducer } from './values/recycling';

export default combineReducers({
  airQuality,
  cleaning,
  conditions,
  consumption,
  energyChartValues,
  energyRating,
  flEnergyValues,
  importSensorValues,
  sensors,
  recyclingOPI: OPIDataReducer,
  recyclingWasteAmounts: wasteAmountsDataReducer
});
