import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import compact from 'lodash/compact';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import moment from 'moment';
import { getBuildingTimezone } from '@caverion/loopback-shared/utility/performance';

import Sensor from 'components/Sensor/Sensor';
import SensorHead from 'components/Sensor/SensorHead/SensorHead';
import SensorBody from 'components/Sensor/SensorBody/SensorBody';
import SensorTools from 'components/Sensor/SensorTools/SensorTools';
import PerformanceSelector from 'components/Sensor/PerformanceSelector/PerformanceSelector';
import PresenceKeys from 'components/Sensor/PresenceKeys/PresenceKeys';

import { DATE_RANGES } from 'components/Form/DateTools';
import {
  loadSensorsValues,
  loadLatestSensorsValues,
  addLoading,
  removeLoading,
  loadUtilizationRateChartValues,
} from 'redux/modules/index.js';
import { getLatestSensorValueProperties, isEnergySensor } from 'utils/Data/values.js';
import { getPerformanceLimit } from 'utils/Data/performance';
import {
  getSensorData,
  getSensor,
  getSensorType,
  getSensorTitle,
  getSensorOptions,
  getAggregation,
  getInitialParameters,
  getPerformanceSelectorOptions,
  getRawUtilization,
  STATISTICS_TYPES,
  getStatisticsOptions,
  getUtilizationDataset,
  getUtilizationHours,
  getUtilizationRateSelectorOptions,
  getFloorOPISelectorOptions,
} from './SensorValuesUtils';

const NoData = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 5em;
`;
NoData.displayName = 'NoData';

class SensorValues extends PureComponent {
  state = {
    sensors: [],
    sensor: undefined,
    parameters: {
      startDatetime: null,
      endDatetime: null,
      presence: [0, 0],
      statistics: STATISTICS_TYPES.perDay,
    },
  };

  getLatestValue = sensor => this.props.values.sensors.latestValuesBySensorId[sensor.id];

  handleSensorSelect = (property, sensor) => {
    const sensorType = getSensorType(sensor, this.props.sensorTypes);
    this.setState(oldState => ({
      sensor,
      parameters: getInitialParameters(oldState.parameters, sensorType.graphType, true),
    }));
  };

  handleParameterSelect = (property, value) => {
    this.setState(oldState => ({ parameters: { ...oldState.parameters, [property]: value } }));
  };

  componentDidMount() {
    const {
      sensorsIds,
      defaultSensorId,
      sensorTypes,
      buildingSensors,
      t,
      sensorGroups,
      showCombinationGroup,
      isFloorOPISensors,
    } = this.props;

    if (!isEmpty(sensorsIds)) {
      const sensors = sensorGroups || compact(sensorsIds.map(sensorId => getSensor(sensorId, buildingSensors)));
      const combinedSensor = showCombinationGroup && {
        name: t('All'),
        isGroup: true,
        sensorType: sensors[0].sensorType,
        sensorTypeId: sensors[0].sensorTypeId,
        sensorIds: sensorsIds,
      };

      const sensor =
        combinedSensor ||
        (isFloorOPISensors && sortBy(sensors, 'name')[0]) ||
        find(sensors, { id: defaultSensorId }) ||
        sensors[0];
      const sensorType = getSensorType(sensor, sensorTypes);

      this.setState(oldState => ({
        sensors,
        sensor,
        combinedSensor,
        parameters: getInitialParameters(oldState.parameters, sensorType.graphType, false),
      }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      parameters: { startDatetime, endDatetime },
      sensor,
    } = this.state;
    const { isAirQuality, isUtilizationRate } = this.props;

    if (!startDatetime || !endDatetime) {
      return;
    }

    // Do not load data if sensor or datetimes haven't changed
    if (
      (!isAirQuality && !isUtilizationRate && prevState.sensor && prevState.sensor.id !== sensor.id) ||
      !prevState.parameters.startDatetime ||
      !prevState.parameters.endDatetime ||
      prevState.parameters.startDatetime.valueOf() !== startDatetime.valueOf() ||
      prevState.parameters.endDatetime.valueOf() !== endDatetime.valueOf()
    ) {
      this.loadSensorData();
    }
  }

  loadSensorData = () => {
    const {
      addLoading,
      removeLoading,
      loadSensorsValues,
      loadLatestSensorsValues,
      loadUtilizationRateChartValues,
      functionalLocation: { functionalLocation },
      sensorTypes,
      isAirQuality,
      isUtilizationRate,
      isFloorOPISensors,
      sensorsIds,
      buildingMeta,
    } = this.props;
    const {
      sensor,
      sensors,
      parameters: { startDatetime, endDatetime },
      parameters,
      combinedSensor,
    } = this.state;

    const sensorType = getSensorType(sensor, sensorTypes);
    const start = moment.utc(startDatetime);
    const end = moment.utc(endDatetime);
    const aggregation = getAggregation(sensorType, parameters, sensor, isUtilizationRate);
    const utilizationHours = getUtilizationHours(buildingMeta);
    const isArea = sensor.sensorType && sensor.sensorType.name === 'presence_area';
    const sensorGroups = isUtilizationRate && [combinedSensor, ...sensors];

    const loadValues = () => {
      if (isUtilizationRate) {
        return loadUtilizationRateChartValues(sensorsIds, sensorGroups, start, end, utilizationHours, isArea);
      } else if (isAirQuality) {
        return loadSensorsValues(sensorsIds, start, end, aggregation);
      }
      return loadSensorsValues([sensor.id], start, end, aggregation);
    };

    const loadLatestValues = () => {
      if (isFloorOPISensors) {
        return loadLatestSensorsValues(sensorsIds);
      } else if (!isUtilizationRate && !isAirQuality) {
        return loadLatestSensorsValues([sensor.id]);
      }
    };

    addLoading(functionalLocation);

    Promise.all([loadValues(), loadLatestValues()])
      .then(() => removeLoading(functionalLocation))
      .catch(() => removeLoading(functionalLocation));
  };

  getPerformanceSelectorOptions() {
    const {
      values,
      isAirQuality,
      isUtilizationRate,
      isFloorOPISensors,
      utilizationRateChartValues,
      areas,
      buildingMeta,
      theme,
      t,
      sensorAlarmsById,
    } = this.props;
    const { sensors, combinedSensor } = this.state;

    if (isAirQuality) {
      return getPerformanceSelectorOptions(sensors, combinedSensor, values && values.sensors.valuesBySensorId);
    }
    if (isUtilizationRate) {
      return getUtilizationRateSelectorOptions(sensors, combinedSensor, utilizationRateChartValues, areas);
    }
    if (isFloorOPISensors) {
      return getFloorOPISelectorOptions(
        sensors,
        values.sensors.latestValuesBySensorId,
        buildingMeta,
        theme,
        t,
        sensorAlarmsById
      );
    }
  }
  render() {
    const {
      t,
      isLoading,
      defaultSensorId,
      theme,
      values,
      sensorTypes,
      isAirQuality,
      isUtilizationRate,
      isFloorOPISensors,
      buildingMeta,
      category,
      sensorAlarmsById,
      title,
      areas,
      openingHours,
      functionalLocation,
      sensorGroups,
      utilizationRateChartValues,
    } = this.props;

    const { sensor, sensors, parameters } = this.state;

    if (!sensor) {
      return <NoData>{t('No data available')}</NoData>;
    }

    const utilizationHours = getUtilizationHours(buildingMeta);
    const sensorType = getSensorType(sensor, sensorTypes);
    const aggregation = getAggregation(sensorType, parameters, sensor, isUtilizationRate);

    const sensorData = isUtilizationRate
      ? getUtilizationDataset(sensor, sensors, utilizationRateChartValues, parameters.statistics, areas)
      : getSensorData(sensor, sensorType, values && values.sensors.valuesBySensorId);

    const latestValue = this.getLatestValue(sensor);
    const { value, time, color } = getLatestSensorValueProperties(
      latestValue,
      sensorType,
      sensorData,
      sensor.sensorMeta,
      theme,
      t
    );

    // push latest value to sensorData if
    // 1) it's not s2 sensor and
    // 2) the latest value is newer than the last data point
    // 3) endDatetime is at least on today
    if (
      sensorType &&
      sensorType.name !== 'air_quality' &&
      !sensorType.name.startsWith('technical_performance') &&
      latestValue &&
      sensorData &&
      sensorData.length > 0 &&
      moment.utc(latestValue.timestamp).isAfter(moment.utc(last(sensorData).timestamp)) &&
      moment.utc().isSame(moment.utc(parameters.endDatetime), 'day')
    ) {
      sensorData.push(latestValue);
    }

    const isPresence = sensorType.graphType === 'presence';
    const isRadon = sensorType.name === 'radon';
    const defaultRange = isUtilizationRate
      ? DATE_RANGES.DAYS_30
      : isPresence
      ? DATE_RANGES.DAYS_7
      : isRadon
      ? DATE_RANGES.DAYS_30
      : isEnergySensor(sensorType.name)
      ? DATE_RANGES.DAYS_365
      : DATE_RANGES.DAYS_7;

    const performanceSelectorOptions = this.getPerformanceSelectorOptions();

    const presenceUtilization =
      isPresence &&
      !isUtilizationRate &&
      getRawUtilization(
        values && values.sensors && values.sensors.valuesBySensorId[sensor.id],
        parameters,
        utilizationHours
      );

    const statisticsOptions = isUtilizationRate && getStatisticsOptions(t, sensorType, !!sensorGroups);

    const getThreshold =
      (sensorType &&
        (sensorType.graphType === 'iot' || sensorType.graphType === 'performance') &&
        getPerformanceLimit(sensor, sensor.parent, buildingMeta, sensorAlarmsById[sensor.id])) ||
      undefined;

    const timezone = getBuildingTimezone(functionalLocation.functionalLocation, buildingMeta);
    return (
      <Sensor>
        <SensorHead
          t={t}
          loading={isLoading}
          sensor={sensor}
          sensorTitle={getSensorTitle(title, sensor)}
          sensorOptions={performanceSelectorOptions ? undefined : getSensorOptions(sensors)}
          sensorValue={performanceSelectorOptions ? undefined : value}
          sensorValueTime={performanceSelectorOptions ? undefined : time}
          sensorValueColor={color}
          onSensorChange={this.handleSensorSelect}
          statisticsOptions={statisticsOptions || undefined}
          onStatisticsChange={this.handleParameterSelect}
          noSubsensors={isNil(defaultSensorId)}
          category={category}
          utilizationHours={isUtilizationRate || isPresence ? utilizationHours : undefined}
          model={parameters}
          contextualHelp={isUtilizationRate ? 'Utilization Rate' : undefined}
          openingHours={openingHours}
          timezone={timezone}
        />
        {performanceSelectorOptions && parameters.statistics !== STATISTICS_TYPES.perSensor && (
          <PerformanceSelector
            model={this.state}
            property="sensor"
            onClick={this.handleSensorSelect}
            t={t}
            options={performanceSelectorOptions}
            loading={isLoading}
            theme={theme}
            isAirQuality={isAirQuality}
            areas={areas}
            unit={isFloorOPISensors && sensorType ? sensorType.unit : undefined}
          />
        )}
        {isPresence && !isUtilizationRate && (
          <PresenceKeys
            t={t}
            loading={isLoading}
            utilizationRate={presenceUtilization.utilizationRate}
            unusedHours={presenceUtilization.unusedHours}
            color={theme.colors.midnight}
          />
        )}
        <SensorBody
          t={t}
          loading={isLoading}
          sensorData={sensorData}
          sensorType={sensorType}
          parameterModel={this.state.parameters}
          hasSubsensors={!!defaultSensorId}
          aggregation={aggregation}
          sensorMeta={sensor.sensorMeta}
          smallHeight={isAirQuality || isUtilizationRate || isPresence || isFloorOPISensors}
          utilizationHours={utilizationHours}
          statistics={parameters.statistics}
          getThreshold={getThreshold}
          openingHours={openingHours}
          buildingMeta={buildingMeta}
          timezone={timezone}
          functionalLocation={functionalLocation.functionalLocation}
        />
        <SensorTools
          t={t}
          parameterModel={this.state.parameters}
          onParameterChange={this.handleParameterSelect}
          defaultRange={defaultRange}
          sensorGranularity={sensor.granularity}
          isUtilizationRate={isUtilizationRate}
        />
      </Sensor>
    );
  }
}

const COMPONENT = 'SensorValues';

const mapStateToProps = (state, ownProps) => ({
  values: state.values,
  isLoading: includes(state.common.loading[COMPONENT], ownProps.functionalLocation.functionalLocation),
  sensorTypes: state.sensorHierarchy.sensorDataTypes,
  utilizationRateChartValues: state.utilizationRate.utilizationRateChartValues,
});

const mapDispatchToProps = {
  loadSensorsValues,
  loadLatestSensorsValues,
  loadUtilizationRateChartValues,
  addLoading: key => addLoading(key, COMPONENT),
  removeLoading: key => removeLoading(key, COMPONENT),
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

SensorValues.propTypes = {
  // required from redux/decorators:
  addLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadSensorsValues: PropTypes.func.isRequired,
  loadLatestSensorsValues: PropTypes.func.isRequired,
  loadUtilizationRateChartValues: PropTypes.func.isRequired,
  removeLoading: PropTypes.func.isRequired,
  sensorTypes: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  utilizationRateChartValues: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  // required from parent:
  sensorsIds: PropTypes.array.isRequired,
  buildingSensors: PropTypes.array.isRequired,
  functionalLocation: PropTypes.object.isRequired,
  // optional:
  defaultSensorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isAirQuality: PropTypes.bool,
  isUtilizationRate: PropTypes.bool,
  isFloorOPISensors: PropTypes.bool,
  buildingMeta: PropTypes.arrayOf(PropTypes.object),
  category: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    shortName: PropTypes.string,
  }),
  sensorAlarmsById: PropTypes.object,
  title: PropTypes.string,
  areas: PropTypes.arrayOf(PropTypes.object),
  openingHours: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sensorGroups: PropTypes.object,
  showCombinationGroup: PropTypes.bool,
};

SensorValues.defaultProps = {
  defaultSensorId: undefined,
  sensorType: undefined,
  isAirQuality: false,
  isUtilizationRate: false,
};

export default connector(translations(withTheme(SensorValues)));
